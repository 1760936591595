import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary, ButtonSecondary } from "../../UI/Buttons";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField, Switches, Select } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";
import {
	getSystemSetting,
	deleteTemplateAttachment,
	uploadFile,
	getWorkingData,
} from "../../../apis/system/settings";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import style from "../../requests/processing/Processing.module.scss";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import { downloadRequestAttachment } from "../../../apis/requests/info/downloadRequestAttachment";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import { CheckBox } from "devextreme-react/check-box";
import moment from "moment";
class SystemSetting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			viewMood: true,
			file: [],
			expanded: "",
			emailPass: false,
			smsPass: false,
			initValues: {},
			workingData: {},
			sliderTypes: [],
			templatesTypes: [],
		};
	}
	componentDidMount() {
		this.getSliderTypes();
		this.getTemplates();
		this.getSystemSetting();
		this.getWorkingHoursDays();
	}
	getSystemSetting = async () => {
		let at = this.props.currentUser.Data.access_token,
			response = await getSystemSetting(at);

		this.setState({
			initValues: response,
		});
		if (response.Inspection_Template_File_Code !== null) {
			this.setState({
				file: [
					{
						File_Name: response.Inspection_Template_File_Name,
						File_Size: response.Inspection_Template_File_Size,
						File_Code: response.Inspection_Template_File_Code,
						Files_Type: response.Inspection_Template_File_Type,
						at,
						Can_Delete: 1,
					},
				],
			});
		}
	};
	getWorkingHoursDays = async () => {
		let at = this.props.currentUser.Data.access_token,
			response = await getWorkingData(at);

		this.setState({
			workingData: response,
		});
	};

	getTemplates = async () => {
		let template = [];
		const response = await apiConfig.post("/API/SYSTEM/DDL/GET_TEMPLATES");
		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;
				template.push({ value: item.Record_Id, label });
			});
			this.setState({ templatesTypes: template });
		}
	};

	getSliderTypes = async () => {
		let sliderType = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_PORTAL_SLIDERS_TYPES"
		);
		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;
				sliderType.push({ value: item.Record_Id, label });
			});
			this.setState({ sliderTypes: sliderType });
		}
	};

	deleteFile = (params) => {
		// console.log(params)
		deleteTemplateAttachment(params).then((res) => {
			this.getSystemSetting();
		});
	};

	onSectionSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = "API/SYSTEM/SETTINGS/SAVE_SYSTEM_SETTINGS",
			bodyData = values;

		this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});
		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false);
	};

	handleChange = (panel) => (event, isExpanded) => {
		this.setState({
			expanded: isExpanded ? panel : false,
		});
	};
	handleFileChange = async (e) => {
		this.props.setLoading(true, "dark", 3000);
		const formData = new FormData();
		formData.append("Attached_File", e.target.files[0]);
		const response = await uploadFile(
			formData,
			this.props.currentUser.Data.access_token
		);
		this.props.setLoading(false);
		this.getSystemSetting();
	};
	submitWorkingData = async () => {
		let strTime = `${
			this.state.workingData.Working_Day_Start_Time.split(":")[0]
		}:${this.state.workingData.Working_Day_Start_Time.split(":")[1]}`;
		let endTime = `${
			this.state.workingData.Working_Day_End_Time.split(":")[0]
		}:${this.state.workingData.Working_Day_End_Time.split(":")[1]}`;
		console.log(strTime);
		console.log(endTime);

		let at = this.props.currentUser.Data.access_token,
			url = "API/SYSTEM/SETTINGS/SAVE_WORKING_DAYS",
			bodyData = {
				Working_Day_End_Time: endTime,
				Working_Day_Start_Time: strTime,
				Working_Day_Friday: this.state.workingData.Working_Day_Friday,
				Working_Day_Monday: this.state.workingData.Working_Day_Monday,
				Working_Day_Saturday:
					this.state.workingData.Working_Day_Saturday,
				Working_Day_Sunday: this.state.workingData.Working_Day_Sunday,
				Working_Day_Thursday:
					this.state.workingData.Working_Day_Thursday,
				Working_Day_Tuesday: this.state.workingData.Working_Day_Tuesday,
				Working_Day_Wednesday:
					this.state.workingData.Working_Day_Wednesday,
			};

		this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});
		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false);
	};
	render() {
		const validate = (values) => {
			const errors = {};
			return errors;
		};

		return (
			<>
				<div className="d-flex justify-content-between align-items-center pb-5">
					<h3>{this.props.t("ManageSettings")}</h3>
				</div>
				<Form
					onSubmit={this.onSectionSubmit}
					initialValues={this.state.initValues}
					validate={validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="sliderform"
							className="row"
							onSubmit={handleSubmit}
							noValidate
						>
							<div className="col-md-12">
								<Accordion
									className={`${style.accordionWrap} py-4 mb-3`}
									expanded={this.state.expanded === "panel1"}
									onChange={this.handleChange("panel1")}
									key={`mail_${this.state.initValues?.SMTP_From_Mail}`}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`task_${this.state.initValues?.SMTP_From_Mail}`}
										id={`task_`}
									>
										<h3>{this.props.t("emailSettings")}</h3>
									</AccordionSummary>
									<AccordionDetails
										className={style.accordionDetail}
									>
										<div className="row mx-0">
											<Col xs={12} md={6}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"email"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMTP_From_Mail"
												/>
											</Col>
											<Col xs={12} md={6}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"accountName"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMTP_From_Name"
												/>
											</Col>
											<Col xs={12} md={3}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t("host")}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMTP_Host"
												/>
											</Col>
											<Col xs={12} md={3}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t("port")}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMTP_Port"
												/>
											</Col>
											<Col xs={12} md={3}>
												<Switches
													disabled={
														this.state.viewMood
													}
													color="primary"
													name="SMTP_EnableSSL"
													data={{
														label: `${this.props.t(
															"EnableSSL"
														)}`,
														value: `${this.props.t(
															"EnableSSL"
														)}`,
													}}
												/>
											</Col>
											<Col xs={12} md={3}>
												<Switches
													disabled={
														this.state.viewMood
													}
													color="primary"
													name="SMTP_Authentication"
													data={{
														label: `${this.props.t(
															"enableAuth"
														)}`,
														value: `${this.props.t(
															"enableAuth"
														)}`,
													}}
												/>
											</Col>
											<Col xs={12} md={6}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"loginName"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMTP_Login_Name"
												/>
											</Col>
											<Col xs={12} md={2}>
												<Switches
													disabled={
														this.state.viewMood
													}
													color="primary"
													name="Enable_SMTP_Login_Password"
													data={{
														label: `${this.props.t(
															"EnablePassword"
														)}`,
														value: `${this.props.t(
															"EnablePassword"
														)}`,
													}}
												/>
											</Col>
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													type={
														!this.state.emailPass
															? "password"
															: "text"
													}
													label={this.props.t(
														"loginPassword"
													)}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={() => {
																		this.setState(
																			{
																				emailPass:
																					!this
																						.state
																						.emailPass,
																			}
																		);
																	}}
																	onMouseDown={() => {
																		this.setState(
																			{
																				emailPass:
																					!this
																						.state
																						.emailPass,
																			}
																		);
																	}}
																>
																	{this.state
																		.emailPass ? (
																		<VisibilityIcon />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMTP_Login_Password"
												/>
											</Col>
											{!this.state.viewMood ? (
												<>
													<Col
														className="my-4 justify-content-start d-flex"
														xs={12}
													>
														<ButtonPrimary
															color="primary"
															variant="outlined"
															type="button"
															onClick={(e) => {
																this.setState({
																	viewMood: true,
																});
															}}
														>
															{this.props.t(
																"cancel"
															)}
														</ButtonPrimary>
														<ButtonPrimary
															color="primary"
															variant="contained"
															type="submit"
														>
															{this.props.t(
																"save"
															)}
														</ButtonPrimary>
													</Col>
												</>
											) : (
												<Col
													className="my-4 justify-content-start d-flex"
													xs={12}
												>
													<ButtonPrimary
														color="primary"
														variant="contained"
														type="button"
														onClick={(e) => {
															this.setState({
																viewMood: false,
															});
														}}
													>
														{this.props.t("edit")}
													</ButtonPrimary>
												</Col>
											)}
										</div>
									</AccordionDetails>
								</Accordion>
							</div>
							<div className="col-md-12">
								<Accordion
									className={`${style.accordionWrap} py-4 mb-3`}
									expanded={this.state.expanded === "panel2"}
									onChange={this.handleChange("panel2")}
									key={`task_${this.state.initValues?.SMTP_From_Mail}`}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`task_${this.state.initValues?.SMTP_From_Mail}`}
										id={`task_`}
									>
										<h3>{this.props.t("SMSSettings")}</h3>
									</AccordionSummary>
									<AccordionDetails
										className={style.accordionDetail}
									>
										<div className="row mx-0">
											<Col xs={12} md={12}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"smsUrl"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMS_URL"
												/>
											</Col>
											<Col xs={12} md={3}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"smsAccountNo"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMS_Account_No"
												/>
											</Col>
											<Col xs={12} md={3}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"smsUserName"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMS_User_Name"
												/>
											</Col>
											<Col xs={12} md={3}>
												<Switches
													disabled={
														this.state.viewMood
													}
													color="primary"
													name="Enable_SMS_User_Password"
													data={{
														label: `${this.props.t(
															"loginPassword"
														)}`,
														value: `${this.props.t(
															"loginPassword"
														)}`,
													}}
												/>
											</Col>
											<Col xs={12} md={3}>
												<TextField
													disabled={
														this.state.viewMood
													}
													type={
														!this.state.smsPass
															? "password"
															: "text"
													}
													label={this.props.t(
														"loginPassword"
													)}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	onMouseDown={() => {
																		this.setState(
																			{
																				smsPass:
																					!this
																						.state
																						.smsPass,
																			}
																		);
																	}}
																	onClick={() => {
																		this.setState(
																			{
																				smsPass:
																					!this
																						.state
																						.smsPass,
																			}
																		);
																	}}
																>
																	{this.state
																		.smsPass ? (
																		<VisibilityIcon />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMS_User_Password"
												/>
											</Col>

											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"smsTitle"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMS_Title"
												/>
											</Col>

											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"smsEncodingAR"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMS_Encoding_AR"
												/>
											</Col>
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"smsEncodingEN"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="SMS_Encoding_EN"
												/>
											</Col>
											{!this.state.viewMood ? (
												<>
													<Col
														className="my-4 justify-content-start d-flex"
														xs={12}
													>
														<ButtonPrimary
															color="primary"
															variant="outlined"
															type="button"
															onClick={(e) => {
																this.setState({
																	viewMood: true,
																});
															}}
														>
															{this.props.t(
																"cancel"
															)}
														</ButtonPrimary>
														<ButtonPrimary
															color="primary"
															variant="contained"
															type="submit"
														>
															{this.props.t(
																"save"
															)}
														</ButtonPrimary>
													</Col>
												</>
											) : (
												<Col
													className="my-4 justify-content-start d-flex"
													xs={12}
												>
													<ButtonPrimary
														color="primary"
														variant="contained"
														type="button"
														onClick={(e) => {
															this.setState({
																viewMood: false,
															});
														}}
													>
														{this.props.t("edit")}
													</ButtonPrimary>
												</Col>
											)}
										</div>
									</AccordionDetails>
								</Accordion>
							</div>
							<div className="col-md-12">
								<Accordion
									className={`${style.accordionWrap} py-4 mb-3`}
									expanded={this.state.expanded === "panel6"}
									onChange={this.handleChange("panel6")}
									key={`task_${this.state.initValues?.SMTP_From_Mail}`}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`task_${this.state.initValues?.SMTP_From_Mail}`}
										id={`task_`}
									>
										<h3>
											{this.props.t("workingHourSetting")}
										</h3>
									</AccordionSummary>
									<AccordionDetails
										className={style.accordionDetail}
									>
										<div className="row mx-0">
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"startingTime"
													)}
													type="time"
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="Working_Day_Start_Time"
													value={
														this.state.workingData
															.Working_Day_Start_Time
													}
													onChange={(e) => {
														this.setState({
															workingData: {
																...this.state
																	.workingData,
																[e.target.name]:
																	e.target
																		.value,
															},
														});
													}}
												/>
											</Col>
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"endingTime"
													)}
													type="time"
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="Working_Day_End_Time"
													format={"HH:MM"}
													value={
														this.state.workingData
															.Working_Day_End_Time
													}
													onChange={(e) => {
														this.setState({
															workingData: {
																...this.state
																	.workingData,
																[e.target.name]:
																	e.target
																		.value,
															},
														});
													}}
												/>
											</Col>
											<Col md={12}>
												<div className="row">
													<Col xs={12} md={2}>
														<div className="p-2">
															<h6>
																{this.props.t(
																	"workingDays"
																)}
															</h6>
														</div>
													</Col>
													<Col xs={12} md={2}>
														<div className="p-2">
															<CheckBox
																disabled={
																	this.state
																		.viewMood
																}
																defaultValue={
																	this.state
																		.workingData
																		?.Working_Day_Sunday
																}
																onValueChanged={(
																	e
																) => {
																	console.log(
																		e
																	);
																	this.setState(
																		{
																			workingData:
																				{
																					...this
																						.state
																						.workingData,
																					Working_Day_Sunday:
																						e.value,
																				},
																		}
																	);
																}}
															/>
															<span className="px-2">
																{this.props.t(
																	"sunday"
																)}
															</span>
														</div>
													</Col>
													<Col xs={12} md={2}>
														<div className="p-2">
															<CheckBox
																disabled={
																	this.state
																		.viewMood
																}
																defaultValue={
																	this.state
																		.workingData
																		?.Working_Day_Monday
																}
																onValueChanged={(
																	e
																) => {
																	console.log(
																		e
																	);
																	this.setState(
																		{
																			workingData:
																				{
																					...this
																						.state
																						.workingData,
																					Working_Day_Monday:
																						e.value,
																				},
																		}
																	);
																}}
															/>
															<span className="px-2">
																{this.props.t(
																	"monday"
																)}
															</span>
														</div>
													</Col>
													<Col xs={12} md={2}>
														<div className="p-2">
															<CheckBox
																disabled={
																	this.state
																		.viewMood
																}
																defaultValue={
																	this.state
																		.workingData
																		?.Working_Day_Tuesday
																}
																onValueChanged={(
																	e
																) => {
																	console.log(
																		e
																	);
																	this.setState(
																		{
																			workingData:
																				{
																					...this
																						.state
																						.workingData,
																					Working_Day_Tuesday:
																						e.value,
																				},
																		}
																	);
																}}
															/>
															<span className="px-2">
																{this.props.t(
																	"tuesday"
																)}
															</span>
														</div>
													</Col>
													<Col xs={12} md={2}>
														<div className="p-2">
															<CheckBox
																disabled={
																	this.state
																		.viewMood
																}
																defaultValue={
																	this.state
																		.workingData
																		?.Working_Day_Wednesday
																}
																onValueChanged={(
																	e
																) => {
																	console.log(
																		e
																	);
																	this.setState(
																		{
																			workingData:
																				{
																					...this
																						.state
																						.workingData,
																					Working_Day_Wednesday:
																						e.value,
																				},
																		}
																	);
																}}
															/>
															<span className="px-2">
																{this.props.t(
																	"wedensday"
																)}
															</span>
														</div>
													</Col>
													<Col xs={12} md={2}>
														<div className="p-2">
															<CheckBox
																disabled={
																	this.state
																		.viewMood
																}
																defaultValue={
																	this.state
																		.workingData
																		?.Working_Day_Thursday
																}
																onValueChanged={(
																	e
																) => {
																	console.log(
																		e
																	);
																	this.setState(
																		{
																			workingData:
																				{
																					...this
																						.state
																						.workingData,
																					Working_Day_Thursday:
																						e.value,
																				},
																		}
																	);
																}}
															/>
															<span className="px-2">
																{this.props.t(
																	"thursday"
																)}
															</span>
														</div>
													</Col>
													<Col xs={12} md={2}>
														<div className="p-2">
															<CheckBox
																disabled={
																	this.state
																		.viewMood
																}
																defaultValue={
																	this.state
																		.workingData
																		?.Working_Day_Friday
																}
																onValueChanged={(
																	e
																) => {
																	console.log(
																		e
																	);
																	this.setState(
																		{
																			workingData:
																				{
																					...this
																						.state
																						.workingData,
																					Working_Day_Friday:
																						e.value,
																				},
																		}
																	);
																}}
															/>
															<span className="px-2">
																{this.props.t(
																	"friday"
																)}
															</span>
														</div>
													</Col>
													<Col xs={12} md={2}>
														<div className="p-2">
															<CheckBox
																disabled={
																	this.state
																		.viewMood
																}
																defaultValue={
																	this.state
																		.workingData
																		?.Working_Day_Saturday
																}
																onValueChanged={(
																	e
																) => {
																	console.log(
																		e
																	);
																	this.setState(
																		{
																			workingData:
																				{
																					...this
																						.state
																						.workingData,
																					Working_Day_Saturday:
																						e.value,
																				},
																		}
																	);
																}}
															/>
															<span className="px-2">
																{this.props.t(
																	"saturday"
																)}
															</span>
														</div>
													</Col>
												</div>
											</Col>
											{!this.state.viewMood ? (
												<>
													<Col
														className="my-4 justify-content-start d-flex"
														xs={12}
													>
														<ButtonPrimary
															color="primary"
															variant="outlined"
															type="button"
															onClick={(e) => {
																this.setState({
																	viewMood: true,
																});
															}}
														>
															{this.props.t(
																"cancel"
															)}
														</ButtonPrimary>
														<ButtonPrimary
															color="primary"
															variant="contained"
															type="button"
															onClick={
																this
																	.submitWorkingData
															}
														>
															{this.props.t(
																"save"
															)}
														</ButtonPrimary>
													</Col>
												</>
											) : (
												<Col
													className="my-4 justify-content-start d-flex"
													xs={12}
												>
													<ButtonPrimary
														color="primary"
														variant="contained"
														type="button"
														onClick={(e) => {
															this.setState({
																viewMood: false,
															});
														}}
													>
														{this.props.t("edit")}
													</ButtonPrimary>
												</Col>
											)}
											<Col />
										</div>
									</AccordionDetails>
								</Accordion>
							</div>
							<div className="col-md-12">
								<Accordion
									className={`${style.accordionWrap} py-4 mb-3`}
									expanded={this.state.expanded === "panel3"}
									onChange={this.handleChange("panel3")}
									key={`task_${this.state.initValues?.SMTP_From_Mail}`}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`task_${this.state.initValues?.SMTP_From_Mail}`}
										id={`task_`}
									>
										<h3>
											{this.props.t("templateSettings")}
										</h3>
									</AccordionSummary>
									<AccordionDetails
										className={style.accordionDetail}
									>
										<div className="row mx-0">
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"templateRow"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="Templates_Rows"
												/>
											</Col>
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"schemeRow"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="Documents_A4_Rows"
												/>
											</Col>
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"documentRow"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="Documents_A3_Rows"
												/>
											</Col>
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"templatesStartRow"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="Templates_Sign_Start_Bottom_Row"
												/>
											</Col>
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"schemeStartRow"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="Documents_A4_Sign_Start_Bottom_Row"
												/>
											</Col>
											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"documentsStartRow"
													)}
													className={`${
														this.state.viewMood
															? "read-only mb-2"
															: "mb-2"
													}`}
													name="Documents_A3_Sign_Start_Bottom_Row"
												/>
											</Col>
											<Col xs={12} md={12}>
												<Select
													disabled={
														this.state.viewMood
													}
													className={`${
														this.state.viewMood
															? "read-only"
															: ""
													} mb-2`}
													label={this.props.t(
														"templateType"
													)}
													required={true}
													name="Inspection_Template_Id"
													data={
														this.state
															.templatesTypes
													}
													showError={() => {
														let fieldStatus =
															form.getFieldState(
																"Inspection_Template_Id"
															);

														if (fieldStatus) {
															return (
																fieldStatus.submitFailed &&
																fieldStatus.invalid
															);
														}
													}}
												></Select>
											</Col>
											<div className="col-md-6 mt-4">
												<ButtonSecondary
													color="primary"
													variant="contained"
													disabled={
														this.state.viewMood
													}
													className="custom-input"
													endIcon={
														<CloudUploadIcon />
													}
												>
													{this.props.t("select")}
													<input
														type="file"
														onChange={(e) =>
															this.handleFileChange(
																e
															)
														}
													/>
												</ButtonSecondary>
											</div>
											{this.state.file.length > 0 &&
												this.state.file[0].File_Code !==
													"" &&
												this.state.file.map((file) => (
													<Col
														md={6}
														className="mt-4"
													>
														<ListItem className="shadow-sm">
															<ListItemAvatar>
																<CropOriginalIcon />
															</ListItemAvatar>
															<ListItemText
																primary={
																	file.File_Name
																}
																secondary={
																	file.File_Size
																}
															/>
															<IconButton
																edge="start"
																aria-label="download"
																onClick={async () => {
																	this.props.setLoading(
																		true,
																		"dark",
																		3000
																	);
																	const response =
																		await downloadRequestAttachment(
																			file,
																			this
																				.props
																				.currentUser
																				.Data
																				.access_token
																		);
																	this.props.setLoading(
																		false
																	);
																	if (
																		response.status ===
																		200
																	) {
																		let contentType =
																				response
																					.headers[
																					"content-type"
																				],
																			typesArray =
																				[
																					"application/pdf",
																					"image/bmp",
																					"image/gif",
																					"image/vnd.microsoft.icon",
																					"image/jpeg",
																					"image/png",
																					"image/svg+xml",
																					"image/tiff",
																					"image/webp",
																				],
																			url =
																				window.URL.createObjectURL(
																					new Blob(
																						[
																							response.data,
																						],
																						{
																							type: contentType,
																						}
																					)
																				),
																			link =
																				document.createElement(
																					"a"
																				);

																		link.href =
																			url;

																		link.setAttribute(
																			"download",
																			file.File_Name
																		);

																		document.body.appendChild(
																			link
																		);
																		link.click();
																	}
																}}
															>
																<GetAppIcon />
															</IconButton>
															<IconButton
																edge="end"
																aria-label="download"
																disabled={
																	this.state
																		.viewMood
																}
																onClick={async () => {
																	this.props.setLoading(
																		true,
																		"dark",
																		3000
																	);
																	const response =
																		await this.deleteFile(
																			file
																		);
																	this.props.setLoading(
																		false
																	);
																}}
															>
																<DeleteIcon />
															</IconButton>
														</ListItem>
													</Col>
												))}
											{!this.state.viewMood ? (
												<>
													<Col
														className="my-4 justify-content-start d-flex"
														xs={12}
													>
														<ButtonPrimary
															color="primary"
															variant="outlined"
															type="button"
															onClick={(e) => {
																this.setState({
																	viewMood: true,
																});
															}}
														>
															{this.props.t(
																"cancel"
															)}
														</ButtonPrimary>
														<ButtonPrimary
															color="primary"
															variant="contained"
															type="submit"
														>
															{this.props.t(
																"save"
															)}
														</ButtonPrimary>
													</Col>
												</>
											) : (
												<Col
													className="my-4 justify-content-start d-flex"
													xs={12}
												>
													<ButtonPrimary
														color="primary"
														variant="contained"
														type="button"
														onClick={(e) => {
															this.setState({
																viewMood: false,
															});
														}}
													>
														{this.props.t("edit")}
													</ButtonPrimary>
												</Col>
											)}
										</div>
									</AccordionDetails>
								</Accordion>
							</div>
							<div className="col-md-12">
								<Accordion
									className={`${style.accordionWrap} py-4 mb-3`}
									expanded={this.state.expanded === "panel4"}
									onChange={this.handleChange("panel4")}
									key={`task_${this.state.initValues?.SMTP_From_Mail}`}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`task_${this.state.initValues?.SMTP_From_Mail}`}
										id={`task_`}
									>
										<h3>
											{this.props.t("generalSetting")}
										</h3>
									</AccordionSummary>
									<AccordionDetails
										className={style.accordionDetail}
									>
										<div className="row mx-0">
											<Col xs={12} md={6}>
												<Select
													disabled={
														this.state.viewMood
													}
													label={this.props.t(
														"sliderType"
													)}
													required={true}
													name="Portal_Slider_Type_Id"
													data={
														this.state.sliderTypes
													}
													className={`${
														this.state.viewMood
															? "read-only"
															: ""
													} mb-2`}
													showError={() => {
														let fieldStatus =
															form.getFieldState(
																"Portal_Slider_Type_Id"
															);

														if (fieldStatus) {
															return (
																fieldStatus.submitFailed &&
																fieldStatus.invalid
															);
														}
													}}
												></Select>
											</Col>
											{!this.state.viewMood ? (
												<>
													<Col
														className="my-4 justify-content-start d-flex"
														xs={12}
													>
														<ButtonPrimary
															color="primary"
															variant="outlined"
															type="button"
															onClick={(e) => {
																this.setState({
																	viewMood: true,
																});
															}}
														>
															{this.props.t(
																"cancel"
															)}
														</ButtonPrimary>
														<ButtonPrimary
															color="primary"
															variant="contained"
															type="submit"
														>
															{this.props.t(
																"save"
															)}
														</ButtonPrimary>
													</Col>
												</>
											) : (
												<Col
													className="my-4 justify-content-start d-flex"
													xs={12}
												>
													<ButtonPrimary
														color="primary"
														variant="contained"
														type="button"
														onClick={(e) => {
															this.setState({
																viewMood: false,
															});
														}}
													>
														{this.props.t("edit")}
													</ButtonPrimary>
												</Col>
											)}

											{/* <Col xs={12} md={6}>
                                            <TextField
                                                label={this.props.t('accountName')}
                                                className='mb-2'
                                                name='Template_Name_AR'
                                            />
                                        </Col> */}
											{/* 
                                        Inspection_Template_File_Code,
                                        Inspection_Template_File_Name,
                                        Inspection_Template_File_Size,
                                        Inspection_Template_File_Type
                                        */}
										</div>
									</AccordionDetails>
								</Accordion>
							</div>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(SystemSetting));
