import apiConfig from "../../apiConfig";

export const getLicenseActivityInfo = async (at, id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/ACTIVITIES/Get_License_Activity_Info",
		{
			License_Activity_Id: id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
