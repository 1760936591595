import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import apiConfig from "../../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import moment from "moment";
import { ButtonWhite } from "../../UI/Buttons";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";

import { getListDepartmentServiceTemplatesFiles } from "../../../apis/services/departments/getListDepartmentServiceTemplatesFiles";
import { downloadDepartmentTemplateAttachment } from "../../../apis/services/departments/downloadDepartmentTemplateAttachment";
import { deleteDepartmentTemplateAttachment } from "../../../apis/services/departments/deleteDepartmentTemplateAttachment";
import { saveDepartmentTemplateAttachment } from "../../../apis/services/departments/saveDepartmentTemplateAttachment";
import { testDepartmentTemplateAttachment } from "../../../apis/services/departments/testDepartmentTemplateAttachment";
import { updateDepartmentTemplateAttachment } from "../../../apis/services/departments/updateDepartmentTemplateAttachment";
import { updateDepartmentTemplateAttachmentList } from "../../../apis/services/departments/updateDepartmentTemplateAttachmentList";

import {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
} from "../../../actions";
import { translationHook } from "../../translationHook";

import "./Templates.module.scss";
import imgLoading from "./../../../images/ShMunAnimatedGif.gif";
import imgLoadingStrip from "./../../../imagesTemp/STRIPAnimatGif-ForceWhite.gif";

class ServiceTemplatesList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	state = {
		fileIdsList: [],
	};

	handleUploadClick = async (
		event,
		Template_Id,
		Department_Service_Version_Id,
		Department_Service_Id,
		update = false
	) => {
		let file = event.target.files[0],
			response;

		if (file) {
			let at = this.props.currentUser.Data.access_token;

			this.props.setLoading(true, "dark");

			if (update) {
				response = await updateDepartmentTemplateAttachment(
					at,
					Department_Service_Version_Id,
					Department_Service_Id,
					Template_Id,
					file
				);
			} else {
				response = await saveDepartmentTemplateAttachment(
					at,
					Department_Service_Version_Id,
					Department_Service_Id,
					Template_Id,
					file
				);
			}

			this.props.triggerNotification(true, response.data);

			this.props.setLoading(false);

			if (response.data.Status) {
				this.props.reload();
			}
		}
	};

	handleUploadListClick = async (event, Template_Id) => {
		let file = event.target.files[0],
			response;

		if (file) {
			let at = this.props.currentUser.Data.access_token;

			this.props.setLoading(true, "dark");

			response = await updateDepartmentTemplateAttachmentList(
				at,
				Template_Id,
				this.state.fileIdsList,
				file
			);

			this.props.triggerNotification(true, response.data);

			this.props.setLoading(false);

			if (response.data.Status) {
				this.props.reload();
			}
		}
	};

	customizeUploadCell = ({ data }) => {
		if (data.File_Code) {
			return (
				<List className="grid-list-item">
					<ListItem key={data.Department_Service_Template_File_Id}>
						<div className="d-flex w-100 flex-column align-items-end">
							<ListItemText
								className="text-center"
								primary={data.File_Name}
								secondary={data.File_Size}
							/>

							<ListItemSecondaryAction
								className="d-flex"
								style={{
									position: "unset",
									top: "auto",
									transform: "none",
								}}
							>
								<>
									<IconButton
										edge="end"
										aria-label="download"
										onClick={async () => {
											let at =
												this.props.currentUser.Data
													.access_token;

											let downloadResult =
												await downloadDepartmentTemplateAttachment(
													{
														at,
														File_Code:
															data.File_Code,
														Department_Service_Version_Id:
															data.Department_Service_Version_Id,
													}
												);

											if (downloadResult.status === 200) {
												let contentType =
														downloadResult.headers[
															"content-type"
														],
													typesArray = [
														"application/pdf",
														"image/bmp",
														"image/gif",
														"image/vnd.microsoft.icon",
														"image/jpeg",
														"image/png",
														"image/svg+xml",
														"image/tiff",
														"image/webp",
													],
													url =
														window.URL.createObjectURL(
															new Blob(
																[
																	downloadResult.data,
																],
																{
																	type: contentType,
																}
															)
														),
													link =
														document.createElement(
															"a"
														);

												if (
													downloadResult?.data?.Data
														?.File_Bytes
												) {
													url =
														window.URL.createObjectURL(
															new Blob(
																[
																	downloadResult
																		.data
																		.Data
																		.File_Bytes,
																],
																{
																	type: contentType,
																}
															)
														);

													data.File_Name =
														downloadResult.data.Data.File_Name;
												}

												link.href = url;

												link.setAttribute(
													"download",
													data.File_Name
												);

												document.body.appendChild(link);
												link.click();
											}
										}}
									>
										<GetAppIcon />
									</IconButton>

									{this.props.editMood &&
										Boolean(data.Can_Update) && (
											<IconButton
												edge="end"
												className="grid-upload-button"
											>
												<input
													type="file"
													onChange={(e) => {
														this.handleUploadClick(
															e,
															data.Template_Id,
															data.Department_Service_Version_Id,
															data.Department_Service_Id,
															true
														);
													}}
												/>
												<PublishIcon />
											</IconButton>
										)}

									{this.props.editMood &&
										Boolean(data.Can_Delete) && (
											<IconButton
												className="mx-2"
												edge="end"
												aria-label="delete"
												onClick={async () => {
													let at =
														this.props.currentUser
															.Data.access_token;

													let response =
														await deleteDepartmentTemplateAttachment(
															at,
															data.Department_Service_Version_Id,
															data.File_Code
														);

													if (response.data.Status) {
														this.props.reload();
													}
												}}
											>
												<DeleteIcon />
											</IconButton>
										)}

									{Boolean(data.Can_Test) && (
										<button
											id={
												data.Department_Service_Template_File_Id
											}
											className="testTemplateBtn"
											onClick={async (e) => {
												let at =
													this.props.currentUser.Data
														.access_token;

												if (
													data.Department_Service_Template_File_Id ==
													e.target.id
												) {
													e.target.disabled = true;
													e.target.childNodes[0].innerHTML =
														" ";
													e.target.style.backgroundImage = `url(${
														process.env
															.REACT_APP_IMG_SRC ==
														2
															? imgLoadingStrip
															: imgLoading
													})`;
													// e.target.childNodes[0].innerHTML = `<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"></path></svg>`
												}

												let downloadResult =
													await testDepartmentTemplateAttachment(
														at,
														data.Department_Service_Version_Id,
														data.Template_Id,
														data.File_Code
													);

												if (
													downloadResult.status ===
													200
												) {
													let contentType =
															downloadResult
																.headers[
																"content-type"
															],
														typesArray = [
															"application/pdf",
															"image/bmp",
															"image/gif",
															"image/vnd.microsoft.icon",
															"image/jpeg",
															"image/png",
															"image/svg+xml",
															"image/tiff",
															"image/webp",
														],
														url =
															window.URL.createObjectURL(
																new Blob(
																	[
																		downloadResult.data,
																	],
																	{
																		type: contentType,
																	}
																)
															),
														link =
															document.createElement(
																"a"
															);

													if (
														downloadResult?.data
															?.Data?.File_Bytes
													) {
														url =
															window.URL.createObjectURL(
																new Blob(
																	[
																		downloadResult
																			.data
																			.Data
																			.File_Bytes,
																	],
																	{
																		type: contentType,
																	}
																)
															);
														data.File_Name =
															downloadResult.data.Data.File_Name;
													}

													link.href = url;

													typesArray.includes(
														contentType
													)
														? link.setAttribute(
																"target",
																"_blank"
														  )
														: link.setAttribute(
																"download",
																data.File_Name
														  );

													document.body.appendChild(
														link
													);
													link.click();

													e.target.childNodes[0].innerHTML = `<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>`;
													e.target.style.backgroundImage =
														"none";
													e.target.disabled = false;
												}
											}}
										>
											<svg
												class="MuiSvgIcon-root"
												focusable="false"
												viewBox="0 0 24 24"
												aria-hidden="true"
											>
												<path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
											</svg>
										</button>
									)}
								</>
							</ListItemSecondaryAction>
						</div>
					</ListItem>
				</List>
			);
		} else {
			if (this.props.editMood) {
				return (
					<div className="d-flex justify-content-end">
						<IconButton className="grid-upload-button m-0">
							<input
								type="file"
								onChange={(e) => {
									this.handleUploadClick(
										e,
										data.Template_Id,
										data.Department_Service_Version_Id,
										// this.props.departmentServiceId
										data.Department_Service_Id
									);
								}}
							/>
							<PublishIcon />
						</IconButton>
					</div>
				);
			} else {
				return null;
			}
		}
	};

	handleSelectionChanged = (data) => {
		this.setState({ fileIdsList: data.selectedRowKeys });
	};

	render() {
		let columnAlign = "left",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "Department_Service_Template_File_Id",
			load: () => {
				return getListDepartmentServiceTemplatesFiles(
					at,
					this.props.moduleId
				);
			},
		});

		return (
			<>
				<div className="d-flex mb-4 justify-content-end upload-btn-wrapper">
					<ButtonWhite variant="solid">
						{this.props.t("updateSelectedServicesFiles")}
						<input
							type="file"
							onChange={(e) => {
								this.handleUploadListClick(
									e,
									this.props.moduleId
								);
							}}
						/>
					</ButtonWhite>
				</div>

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={false}
					onExporting={this.onExporting}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
					onSelectionChanged={this.handleSelectionChanged}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						dataField={"Service_Code"}
						caption={this.props.t("serviceCode")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "en"
								? "Service_Name_EN"
								: "Service_Name_AR"
						}
						caption={this.props.t("serviceName")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "en"
								? "Department_Name_EN"
								: "Department_Name_AR"
						}
						caption={this.props.t("departments")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={"Department_Service_Version_Name"}
						caption={this.props.t("version")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "en"
								? "Department_Service_Version_Status_Name_EN"
								: "Department_Service_Version_Status_Name_AR"
						}
						caption={this.props.t("versionStatus")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "en"
								? "User_Name_EN"
								: "User_Name_AR"
						}
						caption={this.props.t("userName")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={"Upd_Time"}
						caption={this.props.t("updateTime")}
						alignment={columnAlign}
						cssClass="dir-ltr"
						calculateCellValue={(data) => {
							return moment(data.Upd_Time).format(
								"YYYY-MM-DD, hh:mm A "
							);
						}}
					></Column>

					<Column
						caption={this.props.t("attachments")}
						cellRender={this.customizeUploadCell}
						width="200"
					></Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("manageServices");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
})(translationHook(ServiceTemplatesList));
