import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../translationHook";
import apiConfig from "../../apis/apiConfig";
import { ButtonPrimary } from "../UI/Buttons";
import { Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField, Switches } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../actions";

class LicenseActivitySegmentsForm extends Component {
	state = {
		showForm: false,
	};

	onSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = !this.props.editMood
				? "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Add_License_Segment"
				: "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Upd_License_Segment",
			bodyData = {
				License_Segment_Name_AR: values.License_Segment_Name_AR,
				License_Segment_Name_EN: values.License_Segment_Name_EN,
				Active_Status_Id: values.Active_Status_Id == true ? 1 : 0,
			};

		if (this.props.editMood) {
			bodyData["License_Segment_Id"] = this.props.segmentId;
		}

		this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status) {
			form.restart();
			this.setState({ showForm: false });
		}

		if (this.props.editMood) {
			this.props.triggerDialogForm(false);
		}

		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);
	};

	render() {
		let { initValues } = this.props;

		const validate = (values) => {
			const errors = {};
			if (!values.License_Segment_Name_AR) {
				errors.License_Segment_Name_AR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.License_Segment_Name_EN) {
				errors.License_Segment_Name_EN = `${this.props.t(
					"error.field_required"
				)}`;
			}

			return errors;
		};

		return (
			<>
				{!this.props.editMood && !this.props.viewMood && (
					<div className="d-flex justify-content-between align-items-center pb-5">
						<h3>{this.props.t("licenseSegments")}</h3>
						<ButtonPrimary
							variant={
								!this.state.showForm ? "contained" : "outlined"
							}
							onClick={() =>
								this.setState({
									showForm: !this.state.showForm,
								})
							}
						>
							{!this.state.showForm && this.props.t("addSegment")}
							{this.state.showForm && this.props.t("cancel")}
						</ButtonPrimary>
					</div>
				)}

				<Form
					onSubmit={this.onSubmit}
					initialValues={
						initValues
							? initValues
							: {
									License_Segment_Name_AR: "",
									License_Segment_Name_EN: "",
									Active_Status_Id: "",
							  }
					}
					validate={validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="sliderform"
							className={`row ${
								!this.state.showForm &&
								!this.props.editMood &&
								!this.props.viewMood
									? "d-none"
									: ""
							}`}
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("segmentAR")}
									className="mb-2"
									name="License_Segment_Name_AR"
									required={true}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("segmentEN")}
									className="mb-2"
									name="License_Segment_Name_EN"
									required={true}
								/>
							</Col>

							<Col xs={12} md={6}>
								<Switches
									disabled={this.props.viewMood}
									color="primary"
									name="Active_Status_Id"
									data={{
										label: `${this.props.t(
											"serviceStatus"
										)}`,
										value: `${this.props.t(
											"serviceStatus"
										)}`,
									}}
								/>
							</Col>

							<Col
								className="my-4 justify-content-start d-flex"
								xs={12}
							>
								{!this.props.viewMood && (
									<ButtonPrimary
										color="primary"
										variant="contained"
										disabled={
											submitting || this.props.viewMood
										}
										type="submit"
									>
										{this.props.t("save")}
									</ButtonPrimary>
								)}
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(LicenseActivitySegmentsForm));
