import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Link } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { ButtonSecondary } from "./../UI/Buttons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import style from "./Service.module.scss";
import ServiceInfo from "./ServiceInfo";
import ServiceDealingType from "./ServiceDealingType";
import ServiceDetail from "./ServiceDetail";
import ServiceStep from "./ServiceStep";
import ServiceTemplatesList from "./ServiceTemplatesList";
import ServiceDetailsFormsList from "./ServiceDetailsFormsList";
import ServiceDetailsFormInputs from "./ServiceDetailsFormInputs";
import { translationHook } from "../translationHook";
import { getServiceInfo } from "../../apis/services/getServiceInfo";

class Service extends Component {
	state = {
		key: 0,
		next: 1,
		prev: -1,
		serviceID: null,
		first: false,
		second: true,
		third: true,
		fourth: true,
		fifth: true,
		sixth: true,
		seventh: true,
		edit: false,
		view: false,
		serviceInfoInit: {
			serviceStatus: true,
			serviceRulesAR:
				this.props.location.state.serviceInfo?.Service_Rules_AR,
			serviceRulesEN:
				this.props.location.state.serviceInfo?.Service_Rules_EN,
			serviceRequirmentsAR:
				this.props.location.state.serviceInfo?.Service_Requirements_AR,
			serviceRequirmentsEN:
				this.props.location.state.serviceInfo?.Service_Requirements_EN,
			serviceImg: this.props.location.state.serviceInfo?.Service_Logo_URL,
		},
	};

	handleNext = () => {
		this.setState({
			key: this.state.next,
			next: this.state.next + 1,
			prev: this.state.prev + 1,
		});
	};

	handlePrev = () => {
		this.setState({
			key: this.state.prev,
			next: this.state.next - 1,
			prev: this.state.prev - 1,
		});
	};

	handleFirstStepDone = (sID, values) => {
		this.setState({
			serviceID: sID,
			key: 1,
			second: false,
			third: false,
			fourth: false,
			fifth: false,
			sixth: false,
			seventh: false,
			edit: true,
			serviceInfoInit: values,
		});
	};

	renderChevs = () => {
		let rtl = this.props.i18n.language === "ar" ? true : false,
			iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
			iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

		return (
			<>
				<IconButton
					aria-label="prev"
					color="primary"
					onClick={this.handlePrev}
					disabled={this.state.key > 0 ? false : true}
				>
					{iconPrev}
				</IconButton>
				<IconButton
					aria-label="next"
					color="primary"
					onClick={this.handleNext}
					disabled={
						this.state.key < 4 && !this.state.second ? false : true
					}
				>
					{iconNext}
				</IconButton>
			</>
		);
	};

	componentDidMount() {
		if (this.props.location.state.serviceInfo) {
			let values = {};

			const response = this.props.location.state.serviceInfo;
			if (response) {
				values.serviceNo = response.Service_No;
				values.serviceCode = response.Service_Code;
				values.serviceNameAR = response.Service_Name_AR;
				values.serviceNameEN = response.Service_Name_EN;
				values.servicePlaceNameAR = response.Service_Place_Name_AR;
				values.servicePlaceNameEN = response.Service_Place_Name_EN;
				values.serviceHoursAR = response.Service_Working_Hours_AR;
				values.serviceHoursEN = response.Service_Working_Hours_EN;
				values.serviceDescAR = response.Service_Description_AR;
				values.serviceDescEN = response.Service_Description_EN;
				values.serviceRulesAR = response.Service_Rules_AR;
				values.serviceRulesEN = response.Service_Rules_EN;
				values.serviceRequirmentsAR = response.Service_Requirements_AR;
				values.serviceRequirmentsEN = response.Service_Requirements_EN;
				values.serviceStatus = response.Active_Status_Id ? true : false;
				values.serviceTime = response.Service_Total_Time;
				values.serviceTimeType = response.Service_Time_Type_Id;
				values.serviceCategoryID = response.Service_Category_Id;
				values.serviceImg = response.Service_Logo_URL;
				values.chartSignature = response.Charts_Signatures;
			}

			// if #view in url set state to view mood to prevent submititons and hide submit UI components in all steps
			this.setState({
				serviceID: this.props.serviceId,
				second: false,
				third: false,
				fourth: false,
				fifth: false,
				sixth: false,
				seventh: false,
				edit: this.props.location.hash === "#view" ? false : true,
				view: this.props.location.hash === "#view" ? true : false,
				serviceInfoInit: { ...this.state.serviceInfoInit, ...values },
			});
		}
	}

	render() {
		let mystyle;
		if (this.props.i18n.language === "ar") {
			mystyle = "ServiceTabsAr";
		} else {
			mystyle = "ServiceTabsEn";
		}

		return (
			<Tab.Container
				id="left-tabs-example"
				defaultActiveKey={0}
				activeKey={this.state.key}
				onSelect={(k) =>
					this.setState({
						key: parseInt(k),
						next: parseInt(k) + 1,
						prev: parseInt(k) - 1,
					})
				}
				unmountOnExit="true"
			>
				<Row>
					<Col
						sm={12}
						className="d-flex justify-content-between align-items-center"
					>
						<div>{this.renderChevs()}</div>
						<Nav variant="pills" className={style.stepsNav}>
							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 0 ? style.active : ""
									}`}
									eventKey="0"
									disabled={this.state.first}
								>
									{this.props.t("serviceInfo")}
								</Nav.Link>
							</Nav.Item>
							{/* <Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 1 ? style.active : ""
									}`}
									eventKey="1"
									disabled={this.state.second}
								>
									{this.props.t("servicesActionsOrdersStep")}
								</Nav.Link>
							</Nav.Item> */}
							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 1 ? style.active : ""
									}`}
									eventKey="1"
									disabled={this.state.second}
								>
									{this.props.t("serviceDealingType")}
								</Nav.Link>
							</Nav.Item>
							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 2 ? style.active : ""
									}`}
									eventKey="2"
									disabled={this.state.third}
								>
									{this.props.t("serviceTemplates")}
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 3 ? style.active : ""
									}`}
									eventKey="3"
									disabled={this.state.fourth}
								>
									{this.props.t("applicationSteps")}
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 4 ? style.active : ""
									}`}
									eventKey="4"
									disabled={this.state.fifth}
								>
									{this.props.t("serviceDetails")}
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 5 ? style.active : ""
									}`}
									eventKey="5"
									disabled={this.state.sixth}
								>
									{this.props.t("serviceInputsOrder")}
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 6 ? style.active : ""
									}`}
									eventKey="6"
									disabled={this.state.seventh}
								>
									{this.props.t("serviceSteps")}
								</Nav.Link>
							</Nav.Item>
						</Nav>
						<ButtonSecondary
							variant="outlined"
							endIcon={<ExitToAppIcon />}
							component={Link}
							to="/services"
							size="large"
						>
							{this.props.t("exitToServices")}
						</ButtonSecondary>
					</Col>
					<Col sm={12}>
						<Tab.Content>
							<Tab.Pane eventKey={0} disabled={this.state.first}>
								<ServiceInfo
									serviceid={this.state.serviceID}
									active={!this.state.first}
									initValues={this.state.serviceInfoInit}
									onStepDone={this.handleFirstStepDone}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane>

							{/* <Tab.Pane eventKey={1} disabled={this.state.second}>
								<ServiceActions
									serviceid={this.state.serviceID}
									active={!this.state.second}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane> */}

							<Tab.Pane
								eventKey={1}
								disabled={this.state.second}
								unmountOnExit="true"
							>
								<ServiceDealingType
									serviceid={this.state.serviceID}
									active={!this.state.second}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane>

							<Tab.Pane
								eventKey={2}
								disabled={this.state.third}
								unmountOnExit="true"
							>
								<ServiceTemplatesList
									serviceid={this.state.serviceID}
									active={!this.state.third}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane>

							<Tab.Pane
								eventKey={3}
								disabled={this.state.fourth}
								unmountOnExit="true"
							>
								<ServiceDetailsFormsList
									serviceid={this.state.serviceID}
									active={!this.state.fourth}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane>

							<Tab.Pane
								eventKey={4}
								disabled={this.state.fifth}
								unmountOnExit="true"
							>
								<ServiceDetail
									serviceid={this.state.serviceID}
									active={!this.state.fifth}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane>

							<Tab.Pane
								eventKey={5}
								disabled={this.state.sixth}
								unmountOnExit="true"
							>
								<ServiceDetailsFormInputs
									serviceid={this.state.serviceID}
									active={!this.state.sixth}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane>

							<Tab.Pane
								eventKey={6}
								disabled={this.state.seventh}
								unmountOnExit="true"
							>
								<ServiceStep
									serviceid={this.state.serviceID}
									active={!this.state.seventh}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(translationHook(Service));
