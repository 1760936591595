import React, { useState, useEffect, useRef } from "react";
import style from "./SideMenu.module.scss";
import clsx from "clsx";
import { Drawer, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import primaryLogo from "./../../../images/login/logos/Sharjah-Mun-Logo1.svg";
import primaryLogoStrip from "./../../../imagesTemp/login/logos/STRIP-Logo1.png";

import Menu from "./Menu/Menu";
// import { useParams } from 'react-router-dom'

const SideMenu = (props) => {
	const { t, i18n } = useTranslation("common");
	const [open, setOpen] = useState(false);
	const [openOnHover, setOpenOnHover] = useState(false);
	const [openOnClick, setOpenOnClick] = useState(false);
	// let { tabName } = useParams()

	var drawerCon = useRef(null);

	useEffect(() => {
		props.currentWidth(open);
	}, [open]);

	let useStyles = makeStyles((theme) => ({
		drawer: {
			width: openOnHover ? "73px!important" : 240,
			flexShrink: 0,
			whiteSpace: "nowrap",
		},
		drawerOpen: {
			width: 265,
			overflowX: "hidden",
			zIndex: 1300,
			border: "none",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			zIndex: 1300,
			border: "none",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflow: "hidden",
			width: theme.spacing(6.5) + 1,
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9) + 1,
			},
		},
		respDrawer: {
			width: 275,
			direction: i18n.language === "en" ? "ltr" : "rtl",
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
		},
	}));

	const classes = useStyles();

	const handleDrawer = () => {
		setOpen(!open);
		setOpenOnClick(!openOnClick);
		// equalWidth()
	};

	const handleDrawerClose = (e) => {
		if (e.target.className === "MuiBackdrop-root") {
			props.onToggleSide();
		}
	};

	const handleOpenOnHover = (e) => {
		if (!open) {
			setOpen(true);
			setOpenOnHover(true);
		}
	};

	const handleCloseAfterHover = () => {
		if (!openOnClick) {
			setOpen(false);
			setOpenOnHover(false);
		}
	};

	// Content Container
	// useEffect(() => {
	//     if (!open) {
	//         document.querySelector('.tabsContainer').style.width = 'calc(100vw - 100px)'
	//     } else if (open && !openOnHover) {
	//         document.querySelector('.tabsContainer').style.width = 'calc(100vw - 293px)'
	//     }
	// }, [open])

	const menuList = (
		<Menu
			// onOpenOnHover={handleOpenOnHover}
			onCloseAfterHover={handleCloseAfterHover}
			onOpen={open}
			onOpenResp={props.respSideDrawer}
		/>
	);

	const toggleBtn = () => {
		if (i18n.language === "en") {
			if (open) {
				return (
					<svg
						viewBox="0 0 24 24"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlnsXlink="http://www.w3.org/1999/xlink"
					>
						<title>
							Stockholm-icons / Navigation / Angle-double-left
						</title>
						<desc>Created with Sketch.</desc>
						<defs></defs>
						<g
							id="Stockholm-icons-/-Navigation-/-Angle-double-left"
							stroke="none"
							strokeWidth="1"
							fill="none"
							fillRule="evenodd"
						>
							<polygon
								id="Shape"
								points="0 0 24 0 24 24 0 24"
							></polygon>
							<path
								d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
								id="Path-94"
								fill="#000000"
								fillRule="nonzero"
								transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "
							></path>
							<path
								d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
								id="Path-94"
								fill="#000000"
								fillRule="nonzero"
								opacity="0.3"
								transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "
							></path>
						</g>
					</svg>
				);
			} else {
				return (
					<svg
						viewBox="0 0 24 24"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlnsXlink="http://www.w3.org/1999/xlink"
					>
						<title>
							Stockholm-icons / Navigation / Angle-double-right
						</title>
						<desc>Created with Sketch.</desc>
						<defs></defs>
						<g
							id="Stockholm-icons-/-Navigation-/-Angle-double-right"
							stroke="none"
							strokeWidth="1"
							fill="none"
							fillRule="evenodd"
						>
							<polygon
								id="Shape"
								points="0 0 24 0 24 24 0 24"
							></polygon>
							<path
								d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
								id="Path-94"
								fill="#000000"
								fillRule="nonzero"
							></path>
							<path
								d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
								id="Path-94"
								fill="#000000"
								fillRule="nonzero"
								opacity="0.3"
								transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
							></path>
						</g>
					</svg>
				);
			}
		} else {
			if (!open) {
				return (
					<svg
						viewBox="0 0 24 24"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlnsXlink="http://www.w3.org/1999/xlink"
					>
						<title>
							Stockholm-icons / Navigation / Angle-double-left
						</title>
						<desc>Created with Sketch.</desc>
						<defs></defs>
						<g
							id="Stockholm-icons-/-Navigation-/-Angle-double-left"
							stroke="none"
							strokeWidth="1"
							fill="none"
							fillRule="evenodd"
						>
							<polygon
								id="Shape"
								points="0 0 24 0 24 24 0 24"
							></polygon>
							<path
								d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
								id="Path-94"
								fill="#000000"
								fillRule="nonzero"
								transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "
							></path>
							<path
								d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
								id="Path-94"
								fill="#000000"
								fillRule="nonzero"
								opacity="0.3"
								transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "
							></path>
						</g>
					</svg>
				);
			} else {
				return (
					<svg
						viewBox="0 0 24 24"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlnsXlink="http://www.w3.org/1999/xlink"
					>
						<title>
							Stockholm-icons / Navigation / Angle-double-right
						</title>
						<desc>Created with Sketch.</desc>
						<defs></defs>
						<g
							id="Stockholm-icons-/-Navigation-/-Angle-double-right"
							stroke="none"
							strokeWidth="1"
							fill="none"
							fillRule="evenodd"
						>
							<polygon
								id="Shape"
								points="0 0 24 0 24 24 0 24"
							></polygon>
							<path
								d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
								id="Path-94"
								fill="#000000"
								fillRule="nonzero"
							></path>
							<path
								d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
								id="Path-94"
								fill="#000000"
								fillRule="nonzero"
								opacity="0.3"
								transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
							></path>
						</g>
					</svg>
				);
			}
		}
	};

	return (
		<>
			<Hidden smDown>
				<Drawer
					ref={drawerCon}
					anchor={i18n.language === "en" ? "left" : "right"}
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
					}}
				>
					<div
						className={[classes.toolbar, "toolbar"].join(" ")}
						style={{
							justifyContent: !open ? "center" : "space-between",
						}}
					>
						<span
							style={{
								display: !open ? "none" : "block",
								width: "100%",
							}}
						>
							<img
								src={primaryLogoStrip}
								className={style.logoImg}
							/>
						</span>
						<span
							className={[style.toggleBtn, "svg-md"].join(" ")}
							onClick={handleDrawer}
						>
							{toggleBtn()}
						</span>
					</div>

					{menuList}
				</Drawer>
			</Hidden>

			<Hidden mdUp>
				<Drawer
					anchor={i18n.language === "en" ? "left" : "right"}
					open={props.respSideDrawer}
					onClick={(e) => {
						handleDrawerClose(e);
					}}
					classes={{
						paper: classes.respDrawer,
					}}
				>
					{menuList}
				</Drawer>
			</Hidden>
		</>
	);
};

// const equalWidth = () => {

// }

export default SideMenu;
