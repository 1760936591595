import React, { Component } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	Pager,
} from "devextreme-react/data-grid";
import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import CustomStore from "devextreme/data/custom_store";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
} from "../../actions";
import { translationHook } from "../translationHook";
import apiConfig from "../../apis/apiConfig";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import LicenseActivityGroupForm from "./LicenseActivityGroupForm";
import { ButtonDanger, ButtonInfo, ButtonWarning } from "../UI/Buttons";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { deleteActivityGroup } from "../../apis/licenses/licenseActivities/deleteActivityGroup";

class LicenseActivityGroup extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	state = {
		showForm: false,
	};

	onSubmit = () => {};

	renderDocumentForm = (...params) => {
		let viewMood = params[2] === "view" ? true : false,
			editMood = params[2] === "edit" ? true : false;

		return (
			<LicenseActivityGroupForm
				groupId={params[0]}
				initValues={params[1]}
				editMood={editMood}
				viewMood={viewMood}
			/>
		);
	};

	getLicenseActivitiesGroupsList = async () => {
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/ACTIVITIES/Get_Licenses_Activities_Groups",
			{},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);
		return response.data.Data;
	};

	handleCatState = ({ data }) => {
		let label =
			this.props.i18n.language === "ar"
				? data.Active_Status_Name_AR
				: data.Active_Status_Name_EN;
		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Active_Status_Id == 0 ? false : true}
						name={`doc_${data.Active_Status_Id}`}
						onChange={async () => {
							this.props.setLoading(true, "dark");

							const response = await apiConfig.post(
								"/API/ADMINISTRATION/LICENSES/ACTIVITIES/Set_License_Activity_Group_Status",
								{
									License_Activity_Group_Id:
										data.License_Activity_Group_Id,
									Active_Status_Id:
										data.Active_Status_Id == 0 ? 1 : 0,
								},
								{
									headers: {
										Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
									},
								}
							);
							this.dataGrid?.instance?.refresh();
							this.props.setLoading(false);
						}}
					/>
				}
				label={label}
			/>
		);
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			documentTypeId = data.License_Activity_Group_Id,
			documentName =
				this.props.i18n.language === "ar"
					? data.License_Activity_Group_Name_AR
					: data.License_Activity_Group_Name_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${documentName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteActivityGroup,
				params: [at, documentTypeId],
				text: this.props.t("deleteGroup"),
			},
		});
	};

	handleEdit = (data, viewMood) => {
		let initValues = {
				License_Activity_Group_Name_AR:
					data.License_Activity_Group_Name_AR,
				License_Activity_Group_Name_EN:
					data.License_Activity_Group_Name_EN,
				License_Activity_Group_Class: data.License_Activity_Group_Class,
				Active_Status_Id: data.Active_Status_Id,
			},
			groupId = data.License_Activity_Group_Id,
			documentName =
				this.props.i18n.language === "ar"
					? data.License_Activity_Group_Name_AR
					: data.License_Activity_Group_Name_EN,
			dialogTitle = `${
				viewMood === "view"
					? this.props.t("viewServiceDetailTitle")
					: this.props.t("editServiceDetailTitle")
			} ${documentName}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderDocumentForm,
			params: [groupId, initValues, viewMood],
		});
	};

	render() {
		let DocumentStatus = "Active_Status_Name_AR",
			DocumentType = "Document_Type_Name_AR",
			columnAlign = "center";

		if (this.props.i18n.language === "en") {
			DocumentStatus = "Active_Status_Name_EN";
			DocumentType = "Document_Type_Name_EN";
		}

		const jsonDataSource = new CustomStore({
			key: "License_Activity_Group_Id",
			load: () => {
				return this.getLicenseActivitiesGroupsList();
			},
		});

		return (
			<>
				<LicenseActivityGroupForm />

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
					ref={(ref) => (this.dataGrid = ref)}
				>
					<Pager
						visible="true"
						allowedPageSizes={[5, 10, 20, 50, 100]}
						displayMode="full"
						showPageSizeSelector="true"
						showInfo="true"
						infoText={`${this.props.t("page")} {0} ${this.props.t(
							"of"
						)} {1} ({2} ${this.props.t("item")})`}
						showNavigationButtons="true"
					/>

					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					{/* <Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/> */}

					<Column
						dataField="License_Activity_Group_Name_AR"
						caption={this.props.t("groupAR")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="License_Activity_Group_Name_EN"
						caption={this.props.t("groupEN")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="License_Activity_Group_Class"
						caption={this.props.t("groupClass")}
						alignment={columnAlign}
					></Column>

					<Column
						caption={this.props.t("serviceStatus")}
						alignment={columnAlign}
						cellRender={this.handleCatState}
						width={100}
					></Column>

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
										variant="circleIcon"
										startIcon={<EditIcon />}
										onClick={() => {
											this.handleEdit(
												record.data,
												"edit"
											);
										}}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
										variant="circleIcon"
										startIcon={<VisibilityIcon />}
										onClick={() => {
											this.handleEdit(
												record.data,
												"view"
											);
										}}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant="circleIcon"
										startIcon={<DeleteIcon />}
										onClick={() =>
											this.handleDelete(record.data)
										}
									>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("typesOfDocuments");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(LicenseActivityGroup));
