import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches, Autocomplete } from "mui-rff";
import DateBox from "devextreme-react/date-box";
import moment from "moment";
import { PatternFormat } from "react-number-format";
import apiConfig from "../../../apis/apiConfig";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ButtonSecondary, ButtonPrimary, ButtonDanger } from "../../UI/Buttons";
import { DataGrid, Column } from "devextreme-react/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";

import {
	reactivateLicenseVisa,
	delLicenseVisa,
	cancelLicenseVisa,
} from "../../../apis/licenses/LicensesList";

class LicenseVisaForm extends Component {
	state = {
		initValues: {},
		investorsList: [],
		nationalitiesList: [],
		countriesList: [],
		emiratesList: [],
		Holder_Passport_File: [],
		Holder_Visa_File: [],
		Holder_EmiratesID_Back_File: [],
		Holder_EmiratesID_Front_File: [],
		selectedInvestor: 0,
		lockHolderData: false,
		idFrontErr: false,
		idBackErr: false,
		dataLoaded: false,
	};

	selectFiles = (event, field) => {
		let filesData = [];

		for (const file in event.target.files) {
			if (Object.hasOwnProperty.call(event.target.files, file)) {
				const element = event.target.files[file];
				element["Can_Delete"] = 1;
				filesData.push(element);
			}
		}

		switch (field) {
			case "Holder_Visa_File":
				this.setState({ Holder_Visa_File: filesData });

				break;

			case "Holder_Passport_File":
				this.setState({ Holder_Passport_File: filesData });

				break;

			case "Holder_EmiratesID_Back_File":
				this.setState({
					Holder_EmiratesID_Back_File: filesData,
					idBackErr: false,
				});

				break;

			case "Holder_EmiratesID_Front_File":
				this.setState({
					Holder_EmiratesID_Front_File: filesData,
					idFrontErr: false,
				});

				break;

			default:
				break;
		}
	};

	getVisaInvestorsList = async () => {
		let investorsArr = [{ label: this.props.t("none"), value: 0 }],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/MANAGEMENT/Get_Visa_Valid_Investors_List",
			{
				License_Id: this.props.licenseId,
				License_Visa_Id: this.props.visaId ?? 0,
			},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Investor_Name_AR
						: item.Investor_Name_EN;

				investorsArr.push({
					value: item.License_Investor_Data_Id,
					label,
				});
			});

			this.setState({ investorsList: investorsArr });
		}
	};

	getVisaInvestorsDetails = async () => {
		let auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/MANAGEMENT/Get_Visa_Investor_Details",
			{
				License_Id: this.props.licenseId,
				License_Investor_Data_Id: this.state.selectedInvestor,
			},
			{
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState((state) => {
				return {
					initValues: {
						...state.initValues,
						...response.data.Data.Investor_Details,
					},
					Holder_Passport_File: [
						response.data.Data.Passport_File_Info,
					],
					lockHolderData: true,
				};
			});
		}
	};

	getNationalitiesList = async () => {
		let nationalitiesArr = [],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_NATIONALITIES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				nationalitiesArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ nationalitiesList: nationalitiesArr });
		}
	};

	getCountriesList = async () => {
		let countriesArr = [],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post("/API/SYSTEM/DDL/GET_COUNTRIES");

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				countriesArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ countriesList: countriesArr });
		}
	};

	getEmiratesList = async () => {
		let emiratesArr = [],
			auth = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post("/API/SYSTEM/DDL/GET_EMIRATES");

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				emiratesArr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ emiratesList: emiratesArr });
		}
	};

	componentDidMount() {
		Promise.all([
			this.getVisaInvestorsList(),
			this.getNationalitiesList(),
			this.getCountriesList(),
			this.getEmiratesList(),
		]).then(() => {
			this.setState({ dataLoaded: true });
		});

		if (this.props.initValues) {
			this.setState({ initValues: this.props.initValues });
		}

		if (this.props?.initValues?.License_Investor_Data_Id) {
			this.setState({
				selectedInvestor:
					this.props.initValues.License_Investor_Data_Id,
			});
		}

		if (this.props.files) {
			if (this.props.files.Passport_File_Info) {
				this.setState({
					Holder_Passport_File: [
						this.props.files?.Passport_File_Info,
					],
				});
			}

			if (this.props.files.Visa_File_Info) {
				this.setState({
					Holder_Visa_File: [this.props.files?.Visa_File_Info],
				});
			}

			if (this.props.files.EmiratesID_Back_File_Info) {
				this.setState({
					Holder_EmiratesID_Back_File: [
						this.props.files?.EmiratesID_Back_File_Info,
					],
				});
			}

			if (this.props.files.EmiratesID_Front_File_Info) {
				this.setState({
					Holder_EmiratesID_Front_File: [
						this.props.files?.EmiratesID_Front_File_Info,
					],
				});
			}
		}

		if (this.props.disableAllFields) {
			this.setState({ lockHolderData: this.props.disableAllFields });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedInvestor !== this.state.selectedInvestor) {
			if (this.state.selectedInvestor !== 0) {
				this.getVisaInvestorsDetails();
			} else if (this.state.selectedInvestor === 0) {
				this.setState({
					initValues: {},
					lockHolderData: false,
					Holder_Passport_File: [],
				});
			}
		}
	}

	onSubmit = async (values) => {
		const bodyFormData = new FormData(),
			at = this.props.currentUser.Data.access_token,
			url = this.props.visaId
				? "/API/ADMINISTRATION/LICENSES/MANAGEMENT/Upd_License_Visa"
				: "/API/ADMINISTRATION/LICENSES/MANAGEMENT/Add_License_Visa",
			bodyData = values;

		bodyData["License_Id"] = this.props.licenseId;

		//validate only holder Id files if visa issued
		if (
			values.Visa_Issued &&
			(this.state.Holder_EmiratesID_Back_File.length < 1 ||
				this.state.Holder_EmiratesID_Front_File.length < 1)
		) {
			if (this.state.Holder_EmiratesID_Back_File.length < 1) {
				this.setState({
					idBackErr: true,
				});
			}

			if (this.state.Holder_EmiratesID_Front_File.length < 1) {
				this.setState({
					idFrontErr: true,
				});
			}
			return null;
		}

		if (this.props.visaId) {
			bodyData["License_Visa_Id"] = this.props.visaId;
		}

		if (values.Visa_Issued) {
			bodyData["Visa_Issued"] = 1;
		} else {
			bodyData["Visa_Issued"] = 0;
		}

		bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

		if (
			this.state.Holder_Passport_File &&
			this.state.Holder_Passport_File.length > 0
		) {
			bodyFormData.append(
				"Holder_Passport_File",
				this.state.Holder_Passport_File[0]
			);
		}

		if (
			this.state.Holder_Visa_File &&
			this.state.Holder_Visa_File.length > 0
		) {
			bodyFormData.append(
				"Holder_Visa_File",
				this.state.Holder_Visa_File[0]
			);
		}

		if (
			this.state.Holder_EmiratesID_Back_File &&
			this.state.Holder_EmiratesID_Back_File.length > 0
		) {
			bodyFormData.append(
				"Holder_EmiratesID_Back_File",
				this.state.Holder_EmiratesID_Back_File[0]
			);
		}

		if (
			this.state.Holder_EmiratesID_Front_File &&
			this.state.Holder_EmiratesID_Front_File.length > 0
		) {
			bodyFormData.append(
				"Holder_EmiratesID_Front_File",
				this.state.Holder_EmiratesID_Front_File[0]
			);
		}

		this.props.setLoading(true, "dark", 9999999);

		const response = await apiConfig
			.post(url, bodyFormData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerDialogForm(false);
					this.props.triggerNotification(true, res.data);
					this.props.reloadCB();
				} else {
					throw res;
				}

				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);
				this.props.triggerNotification(
					true,
					err.response ? err.response.data : err.data
				);
				return err;
			});
	};

	handleAttachmentDownload = ({ data }) => {
		return (
			<GetAppIcon
				style={{ width: "100%", cursor: "pointer" }}
				onClick={async () => {
					let params = {
						at: this.props.currentUser.Data.access_token,
						File_Code: data.License_Attachment_File_Code,
					};
					const downloadResult = await downloadAttachment(params);
					const url = window.URL.createObjectURL(
						new Blob([downloadResult.data], {
							type: "application/pdf",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("target", "_blank");
					link.click();
				}}
			/>
		);
	};

	handleAction = async (action) => {
		const at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true);

		const response = await action(
			at,
			this.props.licenseId,
			this.props.visaId
		);

		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);

		this.props.triggerDialogForm(false);

		this.props.reloadCB();
	};

	render() {
		if (!this.state.dataLoaded) {
			return (
				<p
					style={{
						textAlign: "start",
						padding: "10px",
					}}
				>
					{this.props.t("loadingClientData")}
				</p>
			);
		} else {
			return (
				<>
					<Form
						onSubmit={this.onSubmit}
						initialValues={this.state.initValues}
						render={({
							handleSubmit,
							form,
							submitting,
							pristine,
							values,
							handleReset,
						}) => (
							<form
								onSubmit={handleSubmit}
								onReset={handleReset}
								style={{ overflowX: "hidden" }}
								className="py-4 px-3"
								noValidate
							>
								<Row>
									<Col md={12}>
										<div className="d-flex justify-content-between align-items-center mb-4">
											<h3>
												{this.props.t("visaMainData")}
											</h3>
										</div>

										<Row>
											<Col md={3}>
												<TextField
													disabled
													label={this.props.t(
														"visaId"
													)}
													className="read-only mb-3"
													name="License_Visa_Id"
												/>
											</Col>

											<Col md={3}>
												<TextField
													disabled
													label={this.props.t(
														"visaSerial"
													)}
													className="read-only mb-3"
													name="License_Visa_Serial"
												/>
											</Col>

											<Col md={3}>
												<TextField
													disabled
													label={this.props.t(
														"visaNo"
													)}
													className="read-only mb-3"
													name="License_Visa_No"
												/>
											</Col>

											<Col md={3}>
												<TextField
													disabled
													label={this.props.t(
														"visaCreationDate"
													)}
													className="read-only mb-3"
													name="License_Visa_Creation_Date"
													value={
														values?.License_Visa_Creation_Date
															? moment(
																	values?.License_Visa_Creation_Date
															  ).format(
																	"YYYY-MM-DD"
															  )
															: ""
													}
												/>
											</Col>

											<Col md={3}>
												<Field name="License_Investor_Data_Id">
													{(fieldProps) => (
														// <Autocomplete
														// 	label={this.props.t(
														// 		"licenseVisaInvestor"
														// 	)}
														// 	className={`${
														// 		this.props.viewMood
														// 			? "read-only"
														// 			: ""
														// 	}`}
														// 	name="License_Investor_Data_Id"
														// 	options={
														// 		this.state
														// 			.investorsList
														// 	}
														// 	getOptionValue={(
														// 		option
														// 	) => option.value}
														// 	getOptionLabel={(
														// 		option
														// 	) => option.label}
														// />

														<Select
															label={this.props.t(
																"licenseVisaInvestor"
															)}
															className={`${
																this.props
																	.viewMood
																	? "read-only"
																	: ""
															}`}
															data={
																this.state
																	.investorsList
															}
															name="License_Investor_Data_Id"
															onChange={(e) => {
																fieldProps.input.onChange(
																	e.target
																		.value
																);
																this.setState({
																	selectedInvestor:
																		e.target
																			.value,
																});
															}}
															value={
																this.state
																	.selectedInvestor
															}
														></Select>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<Switches
													color="primary"
													name="Visa_Issued"
													data={{
														label: `${this.props.t(
															"visaIssued"
														)}`,
														value: false,
													}}
												/>
											</Col>

											<Col md={3}>
												<TextField
													disabled
													label={this.props.t(
														"visaStatus"
													)}
													className="read-only mb-3"
													name={
														this.props.i18n
															.language === "ar"
															? "License_Visa_Status_Name_AR"
															: "License_Visa_Status_Name_EN"
													}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row>
									<Col md={12}>
										<div className="d-flex justify-content-between align-items-center mb-4">
											<h3>
												{this.props.t("visaHolderData")}
											</h3>
										</div>

										<Row>
											<Col md={3}>
												<TextField
													disabled={
														this.state
															.lockHolderData
													}
													label={this.props.t(
														"holderNameAr"
													)}
													className={`${
														this.state
															.lockHolderData
															? "read-only"
															: ""
													} mb-3`}
													name="Holder_Name_AR"
												/>
											</Col>

											<Col md={3}>
												<TextField
													disabled={
														this.state
															.lockHolderData
													}
													label={this.props.t(
														"holderNameEN"
													)}
													className={`${
														this.state
															.lockHolderData
															? "read-only"
															: ""
													} mb-3`}
													name="Holder_Name_EN"
												/>
											</Col>

											<Col md={3}>
												<Field name="Holder_Nationality_Id">
													{(fieldProps) => (
														<Autocomplete
															disabled={
																this.state
																	.lockHolderData
															}
															label={this.props.t(
																"nationality"
															)}
															className={`${
																this.state
																	.lockHolderData
																	? "read-only"
																	: ""
															}`}
															name="Holder_Nationality_Id"
															options={
																this.state
																	.nationalitiesList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<Field name="Holder_DOB">
													{(fieldProps) => (
														<>
															{values.Holder_DOB && (
																<label
																	style={{
																		fontSize:
																			"0.8rem",
																		color: "#00b1eb",
																		textAlign:
																			"right",
																		display:
																			"block",
																		right: "0.5rem",
																		position:
																			"relative",
																		marginBottom:
																			"5px",
																		marginTop:
																			"-4px",
																	}}
																>
																	{this.props.t(
																		"holderDOB"
																	)}
																</label>
															)}
															<DateBox
																disabled={
																	this.state
																		.lockHolderData
																}
																acceptCustomValue={
																	false
																}
																defaultValue={
																	null
																}
																dateSerializationFormat="yyyy-MM-dd"
																displayFormat="yyyy-MM-dd"
																onValueChanged={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.value
																	);
																}}
																style={{
																	borderRadius:
																		"50px",
																}}
																type="date"
																placeholder={this.props.t(
																	"holderDOB"
																)}
																className={`${
																	this.state
																		.lockHolderData
																		? "read-only"
																		: ""
																} ${
																	!values.Holder_DOB
																		? "mt-3"
																		: ""
																}`}
																value={
																	values.Holder_DOB
																}
															/>
														</>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<Field name="Holder_Home_Country_Id">
													{(fieldProps) => (
														<Autocomplete
															disabled={
																this.state
																	.lockHolderData
															}
															label={this.props.t(
																"homeCountry"
															)}
															className={`${
																this.state
																	.lockHolderData
																	? "read-only"
																	: ""
															}`}
															name="Holder_Home_Country_Id"
															options={
																this.state
																	.countriesList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<TextField
													disabled={
														this.state
															.lockHolderData
													}
													label={this.props.t(
														"holderHomeCity"
													)}
													className={`${
														this.state
															.lockHolderData
															? "read-only"
															: ""
													} mb-3`}
													name="Holder_Home_City_Name"
												/>
											</Col>

											<Col md={6}>
												<TextField
													disabled={
														this.state
															.lockHolderData
													}
													label={this.props.t(
														"holderHomeAddress"
													)}
													className={`${
														this.state
															.lockHolderData
															? "read-only"
															: ""
													} mb-3`}
													name="Holder_Home_Address"
												/>
											</Col>

											<Col md={3}>
												<Field name="Holder_UAE_Emirate_Id">
													{(fieldProps) => (
														<Autocomplete
															disabled={
																this.state
																	.lockHolderData
															}
															label={this.props.t(
																"emirate"
															)}
															className={`${
																this.state
																	.lockHolderData
																	? "read-only"
																	: ""
															}`}
															name="Holder_UAE_Emirate_Id"
															options={
																this.state
																	.emiratesList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<TextField
													disabled={
														this.state
															.lockHolderData
													}
													label={this.props.t(
														"holderCity"
													)}
													className={`${
														this.state
															.lockHolderData
															? "read-only"
															: ""
													} mb-3`}
													name="Holder_UAE_City"
												/>
											</Col>

											<Col md={6}>
												<TextField
													disabled={
														this.state
															.lockHolderData
													}
													label={this.props.t(
														"holderAddress"
													)}
													className={`${
														this.state
															.lockHolderData
															? "read-only"
															: ""
													} mb-3`}
													name="Holder_UAE_Address"
												/>
											</Col>

											<Col md={3}>
												<TextField
													disabled={
														this.state
															.lockHolderData
													}
													label={this.props.t(
														"passportNumber"
													)}
													className={`${
														this.state
															.lockHolderData
															? "read-only"
															: ""
													} mb-3`}
													name="Holder_Passport_Number"
												/>
											</Col>

											<Col md={3}>
												<Field name="Holder_Passport_Country_Id">
													{(fieldProps) => (
														<Autocomplete
															disabled={
																this.state
																	.lockHolderData
															}
															label={this.props.t(
																"passportCountry"
															)}
															className={`${
																this.state
																	.lockHolderData
																	? "read-only"
																	: ""
															}`}
															name="Holder_Passport_Country_Id"
															options={
																this.state
																	.countriesList
															}
															getOptionValue={(
																option
															) => option.value}
															getOptionLabel={(
																option
															) => option.label}
														/>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<Field name="Holder_Passport_Issue_Date">
													{(fieldProps) => (
														<>
															{values.Holder_Passport_Issue_Date && (
																<label
																	style={{
																		fontSize:
																			"0.8rem",
																		color: "#00b1eb",
																		textAlign:
																			"right",
																		display:
																			"block",
																		right: "0.5rem",
																		position:
																			"relative",
																		marginBottom:
																			"5px",
																		marginTop:
																			"-4px",
																	}}
																>
																	{this.props.t(
																		"passportIssueDate"
																	)}
																</label>
															)}

															<DateBox
																disabled={
																	this.state
																		.lockHolderData
																}
																acceptCustomValue={
																	false
																}
																defaultValue={
																	null
																}
																dateSerializationFormat="yyyy-MM-dd"
																displayFormat="yyyy-MM-dd"
																onValueChanged={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.value
																	);
																}}
																style={{
																	borderRadius:
																		"50px",
																}}
																type="date"
																placeholder={this.props.t(
																	"passportIssueDate"
																)}
																className={`${
																	this.state
																		.lockHolderData
																		? "read-only"
																		: ""
																} ${
																	!values.Holder_Passport_Issue_Date
																		? "mt-3"
																		: ""
																}`}
																value={
																	values.Holder_Passport_Issue_Date
																}
															/>
														</>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<Field name="Holder_Passport_Expire_Date">
													{(fieldProps) => (
														<>
															{values.Holder_Passport_Expire_Date && (
																<label
																	style={{
																		fontSize:
																			"0.8rem",
																		color: "#00b1eb",
																		textAlign:
																			"right",
																		display:
																			"block",
																		right: "0.5rem",
																		position:
																			"relative",
																		marginBottom:
																			"5px",
																		marginTop:
																			"-4px",
																	}}
																>
																	{this.props.t(
																		"passportExpireDate"
																	)}
																</label>
															)}
															<DateBox
																disabled={
																	this.state
																		.lockHolderData
																}
																acceptCustomValue={
																	false
																}
																defaultValue={
																	null
																}
																dateSerializationFormat="yyyy-MM-dd"
																displayFormat="yyyy-MM-dd"
																onValueChanged={(
																	e
																) => {
																	fieldProps.input.onChange(
																		e.value
																	);
																}}
																style={{
																	borderRadius:
																		"50px",
																}}
																type="date"
																placeholder={this.props.t(
																	"passportExpireDate"
																)}
																className={`${
																	this.state
																		.lockHolderData
																		? "read-only"
																		: ""
																} ${
																	!values.Holder_Passport_Expire_Date
																		? "mt-3"
																		: ""
																}`}
																value={
																	values.Holder_Passport_Expire_Date
																}
															/>
														</>
													)}
												</Field>
											</Col>

											<Col md={3}>
												<TextField
													disabled={
														this.state
															.lockHolderData
													}
													label={this.props.t(
														"mobileNumber"
													)}
													className={`${
														this.state
															.lockHolderData
															? "read-only"
															: ""
													} mb-3`}
													name="Holder_Mobile"
												/>
											</Col>

											<Col md={3}>
												<TextField
													disabled={
														this.state
															.lockHolderData
													}
													label={this.props.t(
														"email"
													)}
													className={`${
														this.state
															.lockHolderData
															? "read-only"
															: ""
													} mb-3`}
													name="Holder_Mail"
												/>
											</Col>

											<Col
												md={6}
												className="d-flex justify-content-between align-items-center"
											>
												{!this.state.lockHolderData && (
													<ButtonSecondary
														color="primary"
														variant="contained"
														endIcon={
															<CloudUploadIcon />
														}
														style={{
															justifyContent:
																"space-between",
															minWidth: "200px",
														}}
													>
														{this.props.t(
															"passportCopy"
														)}
														<input
															style={{
																position:
																	"absolute",
																width: "100%",
																height: "100%",
																left: 0,
																right: 0,
																top: 0,
																bottom: 0,
																zIndex: 1,
																opacity: 0,
																fontSize: 0,
																cursor: "pointer",
															}}
															type="file"
															onChange={(e) => {
																this.selectFiles(
																	e,
																	"Holder_Passport_File"
																);
															}}
															value=""
														/>
													</ButtonSecondary>
												)}
												{this.state
													.Holder_Passport_File &&
													this.state
														.Holder_Passport_File
														.length > 0 && (
														<div
															style={{
																flexGrow: 1,
																marginInline:
																	"6px",
															}}
														>
															<FilesUpload
																view={true}
																attachments={[
																	{
																		Files_Data:
																			[
																				this
																					.state
																					.Holder_Passport_File[0],
																			],
																	},
																]}
																deleteCB={() => {
																	this.setState(
																		{
																			Holder_Passport_File:
																				[],
																		}
																	);
																}}
																downloadCB={
																	downloadAttachment
																}
															/>
														</div>
													)}
											</Col>
										</Row>
									</Col>
								</Row>

								{!!values.Visa_Issued && (
									<>
										<Row>
											<Col md={12}>
												<div className="d-flex justify-content-between align-items-center mb-4">
													<h3>
														{this.props.t(
															"issuedVisaDetails"
														)}
													</h3>
												</div>

												<Row>
													<Col md={3}>
														<TextField
															label={this.props.t(
																"holderVisaUnifedNumber"
															)}
															className={`${
																this.state
																	.lockHolderData
																	? "read-only"
																	: ""
															} mb-3`}
															name="Holder_Visa_Unified_Number"
														/>
													</Col>

													<Col md={3}>
														<Field name="License_Visa_Issue_Date">
															{(fieldProps) => (
																<>
																	{values.License_Visa_Issue_Date && (
																		<label
																			style={{
																				fontSize:
																					"0.8rem",
																				color: "#00b1eb",
																				textAlign:
																					"right",
																				display:
																					"block",
																				right: "0.5rem",
																				position:
																					"relative",
																				marginBottom:
																					"5px",
																				marginTop:
																					"-4px",
																			}}
																		>
																			{this.props.t(
																				"licenseVisaIssueDate"
																			)}
																		</label>
																	)}
																	<DateBox
																		acceptCustomValue={
																			false
																		}
																		defaultValue={
																			null
																		}
																		dateSerializationFormat="yyyy-MM-dd"
																		displayFormat="yyyy-MM-dd"
																		onValueChanged={(
																			e
																		) => {
																			fieldProps.input.onChange(
																				e.value
																			);
																		}}
																		style={{
																			borderRadius:
																				"50px",
																		}}
																		type="date"
																		placeholder={this.props.t(
																			"licenseVisaIssueDate"
																		)}
																		className={`${
																			this
																				.state
																				.lockHolderData
																				? "read-only"
																				: ""
																		} ${
																			!values.License_Visa_Issue_Date
																				? "mt-3"
																				: ""
																		}`}
																		value={
																			values.License_Visa_Issue_Date
																		}
																	/>
																</>
															)}
														</Field>
													</Col>

													<Col md={3}>
														<Field name="License_Visa_Expire_Date">
															{(fieldProps) => (
																<>
																	{values.License_Visa_Expire_Date && (
																		<label
																			style={{
																				fontSize:
																					"0.8rem",
																				color: "#00b1eb",
																				textAlign:
																					"right",
																				display:
																					"block",
																				right: "0.5rem",
																				position:
																					"relative",
																				marginBottom:
																					"5px",
																				marginTop:
																					"-4px",
																			}}
																		>
																			{this.props.t(
																				"licenseVisaExpireDate"
																			)}
																		</label>
																	)}
																	<DateBox
																		acceptCustomValue={
																			false
																		}
																		defaultValue={
																			null
																		}
																		dateSerializationFormat="yyyy-MM-dd"
																		displayFormat="yyyy-MM-dd"
																		onValueChanged={(
																			e
																		) => {
																			fieldProps.input.onChange(
																				e.value
																			);
																		}}
																		style={{
																			borderRadius:
																				"50px",
																		}}
																		type="date"
																		placeholder={this.props.t(
																			"licenseVisaExpireDate"
																		)}
																		className={`${
																			this
																				.state
																				.lockHolderData
																				? "read-only"
																				: ""
																		} ${
																			!values.License_Visa_Expire_Date
																				? "mt-3"
																				: ""
																		}`}
																		value={
																			values.License_Visa_Expire_Date
																		}
																	/>
																</>
															)}
														</Field>
													</Col>

													{this.props
														.disableAllFields && (
														<Col md={3}>
															<TextField
																disabled
																label={this.props.t(
																	"licenseVisaCancelDate"
																)}
																className="read-only mb-3"
																name="License_Visa_Cancel_Date"
																value={
																	values?.License_Visa_Cancel_Date
																		? moment(
																				values?.License_Visa_Cancel_Date
																		  ).format(
																				"YYYY-MM-DD"
																		  )
																		: ""
																}
															/>
														</Col>
													)}

													<Col
														md={6}
														className="d-flex justify-content-between align-items-center"
													>
														<ButtonSecondary
															color="primary"
															variant="contained"
															endIcon={
																<CloudUploadIcon />
															}
															style={{
																justifyContent:
																	"space-between",
																minWidth:
																	"200px",
															}}
														>
															{this.props.t(
																"issuedVisaCopy"
															)}
															<input
																style={{
																	position:
																		"absolute",
																	width: "100%",
																	height: "100%",
																	left: 0,
																	right: 0,
																	top: 0,
																	bottom: 0,
																	zIndex: 1,
																	opacity: 0,
																	fontSize: 0,
																	cursor: "pointer",
																}}
																type="file"
																onChange={(
																	e
																) => {
																	this.selectFiles(
																		e,
																		"Holder_Visa_File"
																	);
																}}
																value=""
															/>
														</ButtonSecondary>

														{this.state
															.Holder_Visa_File &&
															this.state
																.Holder_Visa_File
																.length > 0 && (
																<div
																	style={{
																		flexGrow: 1,
																		marginInline:
																			"6px",
																	}}
																>
																	<FilesUpload
																		view={
																			true
																		}
																		attachments={[
																			{
																				Files_Data:
																					[
																						this
																							.state
																							.Holder_Visa_File[0],
																					],
																			},
																		]}
																		deleteCB={() => {
																			this.setState(
																				{
																					Holder_Visa_File:
																						[],
																				}
																			);
																		}}
																		downloadCB={
																			downloadAttachment
																		}
																	/>
																</div>
															)}
													</Col>
												</Row>
											</Col>
										</Row>

										<Row className="mt-4">
											<Col md={12}>
												<div className="d-flex justify-content-between align-items-center mb-4">
													<h3>
														{this.props.t(
															"issuedIdDetails"
														)}
													</h3>
												</div>

												<Row>
													<Col md={6}>
														<Field name="Holder_EmiratesID_Number">
															{(fieldProps) => (
																<PatternFormat
																	format="784-####-#######-#"
																	mask="_"
																	customInput={
																		TextField
																	}
																	label={this.props.t(
																		"holderIdNumber"
																	)}
																	className={`${
																		this
																			.state
																			.lockHolderData
																			? "read-only"
																			: ""
																	} mb-3`}
																	name="Holder_EmiratesID_Number"
																	onValueChange={(
																		values,
																		source
																	) => {
																		fieldProps.input.onChange(
																			values.formattedValue
																		);
																	}}
																	value={
																		values.Holder_EmiratesID_Number
																	}
																	style={{
																		direction:
																			"ltr",
																	}}
																/>
															)}
														</Field>
														{/* <TextField
															disabled={
																this.state
																	.lockHolderData
															}
															label={this.props.t(
																"holderIdNumber"
															)}
															className={`${
																this.state
																	.lockHolderData
																	? "read-only"
																	: ""
															} mb-3`}
															name="Holder_EmiratesID_Number"
														/> */}
													</Col>

													<Col md={3}>
														<Field name="Holder_EmiratesID_Issue_Date">
															{(fieldProps) => (
																<>
																	{values.Holder_EmiratesID_Issue_Date && (
																		<label
																			style={{
																				fontSize:
																					"0.8rem",
																				color: "#00b1eb",
																				textAlign:
																					"right",
																				display:
																					"block",
																				right: "0.5rem",
																				position:
																					"relative",
																				marginBottom:
																					"5px",
																				marginTop:
																					"-4px",
																			}}
																		>
																			{this.props.t(
																				"iDIssueDate"
																			)}
																		</label>
																	)}
																	<DateBox
																		acceptCustomValue={
																			false
																		}
																		defaultValue={
																			null
																		}
																		dateSerializationFormat="yyyy-MM-dd"
																		displayFormat="yyyy-MM-dd"
																		onValueChanged={(
																			e
																		) => {
																			fieldProps.input.onChange(
																				e.value
																			);
																		}}
																		style={{
																			borderRadius:
																				"50px",
																		}}
																		type="date"
																		placeholder={this.props.t(
																			"iDIssueDate"
																		)}
																		className={`${
																			this
																				.state
																				.lockHolderData
																				? "read-only"
																				: ""
																		} ${
																			!values.Holder_EmiratesID_Issue_Date
																				? "mt-3"
																				: ""
																		}`}
																		value={
																			values.Holder_EmiratesID_Issue_Date
																		}
																	/>
																</>
															)}
														</Field>
													</Col>

													<Col md={3}>
														<Field name="Holder_EmiratesID_Expire_Date">
															{(fieldProps) => (
																<>
																	{values.Holder_EmiratesID_Expire_Date && (
																		<label
																			style={{
																				fontSize:
																					"0.8rem",
																				color: "#00b1eb",
																				textAlign:
																					"right",
																				display:
																					"block",
																				right: "0.5rem",
																				position:
																					"relative",
																				marginBottom:
																					"5px",
																				marginTop:
																					"-4px",
																			}}
																		>
																			{this.props.t(
																				"iDExpireDate"
																			)}
																		</label>
																	)}
																	<DateBox
																		acceptCustomValue={
																			false
																		}
																		defaultValue={
																			null
																		}
																		dateSerializationFormat="yyyy-MM-dd"
																		displayFormat="yyyy-MM-dd"
																		onValueChanged={(
																			e
																		) => {
																			fieldProps.input.onChange(
																				e.value
																			);
																		}}
																		style={{
																			borderRadius:
																				"50px",
																		}}
																		type="date"
																		placeholder={this.props.t(
																			"iDExpireDate"
																		)}
																		className={`${
																			this
																				.state
																				.lockHolderData
																				? "read-only"
																				: ""
																		} ${
																			!values.Holder_EmiratesID_Expire_Date
																				? "mt-3"
																				: ""
																		}`}
																		value={
																			values.Holder_EmiratesID_Expire_Date
																		}
																	/>
																</>
															)}
														</Field>
													</Col>

													<Col
														md={6}
														className="d-flex justify-content-between align-items-center"
													>
														<div>
															<ButtonSecondary
																color="primary"
																variant="contained"
																endIcon={
																	<CloudUploadIcon />
																}
																style={{
																	justifyContent:
																		"space-between",
																	minWidth:
																		"200px",
																}}
															>
																{this.props.t(
																	"idFrontCopy"
																)}
																<input
																	style={{
																		position:
																			"absolute",
																		width: "100%",
																		height: "100%",
																		left: 0,
																		right: 0,
																		top: 0,
																		bottom: 0,
																		zIndex: 1,
																		opacity: 0,
																		fontSize: 0,
																		cursor: "pointer",
																	}}
																	type="file"
																	onChange={(
																		e
																	) => {
																		this.selectFiles(
																			e,
																			"Holder_EmiratesID_Front_File"
																		);
																	}}
																	value=""
																/>
															</ButtonSecondary>
															{this.state
																.idFrontErr && (
																<small className="d-block mt-2 text-danger font-weight-bold">
																	{this.props.t(
																		"required"
																	)}
																</small>
															)}
														</div>

														{this.state
															.Holder_EmiratesID_Front_File &&
															this.state
																.Holder_EmiratesID_Front_File
																.length > 0 && (
																<div
																	style={{
																		flexGrow: 1,
																		marginInline:
																			"6px",
																	}}
																>
																	<FilesUpload
																		view={
																			true
																		}
																		attachments={[
																			{
																				Files_Data:
																					[
																						this
																							.state
																							.Holder_EmiratesID_Front_File[0],
																					],
																			},
																		]}
																		deleteCB={() => {
																			this.setState(
																				{
																					Holder_EmiratesID_Front_File:
																						[],
																				}
																			);
																		}}
																		downloadCB={
																			downloadAttachment
																		}
																	/>
																</div>
															)}
													</Col>

													<Col
														md={6}
														className="d-flex justify-content-between align-items-center"
													>
														<div>
															<ButtonSecondary
																color="primary"
																variant="contained"
																endIcon={
																	<CloudUploadIcon />
																}
																style={{
																	justifyContent:
																		"space-between",
																	minWidth:
																		"200px",
																}}
															>
																{this.props.t(
																	"idBackCopy"
																)}
																<input
																	style={{
																		position:
																			"absolute",
																		width: "100%",
																		height: "100%",
																		left: 0,
																		right: 0,
																		top: 0,
																		bottom: 0,
																		zIndex: 1,
																		opacity: 0,
																		fontSize: 0,
																		cursor: "pointer",
																	}}
																	type="file"
																	onChange={(
																		e
																	) => {
																		this.selectFiles(
																			e,
																			"Holder_EmiratesID_Back_File"
																		);
																	}}
																	value=""
																/>
															</ButtonSecondary>
															{this.state
																.idBackErr && (
																<small className="d-block mt-2 text-danger font-weight-bold">
																	{this.props.t(
																		"required"
																	)}
																</small>
															)}
														</div>

														{this.state
															.Holder_EmiratesID_Back_File &&
															this.state
																.Holder_EmiratesID_Back_File
																.length > 0 && (
																<div
																	style={{
																		flexGrow: 1,
																		marginInline:
																			"6px",
																	}}
																>
																	<FilesUpload
																		view={
																			true
																		}
																		attachments={[
																			{
																				Files_Data:
																					[
																						this
																							.state
																							.Holder_EmiratesID_Back_File[0],
																					],
																			},
																		]}
																		deleteCB={() => {
																			this.setState(
																				{
																					Holder_EmiratesID_Back_File:
																						[],
																				}
																			);
																		}}
																		downloadCB={
																			downloadAttachment
																		}
																	/>
																</div>
															)}
													</Col>
												</Row>
											</Col>
										</Row>
									</>
								)}

								<div className="my-5 justify-content-between d-flex">
									{!!this.props.showSave && (
										<ButtonPrimary
											color="primary"
											variant="contained"
											// disabled={submitting}
											type="submit"
											style={{ marginInlineEnd: "auto" }}
										>
											{this.props.t("save")}
										</ButtonPrimary>
									)}

									{!!this.props.canDelete && (
										<ButtonDanger
											color="primary"
											variant="contained"
											onClick={(e) => {
												this.handleAction(
													delLicenseVisa
												);
											}}
										>
											{this.props.t("deleteVisa")}
										</ButtonDanger>
									)}

									{!!this.props.canCancel && (
										<ButtonSecondary
											color="primary"
											variant="contained"
											onClick={(e) => {
												this.handleAction(
													cancelLicenseVisa
												);
											}}
										>
											{this.props.t("cancelVisa")}
										</ButtonSecondary>
									)}

									{!!this.props.canReactivate && (
										<ButtonSecondary
											color="primary"
											variant="contained"
											onClick={(e) => {
												this.handleAction(
													reactivateLicenseVisa
												);
											}}
										>
											{this.props.t("reactivateVisa")}
										</ButtonSecondary>
									)}
								</div>
							</form>
						)}
					/>
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
})(translationHook(LicenseVisaForm));
