import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";

import { translationHook } from "../../translationHook";
import {
	triggerNotification,
	triggerDialogForm,
	setLoading,
} from "../../../actions";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import { downloadRequestAttachment } from "../../../apis/requests/info/downloadRequestAttachment";
import { getRequestInputChangesList } from "../../../apis/requests/getRequestInputChangesList";
import InputChangesList from "./InputChangesList";

import moment from "moment";
import DialogForm from "../../UI/DialogForm";
import RequestData from "./RequestData";

class RequestInfo extends Component {
	state = {
		showDialog: false,
		dialogData: null,
	};

	onServiceInfoSubmit = async (values, form) => {
		return null;
	};

	renderChangesList = (...params) => {
		return (
			<div className="p-3">
				<InputChangesList
					changesList={params[0]}
					isInputAttachment={params[1]}
				/>
			</div>
		);
	};

	handleGetRequestInputChangesList = async (
		Request_Id,
		Department_Service_Detail_Id
	) => {
		let changesList;
		this.props.setLoading(true, "dark");

		await getRequestInputChangesList(
			this.props.currentUser.Data.access_token,
			{
				Request_Id,
				Department_Service_Detail_Id,
			}
		)
			.then((res) => {
				changesList = res.data.Data;
			})
			.catch((error) => {
				this.props.setLoading(false);
				this.props.triggerNotification(true, error.response);
				return;
			});

		this.props.setLoading(false);
		return changesList;
	};

	render() {
		let { info, data, attachments, reuestSubmissionInfo, requestForms } =
				this.props,
			requestDataGroups = {},
			dataGroups = {};

		if (!info) {
			return <h6>{this.props.t("loadingClientData")}</h6>;
		}

		if (data) {
			dataGroups = data.reduce((result, current) => {
				let prevGroupItems =
					result[current.Department_Service_Detail_Form_No]?.items;

				requestForms.forEach((group) => {
					if (
						current.Department_Service_Detail_Form_Id ===
						group.Department_Service_Detail_Form_Id
					) {
						result[group.Department_Service_Detail_Form_No] = {
							groupId: group.Department_Service_Detail_Form_Id,
							groupTitle: {
								EN: group.Department_Service_Detail_Form_Title_EN,
								AR: group.Department_Service_Detail_Form_Title_AR,
							},
							items: prevGroupItems
								? [...prevGroupItems, current]
								: [current],
						};
					}
				});

				return result;
			}, {});
		}

		if (attachments) {
			requestDataGroups = attachments.reduce((result, current) => {
				let prevGroupAttachments =
					result[current.Department_Service_Detail_Form_No]
						?.attachments;

				requestForms.forEach((group) => {
					if (
						current.Department_Service_Detail_Form_Id ===
						group.Department_Service_Detail_Form_Id
					) {
						if (result[group.Department_Service_Detail_Form_No]) {
							result[group.Department_Service_Detail_Form_No][
								"attachments"
							] = prevGroupAttachments
								? [...prevGroupAttachments, current]
								: [current];
						} else {
							result[group.Department_Service_Detail_Form_No] = {
								groupId:
									group.Department_Service_Detail_Form_Id,
								groupTitle: {
									EN: group.Department_Service_Detail_Form_Title_EN,
									AR: group.Department_Service_Detail_Form_Title_AR,
								},
								attachments: [current],
							};
						}
					}
				});

				return result;
			}, dataGroups);
		}

		return (
			<>
				{this.props.children && this.props.children}
				<Form
					onSubmit={this.onServiceInfoSubmit}
					initialValues={{
						requestNo: info.Request_No,
						requestDate: info.Request_Date
							? info.Request_Date.slice(0, 10)
							: "",
						serviceName:
							this.props.i18n.language === "ar"
								? info.Service_Name_AR
								: info.Service_Name_EN,
						serviceVersion: info.Department_Service_Version_Name,
						departmentName:
							this.props.i18n.language === "ar"
								? info.Department_Name_AR
								: info.Department_Name_EN,
						requestApplicationTime: info.Ins_Time
							? moment(info.Ins_Time).format(
									"h:mm:ss, YYYY-MM-DD"
							  )
							: "",
						requestSubmissionNotes:
							reuestSubmissionInfo?.requestSubmissionNotes,
					}}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<>
							<form
								className="row"
								onSubmit={handleSubmit}
								noValidate
							>
								<Col xs={12}>
									<h5 className="w-25 my-4">
										{this.props.t("requestInfo")}
									</h5>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled
										label={this.props.t("serviceName")}
										className="read-only mb-4"
										name="serviceName"
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled
										label={this.props.t("departmentName")}
										className="read-only mb-4"
										name="departmentName"
									/>
								</Col>
								<Col xs={12} md={3}>
									<TextField
										disabled
										label={this.props.t("version")}
										className="read-only mb-4"
										name="serviceVersion"
									/>
								</Col>
								<Col xs={12} md={3}>
									<TextField
										disabled
										label={this.props.t("requestNo")}
										className="read-only mb-4"
										name="requestNo"
									/>
								</Col>
								<Col xs={12} md={3}>
									<TextField
										disabled
										label={this.props.t("requestDate")}
										className="read-only mb-4"
										name="requestDate"
									/>
								</Col>
								<Col xs={12} md={3}>
									<TextField
										disabled
										label={this.props.t(
											"requestApplicationTime"
										)}
										className="read-only mb-4"
										name="requestApplicationTime"
									/>
								</Col>

								{reuestSubmissionInfo?.requestSubmissionNotes && (
									<Col xs={12} md={6} className="mt-2">
										<TextField
											disabled
											label={this.props.t(
												"requestSubmissionNotes"
											)}
											className="read-only mb-4"
											name="requestSubmissionNotes"
											rows={8}
											multiline
										/>
									</Col>
								)}

								{reuestSubmissionInfo?.requestSubmissionFiles && (
									<Col xs={12} md={6}>
										<label className="static-label">
											{this.props.t(
												"requestSubmissionFiles"
											)}
										</label>
										<FilesUpload
											downloadCB={
												downloadRequestAttachment
											}
											view={true}
											attachments={[
												{
													Files_Data:
														reuestSubmissionInfo?.requestSubmissionFiles,
												},
											]}
										/>
									</Col>
								)}

								<Col xs={12}>
									<h5 className="w-25 my-4">
										{this.props.t("requestDetails")}
									</h5>
								</Col>

								{data && (
									<RequestData
										data={requestDataGroups}
										requestInfoButtonCallback={
											this.props.requestInfoButtonCallback
										}
										requestId={this.props.info.Request_Id}
									/>
								)}
							</form>

							{/* <Row className={style.altWrap}>
								<Col xs={12} md={12}>
									<h5 className="my-4">
										{this.props.t("requestAttachments")}
									</h5>

									{attachments.map((attach) => {
										return (
											<Accordion
												className={`${style.accordionWrap} py-4 mb-3`}
												key={`attachment_${attach.Attachment_No}`}
											>
												<AccordionSummary
													expandIcon={
														<ExpandMoreIcon />
													}
													aria-controls={`attachment_${attach.Attachment_No}`}
													id={`attachment_${attach.Attachment_No}`}
												>
													{attach?.Attachment_Title_AR && (
														<label
															className="ml-2"
															style={{
																fontSize:
																	"14px",
																color: "#333",
															}}
														>
															{this.props.i18n
																.language ===
															"ar"
																? attach.Attachment_Title_AR
																: attach.Attachment_Title_AR}
														</label>
													)}
												</AccordionSummary>

												<AccordionDetails
													className={
														style.accordionDetail
													}
												>
													<FilesUpload
														hideAttachmentListTitle={
															true
														}
														requestId={
															this.props.info
																.Request_Id
														}
														downloadCB={
															downloadRequestAttachment
														}
														view={true}
														attachments={
															[{ ...attach }]
															// attach.Files_Data
														}
														chunk={true}
														labelAction={
															this
																.handleGetRequestInputChangesList
														}
														labelActionCB={(
															data
														) => {
															this.props.triggerDialogForm(
																true,
																{
																	title: this.props.t(
																		"viewChangesList"
																	),
																	renderForm:
																		this
																			.renderChangesList,
																	params: [
																		data,
																		true,
																	],
																}
															);
														}}
													/>
												</AccordionDetails>
											</Accordion>
										);
									})}
								</Col>
							</Row> */}
						</>
					)}
				/>
				<DialogForm
					custom={true}
					customDialogForm={{
						Open: this.state.showDialog || false,
						Data: this.state.dialogData,
						onClose: () => this.setState({ showDialog: false }),
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	triggerDialogForm,
	setLoading,
})(translationHook(RequestInfo));
