import apiConfig from "../../apiConfig";

export const deleteActivityCat = async (at, catId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/ACTIVITIES/Del_License_Activity_Category",
		{
			License_Activity_Category_Id: catId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
