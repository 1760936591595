import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary } from "../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Switches } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";

import { Toolbar, Item } from "devextreme-react/html-editor";
import Wysiwyg from "../../UI/Wysiwyg";

import ContentEditable from "react-contenteditable";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const toolbaroptions = {
	multiline: false,
	items: [
		"undo",
		"redo",
		"separator",
		"bold",
		"italic",
		"underline",
		"strike",
		"separator",
		"link",
		{
			formatName: "header",
			formatValues: [false, 3, 4, 5],
		},
		{
			formatName: "size",
			formatValues: ["11px", "14px", "16px", "22px"],
		},
		"orderedList",
		"bulletList",
		"alignLeft",
		"alignCenter",
		"alignRight",
	],
};

class NotificationUpdateForm extends Component {
	constructor(props) {
		super(props);
		// create a ref to store the textInput DOM element
		this.notificationNameEN = React.createRef();
		this.notificationNameAR = React.createRef();
		this.smsMsgEn = React.createRef();
		this.smsMsgAr = React.createRef();
	}

	state = {
		notificationFields: [],
		initValues: {
			NameEN: "",
		},
		focusedElement: null,
		focusedPosition: null,
		focusedLang: null,
		activeFields: false,
		fields: {
			notificationNameEN: "",
			notificationNameAR: "",
			smsMsgEn: "",
			smsMsgAr: "",
			mailReplyTo: "",
			mailCC: "",
			mailBCC: "",
			webMsgAR: "",
			webMsgEN: "",
			mobileMsgAR: "",
			mobileMsgEN: "",
			mailTitleAR: "",
			mailTitleEN: "",
			mobiletitleAR: "",
			mobileTitleEN: "",
			webtitleAR: "",
			webTitleEN: "",
			mailbodyAR: "",
			mailbodyEN: "",
			smsStatus: false,
			mailStatus: false,
			webStatus: false,
			mobileStatus: false,
		},
	};
	componentDidMount() {
		this.getInfo();
	}
	getInfo = async () => {
		const res = await apiConfig.post(
			"/API/SYSTEM/NOTIFICATIONS/GET_NOTIFICATION_INFO",
			{
				Notification_Id: this.props.notificationId,
			},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		const Fields = await apiConfig.post(
			"/API/SYSTEM/NOTIFICATIONS/GET_NOTIFICATION_FIELDS_LIST",
			{
				Notification_Id: this.props.notificationId,
			},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		this.setState({
			notificationFields: Fields.data.Data,
		});

		this.setState({
			fields: {
				notificationNameEN: res.data.Data.Notification_Name_EN,
				notificationNameAR: res.data.Data.Notification_Name_AR,
				smsMsgEn: res.data.Data.SMS_MSG_EN
					? res.data.Data.SMS_MSG_EN
					: "",
				smsMsgAr: res.data.Data.SMS_MSG_AR
					? res.data.Data.SMS_MSG_AR
					: "",
				mailReplyTo: res.data.Data.Mail_Reply_To
					? res.data.Data.Mail_Reply_To
					: "",
				mailCC: res.data.Data.Mail_To_CC
					? res.data.Data.Mail_To_CC
					: "",
				mailBCC: res.data.Data.Mail_To_BCC
					? res.data.Data.Mail_To_BCC
					: "",
				webMsgAR: res.data.Data.Web_Body_MSG_AR
					? res.data.Data.Web_Body_MSG_AR
					: "",
				webMsgEN: res.data.Data.Web_Body_MSG_EN
					? res.data.Data.Web_Body_MSG_EN
					: "",
				mobileMsgAR: res.data.Data.Mobile_Body_MSG_AR
					? res.data.Data.Mobile_Body_MSG_AR
					: "",
				mobileMsgEN: res.data.Data.Mobile_Body_MSG_EN
					? res.data.Data.Mobile_Body_MSG_EN
					: "",
				mailTitleAR: res.data.Data.Mail_Title_MSG_AR
					? res.data.Data.Mail_Title_MSG_AR
					: "",
				mailTitleEN: res.data.Data.Mail_Title_MSG_EN
					? res.data.Data.Mail_Title_MSG_EN
					: "",
				mobiletitleAR: res.data.Data.Mobile_Title_MSG_AR
					? res.data.Data.Mobile_Title_MSG_AR
					: "",
				mobileTitleEN: res.data.Data.Mobile_Title_MSG_EN
					? res.data.Data.Mobile_Title_MSG_EN
					: "",
				webtitleAR: res.data.Data.Web_Title_MSG_AR
					? res.data.Data.Web_Title_MSG_AR
					: "",
				webTitleEN: res.data.Data.Web_Title_MSG_EN
					? res.data.Data.Web_Title_MSG_EN
					: "",
				mailbodyEN: res.data.Data.Mail_Body_MSG_EN
					? res.data.Data.Mail_Body_MSG_EN
					: "",
				mailbodyAR: res.data.Data.Mail_Body_MSG_AR
					? res.data.Data.Mail_Body_MSG_AR
					: "",
				mailStatus: res.data.Data.Enable_Mail === 1 ? true : false,
				smsStatus: res.data.Data.Enable_SMS === 1 ? true : false,
				webStatus: res.data.Data.Enable_Web === 1 ? true : false,
				mobileStatus: res.data.Data.Enable_Mobile === 1 ? true : false,
			},
			activeFields: true,
		});
	};

	// submit Form
	onSectionSubmit = async (values, form) => {
		console.log(values);
		let at = this.props.currentUser.Data.access_token,
			url = "/API/SYSTEM/NOTIFICATIONS/UPD_NOTIFICATION",
			bodyDate = {
				notification_Id: this.props.notificationId,
				Notification_Name_EN: values.notificationNameEN,
				Notification_Name_AR: values.notificationNameAR,
				Enable_SMS: values.smsStatus === true ? 1 : 0,
				Enable_Mail: values.mailStatus === true ? 1 : 0,
				Enable_Web: values.webStatus === true ? 1 : 0,
				Enable_Mobile: values.mobileStatus === true ? 1 : 0,
				mail_Reply_To: values.mailReplyTo,
				mail_To_CC: values.mailCC,
				mail_To_BCC: values.mailBCC,
				SMS_MSG_AR: values.smsMsgAr,
				SMS_MSG_EN: values.smsMsgEn,
				mail_Title_MSG_AR: values.mailTitleAR,
				mail_Title_MSG_EN: values.mailTitleEN,
				mail_Body_MSG_AR: values.mailbodyAR,
				mail_Body_MSG_EN: values.mailbodyEN,
				web_Title_MSG_AR: values.webtitleAR,
				web_Title_MSG_EN: values.webTitleEN,
				web_Body_MSG_AR: values.webMsgAR,
				web_Body_MSG_EN: values.webMsgEN,
				mobile_Title_MSG_AR: values.mobiletitleAR,
				mobile_Title_MSG_EN: values.mobileTitleEN,
				mobile_Body_MSG_AR: values.mobileMsgAR,
				mobile_Body_MSG_EN: values.mobileMsgEN,
			};

		this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

		const response = await apiConfig
			.post(url, bodyDate, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.then((res) => res)
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status) {
			form.restart();
			this.getInfo();
		}
		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false);
	};
	validate = (values) => {
		const errors = {};

		// if (!values.NameEN) {
		//     errors.NameEN = this.props.t('error.field_required')
		// }

		// if (!values.NameAR) {
		//     errors.NameAR = this.props.t('error.field_required')
		// }

		return errors;
	};

	handleFocus = (e, lang) => {
		this.setState({
			focusedElement: e.component,
			focusedPosition: e.component.getSelection()?.index,
			focusedLang: lang,
		});
	};

	insertTag = (src) => {
		if (this.state.focusedElement) {
			this.state.focusedElement.insertText(
				this.state.focusedPosition,
				"{" +
					`${
						this.state.focusedLang === "ar"
							? src.Field_Source_AR
							: src.Field_Source_EN
					}` +
					"}"
			);
		}
		this.state.focusedElement.focus();
		this.state.focusedElement.setSelection(this.state.focusedPosition, 0);
	};

	handleBlur = (e) => {
		e.target.classList.remove("focused");
	};

	render() {
		return (
			<Form
				onSubmit={this.onSectionSubmit}
				initialValues={this.state.fields}
				validate={this.validate}
				subscription={{ submitting: true, initialValues: true }}
				render={({
					handleSubmit,
					form,
					submitting,
					pristine,
					values,
				}) => (
					<div className="container-fluid mt-3">
						<form
							id="updateNotificationForm"
							className="row"
							onSubmit={handleSubmit}
							noValidate
						>
							<Col md={10}>
								<div className="row">
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t(
														"notificationNameAR"
													)}
												</label>
												<Field
													name="notificationNameAR"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.notificationNameAR
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"ar"
																	)
																}
															></Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t(
														"notificationNameEN"
													)}
												</label>
												<Field
													name="notificationNameEN"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.notificationNameEN
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col
										xs={12}
										md={{ span: 6, offset: 6 }}
										className="mt-3"
									>
										<Switches
											disabled={this.props.viewMood}
											color="secondary"
											name="smsStatus"
											data={{
												label: `${this.props.t(
													"smsStatus"
												)}`,
												value: `${this.props.t(
													"smsstatus"
												)}`,
											}}
										/>
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("smsMsgAr")}
												</label>
												<Field
													name="smsMsgAr"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.smsMsgAr
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"ar"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("smsMsgEn")}
												</label>
												<Field
													name="smsMsgEn"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.smsMsgEn
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col
										xs={12}
										md={{ span: 6, offset: 6 }}
										className="mt-3"
									>
										<Switches
											disabled={this.props.viewMood}
											color="secondary"
											name="mailStatus"
											data={{
												label: `${this.props.t(
													"mailStatus"
												)}`,
												value: `${this.props.t(
													"mailStatus"
												)}`,
											}}
										/>
									</Col>
									<Col xs={12} md={4} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t(
														"mailReplyTo"
													)}
												</label>
												<Field
													name="mailReplyTo"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="50px"
																defaultValue={
																	this.state
																		.fields
																		.mailReplyTo
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={4} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("mailCC")}
												</label>
												<Field
													name="mailCC"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="50px"
																defaultValue={
																	this.state
																		.fields
																		.mailCC
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={4} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("mailBCC")}
												</label>
												<Field
													name="mailBCC"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="50px"
																defaultValue={
																	this.state
																		.fields
																		.mailCC
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t(
														"mailTitleAR"
													)}
												</label>
												<Field
													name="mailTitleAR"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.mailTitleAR
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"ar"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t(
														"mailTitleEN"
													)}
												</label>
												<Field
													name="mailTitleEN"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.mailTitleEN
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("mailbodyAR")}
												</label>
												<Field
													name="mailbodyAR"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="200px"
																defaultValue={
																	this.state
																		.fields
																		.mailbodyAR
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"ar"
																	)
																}
																toolbar={
																	toolbaroptions
																}
															></Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("mailbodyEN")}
												</label>
												<Field
													name="mailbodyEN"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="200px"
																defaultValue={
																	this.state
																		.fields
																		.mailbodyEN
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
																toolbar={
																	toolbaroptions
																}
															></Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col
										xs={12}
										md={{ span: 6, offset: 6 }}
										className="mt-3"
									>
										<Switches
											disabled={this.props.viewMood}
											color="secondary"
											name="webStatus"
											data={{
												label: `${this.props.t(
													"webStatus"
												)}`,
												value: `${this.props.t(
													"webStatus"
												)}`,
											}}
										/>
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("webtitleAR")}
												</label>
												<Field
													name="webtitleAR"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.webtitleAR
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"ar"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("webTitleEN")}
												</label>
												<Field
													name="webTitleEN"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.webTitleEN
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("webMsgAR")}
												</label>
												<Field
													name="webMsgAR"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="200px"
																defaultValue={
																	this.state
																		.fields
																		.webMsgAR
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"ar"
																	)
																}
																toolbar={
																	toolbaroptions
																}
															></Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t("webMsgEN")}
												</label>
												<Field
													name="webMsgEN"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="200px"
																defaultValue={
																	this.state
																		.fields
																		.webMsgEN
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
																toolbar={
																	toolbaroptions
																}
															></Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col
										xs={12}
										md={{ span: 6, offset: 6 }}
										className="mt-3"
									>
										<Switches
											color="secondary"
											name="mobileStatus"
											data={{
												label: `${this.props.t(
													"mobileStatus"
												)}`,
												value: `${this.props.t(
													"mobileStatus"
												)}`,
											}}
										/>
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t(
														"mobiletitleAR"
													)}
												</label>
												<Field
													name="mobiletitleAR"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.mobiletitleAR
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"ar"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t(
														"mobileTitleEN"
													)}
												</label>
												<Field
													name="mobileTitleEN"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.mobileTitleEN
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t(
														"mobileMsgAR"
													)}
												</label>
												<Field
													name="mobileMsgAR"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.mobileMsgAR
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"ar"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
									<Col xs={12} md={6} className="mt-3">
										{this.state.activeFields && (
											<div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
												<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
													{this.props.t(
														"mobileMsgEN"
													)}
												</label>
												<Field
													name="mobileMsgEN"
													subscription={{
														error: true,
														initial: true,
													}}
												>
													{(fieldProps) => {
														return (
															<Wysiwyg
																rtlEnabled={
																	false
																}
																height="100px"
																defaultValue={
																	this.state
																		.fields
																		.mobileMsgEN
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																onFocusOut={(
																	e
																) =>
																	this.handleFocus(
																		e,
																		"en"
																	)
																}
															>
																<Toolbar
																	multiline={
																		false
																	}
																>
																	{/* <Item name="undo" />
															<Item name="redo" />
															<Item name="separator" />
															<Item name="bold" />
															<Item name="italic" />
															<Item name="underline" />
															<Item name="strike" />
															<Item name="separator" />
															<Item name="link" />
															<Item name="header" acceptedValues={[false, 3, 4, 5]} />
															<Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
															<Item name="orderedList" />
															<Item name="bulletList" />
															<Item name="alignLeft" />
															<Item name="alignCenter" />
															<Item name="alignRight" />  */}
																</Toolbar>
															</Wysiwyg>
														);
													}}
												</Field>
											</div>
										)}
									</Col>
								</div>
							</Col>
							<Col
								md={2}
								className={
									this.props.i18n.language === "ar"
										? "text-right"
										: ""
								}
							>
								<div className="position-sticky">
									{/* <p 
									className="dynamic-field"
									contentEditable="false"
									onClick={(e) => 
										this.getDynamicText(e , 'Verification OTP' , 'رمز التحقق')}
									> [Verification OTP Testing] </p> */}
									{this.state.notificationFields.map(
										(field) => {
											return (
												<p
													className="dynamic-field"
													contentEditable="false"
													onClick={
														(e) =>
															this.insertTag(
																field
															)
														// this.getDynamicText(e , field.Field_Name_EN , field.Field_Name_AR)
													}
												>
													[
													{this.props.i18n
														.language === "en"
														? field.Field_Name_EN
														: field.Field_Name_AR}
													]
												</p>
											);
										}
									)}
								</div>
							</Col>
							<Col
								className="my-4 justify-content-start d-flex"
								xs={12}
							>
								{!this.props.viewMood && (
									<ButtonPrimary
										color="primary"
										variant="contained"
										disabled={
											submitting || this.props.viewMood
										}
										type="submit"
									>
										{this.props.t("save")}
									</ButtonPrimary>
								)}
							</Col>
						</form>
					</div>
				)}
			/>
		);
	}
}
const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(NotificationUpdateForm));
