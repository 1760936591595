import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import { translationHook } from "../../../translationHook";

import { setLoading, triggerNotification } from "../../../../actions";
import { deleteRequestInvoice } from "../../../../apis/requests/info/deleteRequestInvoice";

class ReportInvoices extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	downloadInvoice = async (File_Code, File_Name) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DOWNLOAD_REQUEST_ATTACHMENT",
				{ File_Code },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
					responseType: "arraybuffer",
				}
			)
			.catch((error) => {
				return error.response;
			});

		if (response.status === 200) {
			let contentType = response.headers["content-type"],
				typesArray = [
					"application/pdf",
					"image/bmp",
					"image/gif",
					"image/vnd.microsoft.icon",
					"image/jpeg",
					"image/png",
					"image/svg+xml",
					"image/tiff",
					"image/webp",
				],
				url = window.URL.createObjectURL(
					new Blob([response.data], { type: contentType })
				),
				link = document.createElement("a");

			link.href = url;

			// typesArray.includes(contentType) ? link.setAttribute('target', 'blank') : link.setAttribute('download', File_Name)

			link.setAttribute("download", File_Name);

			document.body.appendChild(link);
			link.click();
			this.props.setLoading(false);
		}
	};

	handleDelete = async (row) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		// Promise.all([
		//     deleteRequestTaskAttachment({ at, File_Code: row.Invoice_File.File_Code, Request_Task_Id: this.props.requestTaskId, Request_Id: this.props.requestId }),
		//     deleteRequestInvoice(at, row.Invoice_No, this.props.requestTaskId, this.props.requestId )
		// ]).then((response) => {
		//     console.log(response);
		// }).catch((error) => {
		//     console.log(error.response)
		// })

		const response = await deleteRequestInvoice(
			at,
			row.Invoice_No,
			this.props.requestTaskId,
			this.props.requestId
		).catch((error) => {
			return error.response;
		});

		this.props.triggerNotification(true, response.data);

		this.props.deleteCB(row.Invoice_No);

		this.props.setLoading(false);
	};

	handleSelectedInvoices = ({ selectedRowsData }) => {
		let invoicesList = [];

		if (selectedRowsData.length > 0) {
			selectedRowsData.forEach(({ Invoice_Id }, index) => {
				invoicesList.push({
					Template_Invoice_Index: index + 1,
					Template_Invoice_Id: Invoice_Id,
				});
			});
		}

		this.props.setSelectedInvoices(invoicesList);
	};

	render() {
		let columnAlign = "left",
			invoiceStatusKey = "Invoice_Status_Name_EN",
			invoiceTypeKey = "Invoice_Type_Name_EN",
			jsonDataSource = new CustomStore({
				key: "Invoice_Id",
				load: () => {
					return this.props.invoices;
				},
			});

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			invoiceStatusKey = "Invoice_Status_Name_AR";
			invoiceTypeKey = "Invoice_Type_Name_AR";
		}

		return (
			<DataGrid
				className={[
					this.props.i18n.language === "ar"
						? "dx-grid-ar"
						: "dx-grid-en",
					"globalBox",
				].join(" ")}
				id={`dataGrid_${this.props.key}`}
				width={"100%"}
				dataSource={jsonDataSource}
				showBorders={false}
				showColumnLines={false}
				showRowLines={true}
				allowColumnResizing={true}
				columnResizingMode={"widget"}
				allowColumnReordering={true}
				rowAlternationEnabled={false}
				focusedRowEnabled={true}
				onExporting={this.onExporting}
				columnWidth={"auto"}
				wordWrapEnabled={true}
				rtlEnabled={this.props.i18n.language === "ar" ? true : false}
				onSelectionChanged={this.handleSelectedInvoices}
			>
				<ColumnChooser
					enabled={true}
					allowSearch={true}
					search={"fff"}
					title={this.props.t("dxColumnChooserTitle")}
					emptyPanelText={this.props.t("dxColumnChooserPanelText")}
				/>

				<SearchPanel
					visible={true}
					width={240}
					placeholder={this.props.t("search")}
				/>

				<FilterRow visible={true} />

				<HeaderFilter visible={true} />

				<Export enabled={true} allowExportSelectedData={true} />

				<Grouping contextMenuEnabled={true} expandMode="rowClick" />
				<GroupPanel
					visible={true}
					emptyPanelText={this.props.t("dxGroupPanelText")}
				/>

				<Selection
					mode="multiple"
					selectAllMode={"allPages"}
					showCheckBoxesMode={"always"}
				/>

				<Column
					dataField="Invoice_No"
					width="auto"
					caption={this.props.t("invoiceNo")}
					alignment={columnAlign}
					sortOrder="asc"
					sortIndex={0}
					dataType="number"
				></Column>

				<Column
					dataField={invoiceTypeKey}
					width="auto"
					caption={this.props.t("invoiceType")}
					alignment={columnAlign}
				></Column>

				<Column
					dataField="Invoice_Date"
					width="auto"
					caption={this.props.t("invoiceDate")}
					alignment={columnAlign}
					format="yyyy-MM-dd"
				></Column>

				<Column
					dataField="Invoice_Value"
					width="auto"
					caption={this.props.t("invoiceValue")}
					alignment={columnAlign}
				></Column>

				<Column
					dataField={invoiceStatusKey}
					width="auto"
					caption={this.props.t("invoiceStatus")}
					alignment={columnAlign}
				></Column>

				<Column
					dataField="Payment_No"
					width="auto"
					caption={this.props.t("paymentNo")}
					alignment={columnAlign}
				></Column>
			</DataGrid>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("invoiceList");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { setLoading, triggerNotification })(
	translationHook(ReportInvoices)
);
