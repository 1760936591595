import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Link } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { ButtonSecondary } from "../UI/Buttons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import style from "../services/Service.module.scss";
import OfferInfo from "./OfferInfo";
import RegistrationInfo from "./RegistrationInfo";

import { translationHook } from "../translationHook";

class LicenseOffer extends Component {
	state = {
		key: 0,
		next: 1,
		prev: -1,
		serviceID: this.props.offerId,
		first: false,
		second: true,
		edit: false,
		view: false,
	};

	handleNext = () => {
		this.setState({
			key: this.state.next,
			next: this.state.next + 1,
			prev: this.state.prev + 1,
		});
	};

	handlePrev = () => {
		this.setState({
			key: this.state.prev,
			next: this.state.next - 1,
			prev: this.state.prev - 1,
		});
	};

	handleFirstStepDone = (sID) => {
		this.setState({
			serviceID: sID,
			key: 1,
			second: false,
			edit: true,
		});
	};

	renderChevs = () => {
		let rtl = this.props.i18n.language === "ar" ? true : false,
			iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
			iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

		return (
			<>
				<IconButton
					aria-label="prev"
					color="primary"
					onClick={this.handlePrev}
					disabled={this.state.key > 0 ? false : true}
				>
					{iconPrev}
				</IconButton>
				<IconButton
					aria-label="next"
					color="primary"
					onClick={this.handleNext}
					disabled={
						this.state.key < 4 && !this.state.second ? false : true
					}
				>
					{iconNext}
				</IconButton>
			</>
		);
	};

	componentDidMount() {
		this.setState({
			second: this.props.offerId ? false : true,
			edit: this.props.location.hash === "#view" ? false : true,
			view: this.props.location.hash === "#view" ? true : false,
		});
	}

	render() {
		let mystyle;
		if (this.props.i18n.language === "ar") {
			mystyle = "ServiceTabsAr";
		} else {
			mystyle = "ServiceTabsEn";
		}

		return (
			<Tab.Container
				id="left-tabs-example"
				defaultActiveKey={0}
				activeKey={this.state.key}
				onSelect={(k) =>
					this.setState({
						key: parseInt(k),
						next: parseInt(k) + 1,
						prev: parseInt(k) - 1,
					})
				}
				unmountOnExit="true"
			>
				<Row>
					<Col
						sm={12}
						className="d-flex justify-content-between align-items-center"
					>
						<div>{this.renderChevs()}</div>
						<Nav variant="pills" className={style.stepsNav}>
							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 0 ? style.active : ""
									}`}
									eventKey="0"
									disabled={this.state.first}
								>
									{this.props.t("offerInfo")}
								</Nav.Link>
							</Nav.Item>

							<Nav.Item className={mystyle}>
								<Nav.Link
									className={`${style.navLink} ${
										this.state.key === 1 ? style.active : ""
									}`}
									eventKey="1"
									disabled={this.state.second}
								>
									{this.props.t("registrationPlans")}
								</Nav.Link>
							</Nav.Item>
						</Nav>

						<ButtonSecondary
							variant="outlined"
							endIcon={<ExitToAppIcon />}
							component={Link}
							to="/license-offers"
							size="large"
						>
							{this.props.t("exitToOffers")}
						</ButtonSecondary>
					</Col>
					<Col sm={12}>
						<Tab.Content>
							<Tab.Pane eventKey={0} disabled={this.state.first}>
								<OfferInfo
									serviceid={this.state.serviceID}
									active={!this.state.first}
									onStepDone={this.handleFirstStepDone}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane>

							<Tab.Pane
								eventKey={1}
								disabled={this.state.second}
								unmountOnExit="true"
							>
								<RegistrationInfo
									serviceid={this.state.serviceID}
									active={!this.state.second}
									editMood={this.state.edit}
									viewMood={this.state.view}
								/>
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(translationHook(LicenseOffer));
