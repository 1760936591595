import apiConfig from "../apiConfig";

export const rejectRequest = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/REJECT_REQUEST",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const approveRequest = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/APPROVE_AND_FINISH_REQUEST_STEP",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const returnRequestEditData = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/RETURN_REQUEST_TO_CLIENT_TO_EDIT_DATA",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const returnRequestAddData = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/RETURN_REQUEST_TO_CLIENT_TO_ADD_ADDITIONAL_DATA",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const returnRequestStep = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_RETURN_STEP",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const generateRequestFees = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GENERATE_REQUEST_FEES_TRANSACTIONS",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestTemplateIssuance = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TEMPLATES_ISSUANCE",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestApprovingTemplateFile = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_APPROVING_TEMPLATES_FILES",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestApprovingDocument = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_APPROVING_DOCUMENTS_FILES",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const completeStep = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/COMPLETE_STEP",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestInspectionTime = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_INSPECTION_TIME",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestInspectionReport = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_INSPECTION_REPORT_ISSUANCE",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestApprovingInspectionReport = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_APPROVING_INSPECTION_REPORT_FILES",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestInspectionVisit = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_INSPECTION_VISIT",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestInsuranceRefund = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_INSURANCE_REFUND",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestViolationSettlement = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_PENDING_VIOLATION_SETTLEMENT",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const clearTaskData = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/CLEAR_REQUEST_TASK_DRAFT_DATA",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveTaskNotes = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_NOTES",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveTaskAdditionalNotes = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_ADDITIONAL_NOTES",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const escalateRequestStep = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_Escalate_Step",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response;
};

export const saveRequestRiskRating = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_Risk_Rating",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const saveRequestLicenseNameSelection = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_License_Name_Selection",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const sendRequestLicenseNameSelection = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Send_License_Name_Confirmation_To_Client",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const sendRequestLicenseNameCheckSpilling = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Send_Request_License_Name_For_Check_Spelling",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
