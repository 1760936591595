import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import style from "../return/ReturnStep.module.scss";
import { ButtonPrimary, ButtonSecondary } from "../../../UI/Buttons";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Checkboxes, Select } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import FormControl from "@material-ui/core/FormControl";

class ApproveLicenseName extends Component {
	state = {
		licenseNamesList: [],
		investorsList: [],
		originalInvestorsList: [],
		selectedSug: null,
		initValues: {},
		userId: 0,
		License_Remarks_AR: "",
		License_Remarks_EN: "",
		checkNameSpilling: false,
		usersList: [],
		selectedUser: 0,
	};

	getLicenseNamesList = async () => {
		let stepsArr = [],
			at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Get_Request_Task_License_Names_List",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			let initValues = this.formatInitValue(
					response.data.Data.License_Names_List
				),
				initSelection = this.findInitialSelection(
					response.data.Data.License_Names_List
				),
				initInvestorsValues = this.formatInitInvestorValue(
					response.data.Data.Investors_Names_List
				);

			if (initSelection) {
				this.setState({
					licenseNamesList: response.data.Data.License_Names_List,
					investorsList: initInvestorsValues,
					initValues: initValues,
					License_Remarks_AR: response.data.Data.License_Remarks_AR,
					License_Remarks_EN: response.data.Data.License_Remarks_EN,
					selectedSug: initSelection,
				});

				this.props.setValuesCB((prev) => {
					return {
						...prev,
						...JSON.parse(initSelection),
					};
				});
			} else {
				this.setState({
					licenseNamesList: response.data.Data.License_Names_List,
					investorsList: initInvestorsValues,
					initValues: initValues,
					License_Remarks_AR: response.data.Data.License_Remarks_AR,
					License_Remarks_EN: response.data.Data.License_Remarks_EN,
				});
			}
		}
	};

	getUsersList = async () => {
		let usersArr = [],
			at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_SETP_USERS_LIST",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.User_Name_AR
						: item.User_Name_EN;

				usersArr.push({
					value: item.User_Id,
					label,
				});
			});

			this.setState({ usersList: usersArr });
		}
	};

	onReturnRequestStepSubmit = async (values, form) => {};

	componentDidMount() {
		this.getLicenseNamesList();
		this.getUsersList();
	}

	handleSelectSug = (e, reset) => {
		reset();
		this.setState({
			selectedSug: e.target.value,
		});

		let selectedItem = JSON.parse(e.target.value);

		this.props.setValuesCB((prev) => {
			return {
				...prev,
				...selectedItem,
			};
		});
	};

	handleInvestorNameChange = (e, key, i) => {
		let investorsNamesList = this.state.investorsList,
			selecetedInvestor = investorsNamesList[i];

		selecetedInvestor[key] = e.target.value;
		investorsNamesList[i] = selecetedInvestor;

		this.setState({ investorsList: investorsNamesList });
	};

	handleSelectInvestor = (e, i) => {
		let investorsNamesList = this.state.investorsList,
			selecetedInvestor = investorsNamesList[i];

		if (e.target.checked) {
			selecetedInvestor["Selected"] = 1;
			investorsNamesList[i] = selecetedInvestor;
		} else {
			selecetedInvestor["Selected"] = 0;
			selecetedInvestor["Lic_Name_EN"] =
				selecetedInvestor.Old_Lic_Name_EN;
			selecetedInvestor["Lic_Name_AR"] =
				selecetedInvestor.Old_Lic_Name_AR;
			investorsNamesList[i] = selecetedInvestor;
		}

		this.setState({ investorsList: investorsNamesList });
	};

	// handleEscalateToUserSelect = (e) => {
	// 	this.setState({ userId: e.target.value });
	// 	this.props.setValuesCB((prev) => {
	// 		return { ...prev, Escalate_Step_Receiver_User_Id: e.target.value };
	// 	});
	// };

	formatInitValue = (data) => {
		let initValues = {};

		data.forEach((item, i) => {
			initValues[`sug_EN_${i}`] = item.Lic_Name_EN;
			initValues[`sug_AR_${i}`] = item.Lic_Name_AR;
		});

		return initValues;
	};

	formatInitInvestorValue = (data) => {
		let modifiedList = [];

		data.forEach((item, i) => {
			item["Old_Lic_Name_EN"] = item.Lic_Name_EN;
			item["Old_Lic_Name_AR"] = item.Lic_Name_AR;
			modifiedList.push(item);
		});

		return modifiedList;
	};

	findInitialSelection = (data) => {
		let selectedItem;

		data.forEach((item, i) => {
			if (item?.Selected) {
				selectedItem = JSON.stringify(item);
			}
		});

		return selectedItem;
	};

	handleTranslate = async (i, txt, lng) => {
		let itemsList = this.state.licenseNamesList,
			at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/SYSTEM/GENERAL/Text_Translation",
			{
				Input_Text: txt,
				Input_Lang: lng,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			if (lng === 1) {
				itemsList[i]["Lic_Name_EN"] = response.data.Data;
				itemsList[i]["Lic_Name_AR"] = txt;
			}

			if (lng === 2) {
				itemsList[i]["Lic_Name_AR"] = response.data.Data;
				itemsList[i]["Lic_Name_EN"] = txt;
			}

			let initValues = this.formatInitValue(itemsList);

			this.setState({
				licenseNamesList: itemsList,
				initValues: initValues,
				selectedSug: JSON.stringify(itemsList[i]),
			});

			this.props.setValuesCB((prev) => {
				return {
					...prev,
					...itemsList[i],
				};
			});
		}
	};

	componentDidUpdate(prevProp, prevState) {
		if (prevState !== this.state) {
			this.props.setValuesCB((prev) => {
				if (this.state.selectedSug) {
					return {
						...prev,
						// ...JSON.parse(this.state.selectedSug),
						License_Remarks_AR: this.state.License_Remarks_AR,
						License_Remarks_EN: this.state.License_Remarks_EN,
						Investors_Names_List: this.state.investorsList,
					};
				} else {
					return {
						...prev,
						License_Remarks_AR: this.state.License_Remarks_AR,
						License_Remarks_EN: this.state.License_Remarks_EN,
						Investors_Names_List: this.state.investorsList,
					};
				}
			});

			if (this.state.checkNameSpilling && this.state.selectedUser) {
				this.props.setUserCB(this.state.selectedUser);
			} else {
				this.props.setUserCB(null);
			}
		}
	}

	render() {
		const validate = (values) => {
			const errors = {};

			// if (values.receiverType === "3" && !values.userId) {
			// 	errors.userId = `${this.props.t("error.field_required")}`;
			// }

			return errors;
		};

		return (
			<>
				<Form
					onSubmit={this.onReturnRequestStepSubmit}
					initialValues={this.state.initValues}
					validate={validate}
					validateOnBlur={true}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="escalateStepForm"
							className="row"
							onSubmit={handleSubmit}
							noValidate
						>
							{/* {(() => {
								let activeField = form.getState().active;

								if (
									activeField &&
									form.getState().modified[activeField]
								) {
									let fieldVal =
											form.getState().values[activeField],
										fieldLng = activeField.split("_")[1],
										sugIndex = activeField.split("_")[2],
										selectedSug = JSON.parse(
											this.state.selectedSug
										);

									if (fieldLng === "AR") {
										selectedSug["Lic_Name_AR"] = fieldVal;
										selectedSug["Lic_Name_EN"] =
											form.getState().values[
												`sug_EN_${sugIndex}`
											];
									}

									if (fieldLng === "EN") {
										selectedSug["Lic_Name_EN"] = fieldVal;
										selectedSug["Lic_Name_AR"] =
											form.getState().values[
												`sug_AR_${sugIndex}`
											];
									}

									this.props.setValuesCB((prev) => {
										return { ...prev, ...selectedSug };
									});
								}
							})()} */}

							<Col xs={12} md={6}>
								<FormControl className="mt-3">
									<RadioGroup
										row
										name="receiverType"
										value={this.state.selectedSug}
										onChange={(e) => {
											this.handleSelectSug(e, form.reset);
										}}
										className="d-flex flex-column"
									>
										{this.state?.licenseNamesList.map(
											(item, i) => {
												return (
													<Col
														md={9}
														className="d-flex align-items-center"
													>
														<FormControlLabel
															value={JSON.stringify(
																item
															)}
															control={<Radio />}
															className="mb-3"
														/>

														<div className="flex-grow-1">
															<Field
																name={`sug_EN_${i}`}
															>
																{(
																	fieldProps
																) => (
																	<TextField
																		disabled={
																			this
																				.state
																				.selectedSug !==
																			JSON.stringify(
																				item
																			)
																		}
																		name={`sug_EN_${i}`}
																		label={
																			i <
																			3
																				? this.props.t(
																						`sugEN_${i}`
																				  )
																				: this.props.t(
																						"lastApprovedNameEN"
																				  )
																		}
																		onChange={(
																			e
																		) => {
																			let selectedSug =
																				JSON.parse(
																					this
																						.state
																						.selectedSug
																				);

																			fieldProps.input.onChange(
																				e
																					.target
																					.value
																			);

																			selectedSug[
																				"Lic_Name_EN"
																			] =
																				e.target.value;
																			selectedSug[
																				"Lic_Name_AR"
																			] =
																				values[
																					`sug_AR_${i}`
																				];

																			// this.setState(
																			// 	{
																			// 		selectedSug:
																			// 			JSON.stringify(
																			// 				selectedSug
																			// 			),
																			// 	}
																			// );

																			this.props.setValuesCB(
																				(
																					prev
																				) => {
																					return {
																						...prev,
																						...selectedSug,
																					};
																				}
																			);
																		}}
																	/>
																)}
															</Field>

															{this.state
																.selectedSug ==
																JSON.stringify(
																	item
																) && (
																<a
																	title="translate"
																	href="#"
																	style={{
																		display:
																			"block",
																		marginTop:
																			"-25px",
																		marginBottom:
																			"10px",
																		fontSize:
																			"12px",
																		color: "#333",
																		padding:
																			"0px 14px",
																	}}
																	onClick={(
																		e
																	) => {
																		e.preventDefault();
																		let inputField = `sug_EN_${i}`;
																		this.handleTranslate(
																			i,
																			values[
																				inputField
																			],
																			2
																		);
																	}}
																>
																	{this.props.t(
																		"translateToAr"
																	)}
																</a>
															)}
														</div>

														<div className="flex-grow-1 mx-3">
															<Field
																name={`sug_AR_${i}`}
															>
																{(
																	fieldProps
																) => (
																	<TextField
																		disabled={
																			this
																				.state
																				.selectedSug !==
																			JSON.stringify(
																				item
																			)
																		}
																		name={`sug_AR_${i}`}
																		label={
																			i <
																			3
																				? this.props.t(
																						`sugAR_${i}`
																				  )
																				: this.props.t(
																						"lastApprovedNameAR"
																				  )
																		}
																		onChange={(
																			e
																		) => {
																			let selectedSug =
																				JSON.parse(
																					this
																						.state
																						.selectedSug
																				);

																			fieldProps.input.onChange(
																				e
																					.target
																					.value
																			);

																			selectedSug[
																				"Lic_Name_AR"
																			] =
																				e.target.value;
																			selectedSug[
																				"Lic_Name_EN"
																			] =
																				values[
																					`sug_EN_${i}`
																				];

																			// this.setState(
																			// 	{
																			// 		selectedSug:
																			// 			JSON.stringify(
																			// 				selectedSug
																			// 			),
																			// 	}
																			// );

																			this.props.setValuesCB(
																				(
																					prev
																				) => {
																					return {
																						...prev,
																						...selectedSug,
																					};
																				}
																			);
																		}}
																	/>
																)}
															</Field>
															{/* <TextField
																disabled={
																	this.state
																		.selectedSug !==
																	JSON.stringify(
																		item
																	)
																}
																name={`sug_AR_${i}`}
																label={this.props.t(
																	`sugAR_${i}`
																)}
															/> */}
															{this.state
																.selectedSug ==
																JSON.stringify(
																	item
																) && (
																<a
																	title="translate"
																	href="#"
																	style={{
																		display:
																			"block",
																		marginTop:
																			"-25px",
																		marginBottom:
																			"10px",
																		fontSize:
																			"12px",
																		color: "#333",
																		padding:
																			"0px 14px",
																	}}
																	onClick={(
																		e
																	) => {
																		e.preventDefault();
																		let inputField = `sug_AR_${i}`;
																		this.handleTranslate(
																			i,
																			values[
																				inputField
																			],
																			1
																		);
																	}}
																>
																	{this.props.t(
																		"translateToEn"
																	)}
																</a>
															)}
														</div>
													</Col>
												);
											}
										)}
									</RadioGroup>
								</FormControl>
							</Col>

							<Col xs={12} md={6}>
								<FormControl className="mt-3">
									{this.state?.investorsList.map(
										(item, i) => {
											return (
												<Col
													md={12}
													className="d-flex align-items-center"
												>
													<div
														style={{
															maxWidth: "30%",
															marginInlineEnd:
																"8px",
															paddingTop: "10px",
														}}
													>
														<Checkboxes
															data={{
																label: "",
															}}
															name={`investor_${i}`}
															checked={
																item.Selected
															}
															onChange={(e) => {
																this.handleSelectInvestor(
																	e,
																	i
																);
															}}
														/>
													</div>

													<div className="flex-grow-1">
														<TextField
															disabled={true}
															name={`inv_sug_EN_${i}`}
															label={`${this.props.t(
																"investorNameEN"
															)} - ${i + 1}`}
															value={
																item.Selected
																	? item.Lic_Name_EN
																	: item.Old_Lic_Name_EN
															}
															onChange={(e) => {
																this.handleInvestorNameChange(
																	e,
																	"Lic_Name_EN",
																	i
																);
															}}
														/>
													</div>

													<div className="flex-grow-1 mx-3">
														<TextField
															disabled={
																!item.Selected
															}
															name={`inv_sug_AR_${i}`}
															label={`${this.props.t(
																"investorNameAR"
															)} - ${i + 1}`}
															value={
																item.Selected
																	? item.Lic_Name_AR
																	: item.Old_Lic_Name_AR
															}
															onChange={(e) => {
																this.handleInvestorNameChange(
																	e,
																	"Lic_Name_AR",
																	i
																);
															}}
														/>
													</div>
												</Col>
											);
										}
									)}
								</FormControl>
							</Col>

							<Col md={12}>
								<Row>
									<Col md={6}>
										<TextField
											name="License_Remarks_AR"
											label={this.props.t(
												"licenseRemarksAr"
											)}
											rows={5}
											multiline
											onChange={(e) => {
												this.setState({
													License_Remarks_AR:
														e.target.value,
												});
											}}
											value={
												this.state.License_Remarks_AR
											}
										/>
									</Col>

									<Col md={6}>
										<TextField
											name="License_Remarks_EN"
											label={this.props.t(
												"licenseRemarksEn"
											)}
											rows={5}
											multiline
											onChange={(e) => {
												this.setState({
													License_Remarks_EN:
														e.target.value,
												});
											}}
											value={
												this.state.License_Remarks_EN
											}
										/>
									</Col>

									<Col xs={12} md={6}>
										<div className="d-flex w-100">
											<div className="w-50 custom-lg-cb">
												<Checkboxes
													data={{
														label: this.props.t(
															"checkNameSpilling"
														),
													}}
													className="mb-2"
													name="checkNameSpilling"
													checked={
														this.state
															.checkNameSpilling
													}
													onChange={(e) => {
														this.setState(
															(prev) => {
																return {
																	checkNameSpilling:
																		!prev.checkNameSpilling,
																	selectedUser:
																		prev.checkNameSpilling
																			? null
																			: this
																					.state
																					.usersList[0]
																					?.value,
																};
															}
														);
													}}
												/>
											</div>
											{this.state.checkNameSpilling && (
												<div className="flex-grow-1">
													<Select
														disabled={
															this.props.viewMood
														}
														label={this.props.t(
															"users"
														)}
														name="userName"
														data={
															this.state.usersList
														}
														value={
															this.state
																.selectedUser
														}
														onChange={(e) => {
															this.setState({
																selectedUser:
																	e.target
																		.value,
															});
														}}
													></Select>
												</div>
											)}
										</div>
									</Col>
								</Row>
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ApproveLicenseName));
