import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import { translationHook } from "../../../translationHook";

import {
	setLoading,
	triggerNotification,
	triggerDialogForm,
} from "../../../../actions";

class RiskRatingStep extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	calculateRiskFactor = ({ data }) => {
		return data.Risk_Factors.map((factor) => (
			<span
				style={{
					display: "block",
					marginBottom: "4px",
					borderBottom: "1px solid #ccc",
					paddingBottom: "2px",
					fontSize: "12px",
					padding: "4px",
				}}
				key={factor.Risk_Factor_Id}
			>
				{this.props.i18n.language === "ar"
					? factor?.Risk_Factor_Name_AR
					: factor?.Risk_Factor_Name_EN}
			</span>
		));
	};

	calculateRiskFactorValue = ({ data }) => {
		return data.Risk_Factors.map((factor) => {
			if (
				factor?.Risk_Factor_Value_Name_AR === "" &&
				factor?.Risk_Factor_Value_Name_EN === ""
			) {
				return null;
			}

			return (
				<span
					style={{
						display: "block",
						marginBottom: "4px",
						borderBottom: "1px solid #ccc",
						paddingBottom: "2px",
						fontSize: "12px",
						padding: "4px",
					}}
					key={factor.Risk_Factor_Id}
				>
					{this.props.i18n.language === "ar"
						? factor?.Risk_Factor_Value_Name_AR
						: factor?.Risk_Factor_Value_Name_EN}
				</span>
			);
		});
	};

	calculateRiskFactorResult = ({ data }) => {
		return data.Risk_Factors.map((factor) => {
			return (
				<span
					style={{
						display: "block",
						backgroundColor: factor?.Risk_Factor_Rate_Color,
						marginBottom: "4px",
						padding: "4px",
						fontSize: "12px",
						color: "#fff",
						borderRadius: "2px",
						textAlign: "center",
						// maxWidth: "100px",
					}}
					key={factor.Risk_Factor_Id}
				>
					{this.props.i18n.language === "ar"
						? factor?.Risk_Factor_Rate_Name_AR
						: factor?.Risk_Factor_Rate_Name_EN}
				</span>
			);
		});
	};

	calculateRiskCatResult = ({ data }) => {
		return (
			<div
				style={{
					backgroundColor: data?.Risk_Category_Rate_Color,
					color: "#fff",
					padding: "8px",
					borderRadius: "4px",
					textAlign: "center",
				}}
			>
				{this.props.i18n.language === "ar"
					? data?.Risk_Category_Rate_Name_AR
					: data?.Risk_Category_Rate_Name_EN}
			</div>
		);
	};

	render() {
		let columnAlign = "left",
			jsonDataSource = new CustomStore({
				key: "Risk_Category_Id",
				load: async () => {
					if (this.props.riskRatingCalculations) {
						return this.props.riskRatingCalculations;
					}

					let at = this.props.currentUser.Data.access_token;

					const response = await apiConfig.post(
						"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Get_Request_Task_Risk_Rating_Calculation",
						{
							Request_Id: this.props.requestId,
							Request_Task_Id: this.props.requestTaskId,
						},
						{
							headers: {
								Authorization: `Bearer ${at}`,
							},
						}
					);

					if (response.data.Status) {
						this.props.setValuesCB({
							Risk_Rate_Id: response.data.Data.Risk_Rate_Id,
							Risk_Rate_Color: response.data.Data.Risk_Rate_Color,
							Risk_Rate_Name_EN:
								response.data.Data.Risk_Rate_Name_EN,
							Risk_Rate_Name_AR:
								response.data.Data.Risk_Rate_Name_AR,
							Calculate_Risk_Rate_Id:
								response.data.Data.Calculate_Risk_Rate_Id,
							Calculate_Risk_Rate_Color:
								response.data.Data.Calculate_Risk_Rate_Color,
							Calculate_Risk_Rate_Name_EN:
								response.data.Data.Calculate_Risk_Rate_Name_EN,
							Calculate_Risk_Rate_Name_AR:
								response.data.Data.Calculate_Risk_Rate_Name_AR,
							System_Risk_Rate_Name_AR:
								response.data.Data.System_Risk_Rate_Name_AR,
							System_Risk_Rate_Name_EN:
								response.data.Data.System_Risk_Rate_Name_EN,
							System_Risk_Rate_Color:
								response.data.Data.System_Risk_Rate_Color,
							DNFBP_Activities_Rating:
								response.data.Data.DNFBP_Activities_Rating,
							ESR_Activities_Rating:
								response.data.Data.ESR_Activities_Rating,
							Interview_Risk_Rating:
								response.data.Data.Interview_Risk_Rating,
							WorldCheck_Risk_Rating:
								response.data.Data.WorldCheck_Risk_Rating,
							licenseName:
								this.props.i18n.language === "ar"
									? response.data.Data.License_Name_AR
									: response.data.Data.License_Name_EN,
							licenseNo: response.data.Data.License_No,
						});
						return response.data.Data.Risk_Rating_Calculation;
					}

					return [];
				},
			});

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		return (
			<>
				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id={`dataGrid_${this.props.key}`}
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<SearchPanel
						visible={false}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={false} />

					<HeaderFilter visible={false} />

					<Export enabled={false} allowExportSelectedData={false} />

					<Column
						dataField="Risk_Category_Id"
						width="auto"
						caption={this.props.t("riskNo")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Risk_Category_Name_AR"
								: "Risk_Category_Name_EN"
						}
						width="auto"
						caption={this.props.t("riskCat")}
						alignment={columnAlign}
					></Column>

					<Column
						width="auto"
						caption={this.props.t("riskCatResult")}
						alignment={columnAlign}
						cellRender={this.calculateRiskCatResult}
					></Column>

					<Column
						width="auto"
						caption={this.props.t("riskFactor")}
						alignment={columnAlign}
						cellRender={this.calculateRiskFactor}
					></Column>

					<Column
						width="auto"
						caption={this.props.t("riskFactorVal")}
						alignment={columnAlign}
						cellRender={this.calculateRiskFactorValue}
					></Column>

					<Column
						width="auto"
						caption={this.props.t("riskFactorResult")}
						alignment={columnAlign}
						cellRender={this.calculateRiskFactorResult}
					></Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("riskRating");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(RiskRatingStep));
