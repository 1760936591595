import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../translationHook";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import apiConfig from "../../apis/apiConfig";
import moment from "moment";
import { ButtonPrimary } from "../UI/Buttons";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../actions";
import {
	DataGrid,
	Column,
	Paging,
	SearchPanel,
} from "devextreme-react/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { downloadAttachment } from "../../apis/system/downloadAttachment";

class LicenseActivityForm extends Component {
	state = {
		initialValues: {},
		activitySegments: [],
		activityRisks: [],
		activityGroups: [],
		activityCats: [],
		activityApprovals: [],
		activityId: null,
	};

	getLicenseSegments = async () => {
		let activitySegments = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LICENSES_SEGMENTS"
		);
		if (response.data.Status) {
			response.data.Data?.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.License_Segment_Name_AR
						: item.License_Segment_Name_EN;
				activitySegments.push({
					value: item.License_Segment_Id,
					label,
				});
			});
			this.setState({ activitySegments });
		}
	};

	getRiskRating = async () => {
		let activityRisks = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_RISK_RATING_DEGREES"
		);
		if (response.data.Status) {
			response.data.Data?.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.License_Risk_Rating_Degree_Name_AR
						: item.License_Risk_Rating_Degree_Name_EN;
				activityRisks.push({
					value: item.License_Risk_Rating_Degree_Id,
					label,
				});
			});
			this.setState({ activityRisks });
		}
	};

	handleActivityGroupSelection = ({ data }) => {
		let url = data.Selected
			? "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Del_License_Activity_Group_Selection"
			: "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Add_License_Activity_Group_Selection";

		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Selected == 0 ? false : true}
						name={`activityGroup_${data.License_Activity_Group_Id}`}
						onChange={async () => {
							this.props.setLoading(true, "dark");

							const response = await apiConfig.post(
								url,
								{
									License_Activity_Group_Id:
										data.License_Activity_Group_Id,
									License_Activity_Id:
										data.License_Activity_Id,
								},
								{
									headers: {
										Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
									},
								}
							);
							this.props.setLoading(false);

							if (response.data.Status) {
								this.setState({
									activityGroups: response.data.Data,
								});
							}
						}}
					/>
				}
				// label={label}
			/>
		);
	};

	handleActivityCatSelection = ({ data }) => {
		let url = data.Selected
			? "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Del_License_Activity_Category_Selection"
			: "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Add_License_Activity_Category_Selection";

		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Selected == 0 ? false : true}
						name={`activityCat_${data.License_Activity_Category_Id}`}
						onChange={async () => {
							this.props.setLoading(true, "dark");

							const response = await apiConfig.post(
								url,
								{
									License_Activity_Category_Id:
										data.License_Activity_Category_Id,
									License_Activity_Id:
										data.License_Activity_Id,
								},
								{
									headers: {
										Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
									},
								}
							);
							this.props.setLoading(false);

							if (response.data.Status) {
								this.setState({
									activityCats: response.data.Data,
								});
							}
						}}
					/>
				}
				// label={label}
			/>
		);
	};

	handleActivityApprovalSelection = ({ data }) => {
		let url = data.Selected
			? "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Del_License_Activity_Approval_Selection"
			: "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Add_License_Activity_Approval_Selection";

		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Selected == 0 ? false : true}
						name={`activityApproval_${data.Government_Entity_Id}`}
						onChange={async () => {
							this.props.setLoading(true, "dark");

							const response = await apiConfig.post(
								url,
								{
									Government_Entity_Id:
										data.Government_Entity_Id,
									License_Activity_Id:
										data.License_Activity_Id,
								},
								{
									headers: {
										Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
									},
								}
							);
							this.props.setLoading(false);

							if (response.data.Status) {
								this.setState({
									activityApprovals: response.data.Data,
								});
							}
						}}
					/>
				}
				// label={label}
			/>
		);
	};

	componentDidMount() {
		this.getLicenseSegments();
		this.getRiskRating();

		if (this.props?.activityGroups) {
			this.setState({ activityGroups: this.props?.activityGroups });
		}

		if (this.props?.activityCats) {
			this.setState({ activityCats: this.props?.activityCats });
		}

		if (this.props?.activityApprovals) {
			this.setState({ activityApprovals: this.props?.activityApprovals });
		}
	}

	onSubmit = async (values) => {
		let url = this.props.activityId
				? "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Upd_License_Activity"
				: "/API/ADMINISTRATION/LICENSES/ACTIVITIES/Add_License_Activity",
			bodyData = {
				License_Activity_ISIC: values.License_Activity_ISIC,
				License_Activity_Name_AR: values.License_Activity_Name_AR,
				License_Activity_Name_EN: values.License_Activity_Name_EN,
				License_Activity_Description_AR:
					values.License_Activity_Description_AR,
				License_Activity_Description_EN:
					values.License_Activity_Description_EN,
				License_Risk_Rating_Degree_Id:
					values.License_Risk_Rating_Degree_Id,
				License_Segment_Id: values.License_Segment_Id,
				License_Activity_Add_Price: values.License_Activity_Add_Price,
				License_Activity_Modify_Price:
					values.License_Activity_Modify_Price,
				License_Activity_Delete_Price:
					values.License_Activity_Delete_Price,
				DNFBP: values.DNFBP ? 1 : 0,
				ESR: values.ESR ? 1 : 0,
				Special: values.Special ? 1 : 0,
				Active_Status_Id: values.Active_Status_Id ? 1 : 0,
			};

		if (this.props.activityId || this.state.activityId) {
			bodyData["License_Activity_Id"] = this.props.activityId;
		}

		this.props.setLoading(true);

		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
			},
		});
		this.props.setLoading(false);

		if (response.data.Status) {
			this.setState({
				activityId:
					response.data.Data.Activity_Info.License_Activity_Id,
				activityApprovals: response.data.Data.Activity_Approvals,
				activityGroups: response.data.Data.Activity_Groups,
				activityCats: response.data.Data.Activity_Categories,
			});
		}

		this.props.triggerNotification(true, response.data);
	};

	handleAttachmentDownload = ({ data }) => {
		return (
			<GetAppIcon
				style={{ width: "100%", cursor: "pointer" }}
				onClick={async () => {
					let params = {
						at: this.props.currentUser.Data.access_token,
						File_Code: data.License_Attachment_File_Code,
					};
					const downloadResult = await downloadAttachment(params);
					const url = window.URL.createObjectURL(
						new Blob([downloadResult.data], {
							type: "application/pdf",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("target", "_blank");
					link.click();
				}}
			/>
		);
	};

	render() {
		let { activityApprovals, activityGroups, activityCats, activityInfo } =
			this.props;

		return (
			<>
				<Form
					onSubmit={this.onSubmit}
					initialValues={activityInfo}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
						handleReset,
					}) => (
						<form
							onSubmit={handleSubmit}
							onReset={handleReset}
							style={{ overflowX: "hidden" }}
							className={`py-4 px-3 departmentFormClass`}
							noValidate
						>
							<Row>
								<Col md={12}>
									<Row>
										<Col md={6}>
											<TextField
												label={this.props.t("ISIC")}
												className="read-only mb-3"
												name="License_Activity_ISIC"
											/>
										</Col>

										<Col xs={2}>
											<Switches
												color="primary"
												name="Active_Status_Id"
												data={{
													label: `${this.props.t(
														"serviceStatus"
													)}`,
													value: activityInfo?.Active_Status_Id,
												}}
											/>
										</Col>
									</Row>

									<Row>
										<Col md={6}>
											<TextField
												label={this.props.t(
													"activityAR"
												)}
												className="read-only mb-3"
												name="License_Activity_Name_AR"
											/>
										</Col>
										<Col md={6}>
											<TextField
												label={this.props.t(
													"activityEN"
												)}
												className="read-only mb-3"
												name="License_Activity_Name_EN"
											/>
										</Col>
									</Row>

									<Row>
										<Col md={6}>
											<TextField
												label={this.props.t(
													"activityDescAR"
												)}
												className="read-only mb-3"
												name="License_Activity_Description_AR"
												multiline
												rows={4}
											/>
										</Col>
										<Col md={6}>
											<TextField
												label={this.props.t(
													"activityDescEN"
												)}
												className="read-only mb-3"
												name="License_Activity_Description_EN"
												multiline
												rows={4}
											/>
										</Col>
									</Row>

									<Row>
										<Col xs={6}>
											<Select
												label={this.props.t(
													"activitySegment"
												)}
												className="mb-2"
												name="License_Segment_Id"
												data={
													this.state.activitySegments
												}
											></Select>
										</Col>

										<Col xs={6}>
											<Switches
												color="primary"
												name="Special"
												data={{
													label: `${this.props.t(
														"specialActivity"
													)}`,
													value: activityInfo?.Special,
												}}
											/>
										</Col>
									</Row>

									<Row>
										<Col md={4}>
											<TextField
												label={this.props.t(
													"activityPrice"
												)}
												className="read-only mb-3"
												name="License_Activity_Add_Price"
											/>
										</Col>
										<Col md={4}>
											<TextField
												label={this.props.t(
													"activityModifyPrice"
												)}
												className="read-only mb-3"
												name="License_Activity_Modify_Price"
											/>
										</Col>
										<Col md={4}>
											<TextField
												label={this.props.t(
													"activityDeletePrice"
												)}
												className="read-only mb-3"
												name="License_Activity_Delete_Price"
											/>
										</Col>
									</Row>

									<Row>
										<Col xs={6}>
											<Select
												label={this.props.t(
													"activityRisk"
												)}
												className="mb-2"
												name="License_Risk_Rating_Degree_Id"
												data={this.state.activityRisks}
											></Select>
										</Col>

										<Col xs={2}>
											<Switches
												color="primary"
												name="DNFBP"
												data={{
													label: `${this.props.t(
														"DNFBP"
													)}`,
													value: activityInfo?.DNFBP,
												}}
											/>
										</Col>

										<Col xs={2}>
											<Switches
												color="primary"
												name="ESR"
												data={{
													label: `${this.props.t(
														"ESR"
													)}`,
													value: activityInfo?.ESR,
												}}
											/>
										</Col>

										<Col
											className="my-4 justify-content-start d-flex"
											xs={12}
										>
											{!this.props.viewMood && (
												<ButtonPrimary
													color="primary"
													variant="contained"
													disabled={
														submitting ||
														this.props.viewMood
													}
													type="submit"
												>
													{this.props.t("save")}
												</ButtonPrimary>
											)}
										</Col>
									</Row>
								</Col>

								{this.state.activityGroups &&
									this.state.activityGroups.length > 0 && (
										<Col md={4}>
											<div className="d-flex justify-content-between align-items-center mb-4">
												<h3>
													{this.props.t(
														"activitiesGroups"
													)}
												</h3>
											</div>

											<DataGrid
												className={[
													this.props.i18n.language ===
													"ar"
														? "dx-grid-ar"
														: "dx-grid-en",
													"globalBox",
												].join(" ")}
												id="dataGridActivityGroup"
												width={"100%"}
												keyExpr="License_Activity_Group_Id"
												dataSource={
													this.state.activityGroups
												}
												showBorders={false}
												showColumnLines={false}
												showRowLines={true}
												allowColumnResizing={true}
												columnResizingMode={"widget"}
												allowColumnReordering={true}
												rowAlternationEnabled={false}
												focusedRowEnabled={true}
												onExporting={this.onExporting}
												columnWidth={"auto"}
												wordWrapEnabled={true}
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												ref={(ref) =>
													(this.dataGrid = ref)
												}
												bounceEnabled={false}
												remoteOperations={false}
											>
												<Paging defaultPageSize={10} />

												<SearchPanel
													visible={true}
													width={240}
													placeholder={this.props.t(
														"search"
													)}
												/>

												<Column
													dataField="Classification"
													caption={this.props.t(
														"groupClass"
													)}
													alignment={
														this.props.i18n
															.language === "ar"
															? "right"
															: "left"
													}
												></Column>

												<Column
													dataField={
														this.props.i18n
															.language === "ar"
															? "Name_AR"
															: "Name_EN"
													}
													caption={this.props.t(
														"name"
													)}
													alignment={
														this.props.i18n
															.language === "ar"
															? "right"
															: "left"
													}
												></Column>

												<Column
													caption={this.props.t(
														"selected"
													)}
													alignment={
														this.props.i18n
															.language === "ar"
															? "right"
															: "left"
													}
													cellRender={
														this
															.handleActivityGroupSelection
													}
													width={100}
												></Column>
											</DataGrid>
										</Col>
									)}

								{this.state.activityCats &&
									this.state.activityCats.length > 0 && (
										<Col md={4}>
											<div className="d-flex justify-content-between align-items-center mb-4">
												<h3>
													{this.props.t(
														"activitiesCats"
													)}
												</h3>
											</div>

											<DataGrid
												className={[
													this.props.i18n.language ===
													"ar"
														? "dx-grid-ar"
														: "dx-grid-en",
													"globalBox",
												].join(" ")}
												id="dataGridActivityCat"
												width={"100%"}
												keyExpr="License_Activity_Category_Id"
												dataSource={
													this.state.activityCats
												}
												showBorders={false}
												showColumnLines={false}
												showRowLines={true}
												allowColumnResizing={true}
												columnResizingMode={"widget"}
												allowColumnReordering={true}
												rowAlternationEnabled={false}
												focusedRowEnabled={true}
												onExporting={this.onExporting}
												columnWidth={"auto"}
												wordWrapEnabled={true}
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												ref={(ref) =>
													(this.dataGrid = ref)
												}
												bounceEnabled={false}
												remoteOperations={false}
											>
												<Paging defaultPageSize={10} />

												<SearchPanel
													visible={true}
													width={240}
													placeholder={this.props.t(
														"search"
													)}
												/>

												<Column
													dataField={
														this.props.i18n
															.language === "ar"
															? "Name_AR"
															: "Name_EN"
													}
													caption={this.props.t(
														"name"
													)}
													alignment={
														this.props.i18n
															.language === "ar"
															? "right"
															: "left"
													}
												></Column>

												<Column
													caption={this.props.t(
														"selected"
													)}
													alignment={
														this.props.i18n
															.language === "ar"
															? "right"
															: "left"
													}
													cellRender={
														this
															.handleActivityCatSelection
													}
													width={100}
												></Column>
											</DataGrid>
										</Col>
									)}

								{this.state.activityApprovals &&
									this.state.activityApprovals.length > 0 && (
										<Col md={4}>
											<div className="d-flex justify-content-between align-items-center mb-4">
												<h3>
													{this.props.t(
														"governmentEntities"
													)}
												</h3>
											</div>

											<DataGrid
												className={[
													this.props.i18n.language ===
													"ar"
														? "dx-grid-ar"
														: "dx-grid-en",
													"globalBox",
												].join(" ")}
												id="dataGridActivityCat"
												width={"100%"}
												keyExpr="Government_Entity_Id"
												dataSource={
													this.state.activityApprovals
												}
												showBorders={false}
												showColumnLines={false}
												showRowLines={true}
												allowColumnResizing={true}
												columnResizingMode={"widget"}
												allowColumnReordering={true}
												rowAlternationEnabled={false}
												focusedRowEnabled={true}
												onExporting={this.onExporting}
												columnWidth={"auto"}
												wordWrapEnabled={true}
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												ref={(ref) =>
													(this.dataGrid = ref)
												}
												bounceEnabled={false}
												remoteOperations={false}
											>
												<Paging defaultPageSize={10} />

												<SearchPanel
													visible={true}
													width={240}
													placeholder={this.props.t(
														"search"
													)}
												/>

												<Column
													dataField={
														this.props.i18n
															.language === "ar"
															? "Name_AR"
															: "Name_EN"
													}
													caption={this.props.t(
														"name"
													)}
													alignment={
														this.props.i18n
															.language === "ar"
															? "right"
															: "left"
													}
												></Column>

												<Column
													caption={this.props.t(
														"selected"
													)}
													alignment={
														this.props.i18n
															.language === "ar"
															? "right"
															: "left"
													}
													cellRender={
														this
															.handleActivityApprovalSelection
													}
													width={100}
												></Column>
											</DataGrid>
										</Col>
									)}
							</Row>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
})(translationHook(LicenseActivityForm));
