import React, { Component } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	Pager,
	Paging,
	StateStoring,
} from "devextreme-react/data-grid";
import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import CustomStore from "devextreme/data/custom_store";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
} from "../../actions";
import {
	getInvoicesList,
	getInvoiceDetails,
} from "../../apis/system/invoices/index";

import { getLicenseActivitiesList } from "../../apis/licenses/licenseActivities/getLicenseActivitiesList";
import { getLicenseActivityInfo } from "../../apis/licenses/licenseActivities/getLicenseActivityInfo";
import { deleteLicenseActivity } from "../../apis/licenses/licenseActivities/deleteLicenseActivity";

import { translationHook } from "../translationHook";
import apiConfig from "../../apis/apiConfig";
import { ButtonWarning, ButtonPrimary, ButtonDanger } from "../UI/Buttons";
import { Select, Autocomplete } from "mui-rff";
import { Form } from "react-final-form";
import { Row, Col } from "react-bootstrap";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { CheckBox } from "devextreme-react";
import EditIcon from "@material-ui/icons/Edit";

import LicenseActivityForm from "./LicenseActivityForm";
class LicenseActivitiesList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
		this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
		this.devRef = React.createRef(null);
		this.searchState = React.createRef();
	}

	state = {
		view: false,
		reqData: {
			License_Activity_Group_Id_List: [],
			License_Activity_Category_Id_List: [],
			Government_Entity_Id_List: [],
		},
		gridState: [],
		gridData: [],
		gridKey: "",
		filterResults: 0,
		showGrid: false,
		searchResults: 0,
		saveGrid: false,
		pageSize: null,
		focusRow: -1,
		activityGroups: [],
		activityCats: [],
		governmentEntities: [],
	};

	getActivityGroups = async () => {
		let groups = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LICENSES_ACTIVITES_GROUPS"
		);
		if (response.data.Status) {
			response.data.Data?.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.License_Activity_Group_Name_AR
						: item.License_Activity_Group_Name_EN;
				groups.push({ value: item.License_Activity_Group_Id, label });
			});
			this.setState({ activityGroups: groups });
		}
	};

	getActivityCats = async () => {
		let cats = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LICENSES_ACTIVITES_CATEGORIES"
		);
		if (response.data.Status) {
			response.data.Data?.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.License_Activity_Category_Name_AR
						: item.License_Activity_Category_Name_EN;
				cats.push({ value: item.License_Activity_Category_Id, label });
			});
			this.setState({ activityCats: cats });
		}
	};

	getGovernmentEntities = async () => {
		let entities = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_GOVERNMENT_ENTITIES"
		);
		if (response.data.Status) {
			response.data.Data?.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;
				entities.push({ value: item.Record_Id, label });
			});
			this.setState({ governmentEntities: entities });
		}
	};

	componentDidMount() {
		this.loadState();
		this.getActivityGroups();
		this.getActivityCats();
		this.getGovernmentEntities();
		this.onSectionSubmit();
	}

	renderEditForm = (...params) => {
		let viewMood = params[5] === "view" ? true : false,
			editMood = params[5] === "edit" ? true : false;

		return (
			<LicenseActivityForm
				activityId={params[0]}
				activityInfo={params[1]}
				activityGroups={params[2]}
				activityCats={params[3]}
				activityApprovals={params[4]}
				viewMood={viewMood}
				editMood={editMood}
			/>
		);
	};

	renderAddForm = (...params) => {
		return <LicenseActivityForm />;
	};

	handleEdit = async (data, viewMood) => {
		let activityId = data.License_Activity_Id,
			auth = this.props.currentUser.Data.access_token,
			dialogTitle = `${this.props.t("viewActivity")}`;

		const activityInfo = await getLicenseActivityInfo(auth, activityId);

		if (activityInfo.data.Status) {
			this.props.triggerDialogForm(true, {
				title: dialogTitle,
				renderForm: this.renderEditForm,
				params: [
					activityId,
					activityInfo.data.Data.Activity_Info,
					activityInfo.data.Data.Activity_Groups,
					activityInfo.data.Data.Activity_Categories,
					activityInfo.data.Data.Activity_Approvals,
					viewMood,
				],
			});
		}
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			activityId = data.License_Activity_Id,
			documentName =
				this.props.i18n.language === "ar"
					? data.License_Activity_Name_AR
					: data.License_Activity_Name_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${documentName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: async () => {
					await deleteLicenseActivity(at, activityId).then(() => {
						this.onSectionSubmit();
					});
				},
				params: [at, activityId],
				text: this.props.t("deleteActivity"),
			},
		});
	};

	onSectionSubmit = async () => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await getLicenseActivitiesList(
			at,
			this.state.reqData
		).then((res) => {
			this.setState({ gridData: res });
			return res;
		});

		this.props.setLoading(false);
	};

	saveState = async () => {
		const at = this.props.currentUser.Data.access_token,
			url = "API/SYSTEM/GENERAL/SAVE_USER_GRID_SETTINGS",
			bodyData = {
				System_Grid_Name: "Grid_Lic_Activities_List",
				System_Grid_Json: this?.searchState?.current,
				Lang: this.props.i18n.language,
			};

		await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		this.props.triggerNotification(true, {
			Status: true,
		});
	};

	getState = async (state) => {
		if (state) {
			state.searchText = "";
			state.columns?.map((s) => (s.filterValue = undefined));
			this.searchState.current = JSON.stringify(state);
		}
	};

	loadState = async () => {
		const at = this.props.currentUser.Data.access_token,
			url = "API/SYSTEM/GENERAL/GET_USER_GRID_SETTINGS",
			bodyData = {
				System_Grid_Name: "Grid_Lic_Activities_List",
				Lang: this.props.i18n.language,
			};

		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		if (response.data) {
			this.setState({
				gridState: JSON.parse(response?.data?.Data),
				// showGrid: true,
				pageSize: JSON.parse(response?.data?.Data)?.pageSize,
			});
		}
	};

	onToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: "after",
				widget: "dxButton",
				options: {
					text: this.props.t("saveGridSettings"),
					height: "36px",
					icon: "save",
					onClick: () => this.saveState(),
					hint: this.props.t("saveGridSettings"),
				},
			},
			{
				location: "after",
				widget: "dxButton",
				options: {
					text: this.props.t("clearSearch"),
					height: "36px",
					icon: "clearformat",
					onClick: () => this.dataGrid?.instance?.clearFilter(),
					hint: this.props.t("clearSearch"),
				},
			}
		);
	}

	handleOnContentReady = (e) => {
		if (this.props.i18n.language === "ar") {
			const scrollable = e.component?.getScrollable();
			const maxScrollLeft = scrollable?.scrollWidth();

			scrollable?.scrollTo({
				x: maxScrollLeft,
			});
		}
	};

	render() {
		const jsonDataSource = new CustomStore({
			key: "License_Activity_Id",
			load: () => {
				return this.state.gridData;
			},
		});

		return (
			<>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h3>{this.props.t("activitiesList")}</h3>
				</div>

				<Form
					onSubmit={this.onSectionSubmit}
					initialValues={{
						License_Activity_Group_Id_List: [],
						License_Activity_Category_Id_List: [],
						Government_Entity_Id_List: [],
					}}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							className="row align-items-center"
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={4}>
								<Autocomplete
									multiple
									label={this.props.t("activitiesGroups")}
									name="License_Activity_Group_Id_List"
									className="mb-2"
									options={this.state.activityGroups}
									getOptionValue={(option) => option.value}
									getOptionLabel={(option) => option.label}
									disableCloseOnSelect={true}
									onChange={(e, v) => {
										let newArrId = [];
										v.map(
											(r) =>
												r?.value &&
												newArrId.push(r?.value)
										);
										this.setState({
											reqData: {
												...this.state.reqData,
												License_Activity_Group_Id_List:
													newArrId,
											},
										});
									}}
								/>
							</Col>

							<Col xs={4}>
								<Autocomplete
									multiple
									label={this.props.t("activitiesCats")}
									name="License_Activity_Category_Id_List"
									className="mb-2"
									options={this.state.activityCats}
									getOptionValue={(option) => option.value}
									getOptionLabel={(option) => option.label}
									disableCloseOnSelect={true}
									onChange={(e, v) => {
										let newArrId = [];
										v.map(
											(r) =>
												r?.value &&
												newArrId.push(r?.value)
										);
										this.setState({
											reqData: {
												...this.state.reqData,
												License_Activity_Category_Id_List:
													newArrId,
											},
										});
									}}
								/>
							</Col>

							<Col xs={4}>
								<Autocomplete
									multiple
									label={this.props.t("approvalEntities")}
									name="Government_Entity_Id_List"
									className="mb-2"
									options={this.state.governmentEntities}
									getOptionValue={(option) => option.value}
									getOptionLabel={(option) => option.label}
									disableCloseOnSelect={true}
									onChange={(e, v) => {
										let newArrId = [];
										v.map(
											(r) =>
												r?.value &&
												newArrId.push(r?.value)
										);
										this.setState({
											reqData: {
												...this.state.reqData,
												Government_Entity_Id_List:
													newArrId,
											},
										});
									}}
								/>
							</Col>

							<Col className="pb-4 submit-btn-wrap d-flex align-items-center justify-content-between">
								<ButtonPrimary
									color="primary"
									variant="contained"
									disabled={submitting}
									type="submit"
								>
									{this.props.t("search")}
								</ButtonPrimary>

								{this.state.gridData.length > 0 && (
									<h3>{`${this.props.t("searchResults")}: ${
										this.state.gridData.length
									}`}</h3>
								)}
							</Col>

							<Col
								xs={12}
								className="my-4 d-flex justify-content-end"
							>
								<ButtonPrimary
									variant="contained"
									onClick={() =>
										this.props.triggerDialogForm(true, {
											title: this.props.t("addActivity"),
											renderForm: this.renderAddForm,
											params: [],
										})
									}
								>
									{this.props.t("addActivity")}
								</ButtonPrimary>
							</Col>
						</form>
					)}
				/>

				{this.state.gridState?.columns ? (
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						onExporting={this.onExporting}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={
							this.props.i18n.language === "ar" ? true : false
						}
						onToolbarPreparing={this.onToolbarPreparing}
						ref={(ref) => (this.dataGrid = ref)}
						bounceEnabled={false}
						onOptionChanged={(e) => {
							if (e?.fullName == "paging.pageSize") {
								this.setState({ pageSize: e.value });
							}

							if (e.fullName.split(".").pop() == "filterValue") {
								this.dataGrid.instance.refresh();
							}
						}}
						onContentReady={this.handleOnContentReady}
						remoteOperations={false}
					>
						<ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/>

						<StateStoring
							enabled={true}
							type="custom"
							// customLoad={this.loadState}
							customSave={this.getState}
						/>

						<SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Export enabled={true} allowExportSelectedData={true} />

						<Grouping
							contextMenuEnabled={true}
							expandMode="rowClick"
						/>
						<GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						></GroupPanel>

						{/* <Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/> */}

						<Paging
							enabled={true}
							defaultPageSize={this.state.pageSize}
							pageSize={this.state.pageSize}
						/>

						<Pager
							visible="true"
							allowedPageSizes={[5, 10, 20, 50, 100]}
							displayMode="full"
							showPageSizeSelector="true"
							showInfo="true"
							infoText=""
							showNavigationButtons="true"
						/>

						{this.state.gridState?.columns
							?.filter((d) => d?.name !== "buttons")
							?.map((d, i) => {
								if (d?.dataType == "date") {
									return (
										<Column
											dataField={d?.dataField}
											caption={d?.name}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
											visible={d?.visible}
											width={d?.width ? d.width : "auto"}
											dataType={d?.dataType}
											format="yyyy-MM-dd"
											sortIndex={0}
											sortOrder="desc"
										></Column>
									);
								} else {
									return (
										<Column
											dataField={d?.dataField}
											caption={d?.name}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
											visible={d?.visible}
											width={d?.width ? d.width : "auto"}
											dataType={d?.dataType}
											defaultSortOrder="asc"
										></Column>
									);
								}
							})}

						<Column
							cssClass="outlinedColumn nowrapColumn"
							type="buttons"
							caption={this.props.t("details")}
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "left"
									: "right"
							}
						>
							<Button
								render={(record) => {
									return (
										<ButtonWarning
											variant="circleIcon"
											startIcon={<EditIcon />}
											onClick={() => {
												this.handleEdit(
													record.data,
													"edit"
												);
											}}
										>
											{/* {this.props.t('edit')} */}
										</ButtonWarning>
									);
								}}
							/>

							<Button
								render={(record) => {
									return (
										<ButtonDanger
											variant="circleIcon"
											startIcon={<DeleteIcon />}
											onClick={() =>
												this.handleDelete(record.data)
											}
										>
											{/* {this.props.t('delete')} */}
										</ButtonDanger>
									);
								}}
							/>
						</Column>
					</DataGrid>
				) : (
					<div
						className="globalBox"
						style={{
							height: "300px",
							width: "100%",
							backgroundColor: "#f7f9f5",
							padding: "1rem",
							borderRadius: "20px",
						}}
					>
						<div
							style={{
								padding: "1rem 1rem 0 1rem",
								backgroundColor: "#fff",
								height: "100%",
								borderRadius: "20px",
							}}
						></div>
					</div>
				)}
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("manageSystemModules");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(LicenseActivitiesList));
