import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import { Link } from "@reach/router";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import moment from "moment";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import { DataGrid, Column } from "devextreme-react/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
	getLicensesDetails,
	getLicenseVisasList,
} from "../../../apis/licenses/LicensesList";

import LicenseVisasList from "./LicenseVisasList";

import { ButtonPrimary, ButtonSecondary } from "../../UI/Buttons";

import LicenseVisaForm from "./LicenseVisaForm";

class LicenseVisas extends Component {
	state = {
		initValues: {},
		showAddbtn: false,
		visasList: [],
	};

	loadVisasList = async (auth, licenseId) => {
		const licenseVisas = await getLicenseVisasList(auth, licenseId);
		if (licenseVisas.data.Status) {
			this.setState({
				visasList: licenseVisas.data.Data.Visas_List,
				showAddbtn: licenseVisas.data.Data.Show_Add_Visa_Button,
				totalVisaCount: licenseVisas.data.Data.Total_Visas_Count,
				existVisaCount: licenseVisas.data.Data.Exist_Visas_Count,
				freeVisaCount: licenseVisas.data.Data.Free_Visas_Count,
			});
		}
	};

	componentDidMount() {
		let licenseId = this.props.licenseId,
			auth = this.props.currentUser.Data.access_token;

		if (licenseId) {
			(async () => {
				const licenseVisas = await getLicenseVisasList(auth, licenseId);
				if (licenseVisas.data.Status) {
					this.setState({
						visasList: licenseVisas.data.Data.Visas_List,
						showAddbtn: licenseVisas.data.Data.Show_Add_Visa_Button,
						totalVisaCount:
							licenseVisas.data.Data.Total_Visas_Count,
						existVisaCount:
							licenseVisas.data.Data.Exist_Visas_Count,
						freeVisaCount: licenseVisas.data.Data.Free_Visas_Count,
					});
				}
			})();
		}
	}

	renderForm = (...params) => {
		return (
			<LicenseVisaForm
				licenseId={this.props.licenseId}
				showSave={true}
				reloadCB={() => {
					let auth = this.props.currentUser.Data.access_token;

					this.loadVisasList(auth, this.props.licenseId);
				}}
			/>
		);
	};

	handleAdd = () => {
		this.props.triggerDialogForm(true, {
			title: "",
			renderForm: this.renderForm,
			params: [],
		});
	};

	onSubmit = async (values) => {};

	handleAttachmentDownload = ({ data }) => {
		return (
			<GetAppIcon
				style={{ width: "100%", cursor: "pointer" }}
				onClick={async () => {
					let params = {
						at: this.props.currentUser.Data.access_token,
						File_Code: data.License_Attachment_File_Code,
					};
					const downloadResult = await downloadAttachment(params);
					const url = window.URL.createObjectURL(
						new Blob([downloadResult.data], {
							type: "application/pdf",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("target", "_blank");
					link.click();
				}}
			/>
		);
	};

	render() {
		return (
			<>
				{this.props.standalone && (
					<div className="d-flex justify-content-end mb-3">
						<ButtonSecondary
							variant="outlined"
							endIcon={<ExitToAppIcon />}
							component={Link}
							to="/manage-visas"
							size="large"
						>
							{this.props.t("exitToLicenselist")}
						</ButtonSecondary>
					</div>
				)}
				<div className="d-flex justify-content-between align-items-center mb-4 py-4">
					{this.state.showAddbtn && (
						<ButtonPrimary
							size="large"
							onClick={() => {
								this.handleAdd();
							}}
						>
							{this.props.t("addVisa")}
						</ButtonPrimary>
					)}
					<div className="d-flex justify-content-between w-50">
						<div className="d-flex flex-column align-items-center">
							<h4>{this.props.t("totalVisaCount")}</h4>
							<h4
								style={{
									color: "#fff",
									borderRadius: "50%",
									width: "40px",
									height: "40px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "rgb(0 177 235 / 68%)",
								}}
							>
								{this.state.totalVisaCount}
							</h4>
						</div>

						<div className="d-flex flex-column align-items-center">
							<h4>{this.props.t("existVisaCount")}</h4>
							<h4
								style={{
									color: "#fff",
									borderRadius: "50%",
									width: "40px",
									height: "40px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "rgb(0 177 235 / 68%)",
								}}
							>
								{this.state.existVisaCount}
							</h4>
						</div>

						<div className="d-flex flex-column align-items-center">
							<h4>{this.props.t("freeVisaCount")}</h4>
							<h4
								style={{
									color: "#fff",
									borderRadius: "50%",
									width: "40px",
									height: "40px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "rgb(0 177 235 / 68%)",
								}}
							>
								{this.state.freeVisaCount}
							</h4>
						</div>
					</div>
				</div>

				{this.state.visasList && this.state.visasList.length > 0 && (
					<>
						<div className="d-flex justify-content-between align-items-center mb-4">
							<h3>{this.props.t("visasList")}</h3>
						</div>

						<LicenseVisasList
							visasList={this.state.visasList}
							reloadCB={() => {
								let auth =
									this.props.currentUser.Data.access_token;

								this.loadVisasList(auth, this.props.licenseId);
							}}
						/>
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
})(translationHook(LicenseVisas));
