import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { navigate } from "@reach/router";
import style from "../../../components/dashboard/Dashboard.module.scss";
import Processing from "../../requests/processing/Processing";
import TaskForm from "../../../components/dashboard/TaskForm";
import apiConfig from "../../../apis/apiConfig";

import { ButtonPrimary, ButtonDanger, ButtonSecondary } from "../../UI/Buttons";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import { Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import {
	saveTaskNotes,
	clearTaskData,
} from "../../../apis/requests/processTask";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";

import { downloadRequestAttachment } from "../../../apis/requests/info/downloadRequestAttachment";
import { deleteRequestTaskAttachment } from "../../../apis/requests/info/deleteRequestTaskAttachment";
import { Location } from "@reach/router";

const Notes = (props) => {
	const [draft, saveDraft] = useState("");
	const { t } = useTranslation("common");
	const currentDialogData = useSelector(
		(state) => state.currentDialogData.Data
	);

	const onChange = (event) => {
		saveDraft(event.target.value);
	};

	const handleClear = async () => {
		// this.props.triggerDialogForm(true, {
		//   renderForm: this.renderEditForm,
		//   params: [missionId, initValues],
		// });

		currentDialogData.params[0].viewMood
			? props.triggerDialogForm(true, {
					title: currentDialogData.title,
					closeBtnTitle: t("cancelToDashboard"),
					renderForm: renderEditForm,
					params: currentDialogData.params,
			  })
			: props.triggerDialogForm(true, {
					title: currentDialogData.title,
					closeBtnTitle: t("cancelToDashboard"),
					renderForm: renderViewForm,
					params: currentDialogData.params,
			  });

		// if (props.task && props.task === "inspectionVisit") {
		//   props.triggerDialogForm(false);
		//   return null;
		// }

		// let at = props.currentUser.Data.access_token;

		// const data = {
		//   Request_Id: props.requestId,
		//   Request_Task_Id: props.requestTaskId,
		// };

		// await clearTaskData(at, data).then(() => {
		//   props.triggerDialogForm(false);
		// });
	};

	const onTaskSubmit = async () => {
		let at = props.currentUser.Data.access_token,
			data = {
				Request_Id: props.requestId,
				Request_Task_Id: props.requestTaskId,
				Task_Notes: draft,
			};

		props.setLoading(true, "dark");

		// save notes before submitting to make sure to get latest entered note
		await saveTaskNotes(at, {
			Request_Id: props.requestId,
			Request_Task_Id: props.requestTaskId,
			Temp_Action_Id: props.tempId,
			Task_Notes: draft,
		})
			.then((notesRes) => {
				if (!notesRes.data.Status) {
					throw notesRes.data;
				}
			})
			.then(async () => {
				const saveTask = await props.onTaskSubmit(at, data);

				return saveTask;
			})
			.then((result) => {
				props.triggerNotification(true, result.data);

				//here 1/5
				if (result.data.Status) {
					window.location.assign("/");
					// window.location.href = '/'
					// props.triggerDialogForm(false);
					// window.location.reload()
				}
				props.setLoading(false);
			})
			.catch((error) => {
				props.setLoading(false);
				if (error.response.data.RESULT_CODE === 500) {
					props.triggerNotification(true, error.response.data);
				} else {
					props.triggerNotification(true, error.response);
				}

				return error.response;
			});
	};

	const sendRecommendations = async () => {
		const at = props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SEND_MODIFICATION_RECOMMENDATIONS_TO_CLIENT";
		let body = {
			Request_Id: props.requestId,
			Request_Task_Id: props.requestTaskId,
		};

		props.setLoading(true, "dark");

		await saveTaskNotes(at, {
			...body,
			Temp_Action_Id: props.tempId,
			Task_Notes: draft,
		})
			.then(async () => {
				return await apiConfig.post(url, body, {
					headers: {
						Authorization: `Bearer ${at}`,
					},
				});
			})
			.then((response) => {
				props.triggerNotification(true, response.data);

				props.setLoading(false);

				if (response.data.Status) {
					window.location.assign("/");
				}
			})
			.catch((error) => {
				return error.response;
			});
		// const response = await apiConfig
		// 	.post(url, body, {
		// 		headers: {
		// 			Authorization: `Bearer ${at}`,
		// 		},
		// 	})
		// 	.catch((error) => {
		// 		return error.response;
		// 	});
	};

	const validate = (values) => {
		const errors = {};

		if (!values.notes && props.required) {
			errors.notes = `${t("error.field_required")}`;
		}

		return errors;
	};

	const renderEditForm = (...params) => {
		return <TaskForm missionID={params[0]} initValues={params[1]} />;
	};
	// //
	const renderViewForm = (params) => {
		return (
			<div
				className={style.dashboardListContainer}
				style={{
					padding: params.viewMood
						? "4rem 2rem 0 2rem"
						: "7rem 2rem 0 2rem",
				}}
			>
				<Processing dataListObject={params} />
			</div>
		);
	};

	return (
		<>
			<Form
				onSubmit={onTaskSubmit}
				initialValues={{ notes: draft }}
				validate={validate}
				render={({
					handleSubmit,
					form,
					submitting,
					pristine,
					values,
				}) => {
					return (
						<form
							id="taskFrom"
							className="row mt-5"
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12} md={12}>
								<TextField
									label={t("notes")}
									className="mb-2"
									name="notes"
									required={props.required}
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
									multiline
									rows={10}
									value={draft}
									onChange={onChange}
								/>
							</Col>

							<Col xs={12} md={6}>
								<h5 className="my-4 text-start">
									{t("attachments")}
								</h5>
								<FilesUpload
									at={props.currentUser.Data.access_token}
									params={{
										Request_Id: props.requestId,
										Request_Task_Id: props.requestTaskId,
										Temp_Action_Id: props.tempId,
									}}
									downloadCB={downloadRequestAttachment}
									deleteCB={deleteRequestTaskAttachment}
									deleteParams={{
										Request_Task_Id: props.requestTaskId,
										Request_Id: props.requestId,
									}}
								/>
							</Col>

							<Col
								className="my-5 justify-content-start d-flex"
								xs={12}
							>
								{props.task === "approveLicenseName" &&
									!props.checkNameSpillingUser && (
										<ButtonSecondary
											style={{
												borderRadius: "30px",
												padding: ".75rem 1.5rem",
												minWidth: 90,
											}}
											variant="contained"
											onClick={async () => {
												let at =
														props.currentUser.Data
															.access_token,
													data = {
														Request_Id:
															props.requestId,
														Request_Task_Id:
															props.requestTaskId,
														Task_Notes: draft,
													};
												props.setLoading(true, "dark");

												// save notes before submitting to make sure to get latest entered note
												await saveTaskNotes(at, {
													Request_Id: props.requestId,
													Request_Task_Id:
														props.requestTaskId,
													Temp_Action_Id:
														props.tempId,
													Task_Notes: draft,
												})
													.then((notesRes) => {
														if (
															!notesRes.data
																.Status
														) {
															throw notesRes.data;
														}
													})
													.then(async () => {
														const saveTask =
															await props.onSendNameToClient(
																at,
																data
															);

														return saveTask;
													})
													.then((result) => {
														props.setLoading(false);

														props.triggerNotification(
															true,
															result.data
														);

														//here 1/5
														if (
															result.data.Status
														) {
															window.location.assign(
																"/"
															);
															// window.location.href = '/'
															// props.triggerDialogForm(false);
															// window.location.reload()
														}
													})
													.catch((error) => {
														props.setLoading(false);
														if (
															error.response.data
																.RESULT_CODE ===
															500
														) {
															props.triggerNotification(
																true,
																error.response
																	.data
															);
														} else {
															props.triggerNotification(
																true,
																error.response
															);
														}

														return error.response;
													});
												// props.onSendNameToClient();
											}}
										>
											{t("sendSugToClient")}
										</ButtonSecondary>
									)}

								{props.task === "approveLicenseName" &&
									props.checkNameSpillingUser && (
										<ButtonSecondary
											style={{
												borderRadius: "30px",
												padding: ".75rem 1.5rem",
												minWidth: 90,
											}}
											variant="contained"
											onClick={async () => {
												let at =
														props.currentUser.Data
															.access_token,
													data = {
														Request_Id:
															props.requestId,
														Request_Task_Id:
															props.requestTaskId,
														Task_Notes: draft,
													};
												props.setLoading(true, "dark");

												// save notes before submitting to make sure to get latest entered note
												await saveTaskNotes(at, {
													Request_Id: props.requestId,
													Request_Task_Id:
														props.requestTaskId,
													Temp_Action_Id:
														props.tempId,
													Task_Notes: draft,
												})
													.then((notesRes) => {
														if (
															!notesRes.data
																.Status
														) {
															throw notesRes.data;
														}
													})
													.then(async () => {
														const saveTask =
															await props.onNameCheckSpilling(
																at,
																data
															);

														return saveTask;
													})
													.then((result) => {
														props.setLoading(false);

														props.triggerNotification(
															true,
															result.data
														);

														//here 1/5
														if (
															result.data.Status
														) {
															window.location.assign(
																"/"
															);
															// window.location.href = '/'
															// props.triggerDialogForm(false);
															// window.location.reload()
														}
													})
													.catch((error) => {
														props.setLoading(false);
														if (
															error.response.data
																.RESULT_CODE ===
															500
														) {
															props.triggerNotification(
																true,
																error.response
																	.data
															);
														} else {
															props.triggerNotification(
																true,
																error.response
															);
														}

														return error.response;
													});
												// props.onSendNameToClient();
											}}
										>
											{t("sendSugToCheckSpilling")}
										</ButtonSecondary>
									)}

								{props.task === "templateIssuance" && (
									<ButtonSecondary
										style={{
											borderRadius: "30px",
											padding: ".75rem 1.5rem",
											minWidth: 90,
										}}
										variant="contained"
										onClick={sendRecommendations}
									>
										{t("sendRecommendationEditsBtn")}
									</ButtonSecondary>
								)}

								{!props.checkNameSpillingUser && (
									<ButtonPrimary
										color="primary"
										variant="contained"
										disabled={submitting}
										type="submit"
									>
										{props.task === "templateIssuance"
											? t("confirmServiceRequestDoc")
											: props.task ===
											  "approveLicenseName"
											? t("confirmLicenseName")
											: t("completeTask")}
									</ButtonPrimary>
								)}

								<ButtonDanger
									variant="contained"
									style={{
										borderRadius: "30px",
										padding: ".75rem 1.5rem",
										minWidth: 90,
									}}
									onClick={handleClear}
								>
									{t("cancel")}
								</ButtonDanger>
							</Col>
						</form>
					);
				}}
			/>
		</>
	);
};

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(Notes);
