import React from "react";
import style from "./Header.module.scss";
import { Row, Col } from "react-bootstrap";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Topbar from "./Topbar/Topbar";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import primaryLogo from "./../../../images/App-Logo.svg";
import primaryLogoStrip from "./../../../imagesTemp/login/logos/STRIP-Logo1.png";

const Header = (props) => {
	const { t, i18n } = useTranslation("common");

	const useStyles = makeStyles(() => ({
		appBar: {
			zIndex: props.respTopbarDrawer ? 9999 : 0,
			transition: !props.respTopbarDrawer ? "z-index .5s" : null,
		},
	}));

	const classes = useStyles();

	return (
		<>
			<Hidden mdUp>
				<AppBar position="fixed" className={classes.appBar}>
					<Row>
						<Col xs={4}>
							<Toolbar>
								<img
									src={primaryLogoStrip}
									className={style.logoImg}
								/>
							</Toolbar>
						</Col>
						<Col xs={8}>
							<Toolbar className="d-flex justify-content-end">
								<span
									className={style.sideMenuIcon}
									onClick={props.onToggleSide}
								>
									<span className={style.bar}></span>
									<span className={style.bar}></span>
									<span className={style.bar}></span>
								</span>
								<span
									className={style.customMenuIcon}
									onClick={props.onToggleCustom}
								>
									<span className={style.bar}></span>
									<span className={style.bar}></span>
									<span className={style.bar}></span>
								</span>
								<span
									className={[
										style.topbarIcon,
										"svg-md",
									].join(" ")}
									onClick={props.onToggleTopbar}
								>
									<svg
										viewBox="0 0 24 24"
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlnsXlink="http://www.w3.org/1999/xlink"
									>
										<title>
											Stockholm-icons / General / User
										</title>
										<desc>Created with Sketch.</desc>
										<defs></defs>
										<g
											id="Stockholm-icons-/-General-/-User"
											stroke="none"
											strokeWidth="1"
											fill="none"
											fillRule="evenodd"
										>
											<polygon
												id="Shape"
												points="0 0 24 0 24 24 0 24"
											></polygon>
											<path
												d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
												id="Mask"
												fill="#000000"
												fillRule="nonzero"
												opacity="0.3"
											></path>
											<path
												d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
												id="Mask-Copy"
												fill="#000000"
												fillRule="nonzero"
											></path>
										</g>
									</svg>
								</span>
							</Toolbar>
						</Col>
					</Row>
				</AppBar>
			</Hidden>

			<Hidden smDown implementation="css">
				<Row
					className={[
						style.headerCon,
						"fixedHeader align-items-center py-3",
					].join(" ")}
				>
					<Col md={6}>
						{/* <CustomMenu 
                        respCustomDrawer={props.respCustomDrawer} 
                        onToggleCustom={props.onToggleCustom}
                    /> */}
					</Col>
					<Col md={6}>
						<Topbar
							respTopbarDrawer={props.respTopbarDrawer}
							onToggleTopbar={props.onToggleTopbar}
						/>
					</Col>
				</Row>
			</Hidden>
		</>
	);
};

export default Header;
