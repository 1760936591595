import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ButtonPrimary, ButtonDanger } from "./../UI/Buttons";
import { Form } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";
import { OnChange } from "react-final-form-listeners";
import { Divider } from "@material-ui/core";

import apiConfig from "../../apis/apiConfig";
import { translationHook } from "../translationHook";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
	reload,
} from "../../actions";
import ServiceInfoForm from "./ServiceInfoForm";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import { getServiceActions } from "../../apis/services/getServiceActions";
import { deleteServiceAction } from "../../apis/services/deleteServiceAction";

class ServiceStepForm extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
		this.serviceIdRef = props.serviceStepId;
	}

	state = {
		timeTypes: [],
		stepTypes: [],
		serviceNotifications: [],
		stepScreens: [],
		createdStepId: null,
		showForm: false,
		reload: false,
		showReExcuteDD: false,
		reExcuteTypes: [],
	};

	getTimeTypes = async () => {
		let typesArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_TIME_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({ timeTypes: typesArr });
		}
	};

	getStepTypes = async () => {
		let typesArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_STEPS_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({ stepTypes: typesArr });
		}
	};

	getStepScreens = async (selectedStepType) => {
		let screensArr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_STEPS_SCREENS",
			{
				Service_Step_Type_Id: selectedStepType,
			}
		);

		if (response.data.Status) {
			response?.data?.Data?.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				screensArr.push({
					value: item.Record_Id,
					label,
					Service_Step_Can_Reopen: item.Service_Step_Can_Reopen,
				});
			});

			this.setState({ stepScreens: screensArr });
			this.handleShowReExecuteDD(this.props?.initValues, screensArr);
		}
	};

	getReExcuteTypes = async (selectedStepType) => {
		let typesArr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_STEPS_REEXECUTE_TYPES",
			{}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({ reExcuteTypes: typesArr });
		}
	};

	getServiceNotifications = async () => {
		let notificationArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_NOTIFICATIONS"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				notificationArr.push({ value: item.Record_Id, label });
			});

			this.setState({ serviceNotifications: notificationArr });
		}
	};

	handleShowReExecuteDD = (values, screensArr) => {
		if (!values || !screensArr || screensArr.length === 0) {
			return;
		}

		if (values?.stepScreen) {
			let screen = screensArr.find((s) => s.value === values?.stepScreen);

			if (screen?.Service_Step_Can_Reopen) {
				this.setState({
					showReExcuteDD: true,
				});
				this.getReExcuteTypes();
			} else {
				this.setState({
					showReExcuteDD: false,
				});
			}
		}
	};

	componentDidMount() {
		this.getStepTypes();
		this.getTimeTypes();
		this.getServiceNotifications();
	}

	handleDelete = async (data) => {
		let at = this.props.currentUser.Data.access_token,
			serviceActionId = data.Service_Action_Id,
			serviceEventName =
				this.props.i18n.language === "ar"
					? data.RAction_Name_AR
					: data.RAction_Name_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${serviceEventName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		const serviceStepId = this.state.createdStepId || this.serviceIdRef;

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteServiceAction,
				params: [
					at,
					this.props.serviceid,
					serviceActionId,
					serviceStepId
						? "/API/ADMINISTRATION/SERVICES/DEL_SERVICE_STEP_ACTION"
						: "/API/ADMINISTRATION/SERVICES/DEL_SERVICE_ACTION",
					serviceStepId,
				],
				text: this.props.t("serviceActionDelete"),
			},
		});
	};

	onServiceStepSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = !this.props.editMood
				? "/API/ADMINISTRATION/SERVICES/ADD_SERVICE_STEP"
				: "/API/ADMINISTRATION/SERVICES/UPD_SERVICE_STEP",
			bodyData = {
				Service_Step_Type_Id: values.stepType,
				Service_Step_Name_AR: values.stepNameAR,
				Service_Step_Name_EN: values.stepNameEN,
				Service_Step_Details_AR: values.stepDescAR,
				Service_Step_Details_EN: values.stepDescEN,
				Service_Step_Screen_Id: values.stepScreen,
				Service_Step_Internal: values.internal === true ? 1 : 0,
				Service_Step_Notification_Id: values.serviceNotification,
				Service_Step_Send_Notification:
					values.notification === true ? 1 : 0,
				Service_Step_Time: values.stepTime,
				Service_Step_Time_Type_Id: values.stepTimeType,
				Service_Step_Group_Time: values.stepAcceptTime,
				Service_Step_Group_Time_Type_Id: values.stepAcceptTimeType,
				Service_Step_Can_Skip: values.skip === true ? 1 : 0,
				Service_Step_Escalate_Level_1:
					values.Service_Step_Escalate_Level_1 === true ? 1 : 0,
				Service_Step_Escalate_Level_2:
					values.Service_Step_Escalate_Level_2 === true ? 1 : 0,
				Service_Step_ReExecute_Type_Id: values.reExcuteType,
			};

		if (!this.props.editMood) {
			bodyData.Service_Id = this.props.serviceid;
		} else {
			bodyData.Service_Step_Id = this.props.serviceStepId;
		}

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.setState({
			createdStepId: response.data.Data.Service_Step_Id,
		});

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);

		if (response.data.Status) {
			if (this.props.editMood) {
				this.props.triggerDialogForm(false);
			}
			this.props.reloadDatagrid();
			form.restart();
		}
	};

	validate = (values) => {
		const errors = {};

		if (!values.stepNameAR) {
			errors.stepNameAR = `${this.props.t("error.field_required")}`;
		}

		if (!values.stepNameEN) {
			errors.stepNameEN = `${this.props.t("error.field_required")}`;
		}

		if (!values.stepDescAR) {
			errors.stepDescAR = `${this.props.t("error.field_required")}`;
		}

		if (!values.stepDescEN) {
			errors.stepDescEN = `${this.props.t("error.field_required")}`;
		}

		if (!values.stepType) {
			errors.stepType = `${this.props.t("error.field_required")}`;
		}

		if (!values.stepTime) {
			errors.stepTime = `${this.props.t("error.field_required")}`;
		}

		if (!values.stepTimeType) {
			errors.stepTimeType = `${this.props.t("error.field_required")}`;
		}

		if (!values.stepAcceptTime) {
			errors.stepAcceptTime = `${this.props.t("error.field_required")}`;
		}

		if (!values.stepAcceptTimeType) {
			errors.stepAcceptTimeType = `${this.props.t(
				"error.field_required"
			)}`;
		}

		if (!values.stepScreen) {
			errors.stepScreen = `${this.props.t("error.field_required")}`;
		}

		if (
			!values.serviceNotification &&
			(values.notification === 1 || values.notification === true)
		) {
			errors.serviceNotification = `${this.props.t(
				"error.field_required"
			)}`;
		}

		return errors;
	};

	render() {
		let { initValues } = this.props;

		let columnAlign = "right",
			ServiceEventNameKey = "RAction_Event_Name_AR",
			ServiceNameKey = "RAction_Name_AR";

		if (this.props.i18n.language === "en") {
			columnAlign = "left";
			ServiceEventNameKey = "RAction_Event_Name_EN";
			ServiceNameKey = "RAction_Name_EN";
		}

		const serviceStepId = this.state.createdStepId || this.serviceIdRef;
		const jsonDataSource = new CustomStore({
			key: "Service_Action_Id",
			load: async () => {
				return getServiceActions(
					this.props.currentUser.Data.access_token,
					this.props.serviceid,
					"/API/ADMINISTRATION/SERVICES/GET_SERVICE_STEP_ACTIONS_LIST",
					serviceStepId
				);
			},
		});

		return (
			<>
				{!this.props.editMood && !this.props.viewMood && (
					<div className="d-flex justify-content-end">
						<ButtonPrimary
							variant={
								!this.state.showForm ? "contained" : "outlined"
							}
							onClick={() =>
								this.setState({
									showForm: !this.state.showForm,
								})
							}
						>
							{!this.state.showForm && this.props.t("addNewStep")}
							{this.state.showForm && this.props.t("cancel")}
						</ButtonPrimary>
					</div>
				)}
				<Form
					onSubmit={this.onServiceStepSubmit}
					initialValues={
						initValues
							? initValues
							: {
									stepNameAR: "",
									stepNameEN: "",
									stepDescAR: "",
									stepDescEN: "",
									stepType: "",
									stepTime: "",
									stepTimeType: "",
									stepAcceptTime: "",
									stepAcceptTimeType: "",
									stepScreen: "",
									serviceNotification: "",
									notification: 0,
									internal: 0,
									skip: 0,
							  }
					}
					validate={this.validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => {
						if (
							this.props.editMood &&
							values.stepType &&
							values.stepType !== "" &&
							values.stepScreens !== "" &&
							this.state.stepScreens.length === 0
						) {
							this.getStepScreens(values.stepType);
						}

						if (
							(values.notification === 0 ||
								values.notification === false) &&
							values.serviceNotification &&
							!this.props.editMood
						) {
							delete values.serviceNotification;
						}

						return (
							<form
								id="serviceStepForm"
								className={`row ${
									!this.state.showForm &&
									!this.props.editMood &&
									!this.props.viewMood
										? "d-none"
										: ""
								}`}
								onSubmit={handleSubmit}
								noValidate
							>
								<Col md={7}>
									<Row>
										<Col xs={12} md={4}>
											<TextField
												label={this.props.t("stepNo")}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												name="stepNo"
												disabled
											/>
										</Col>
										<Col xs={12} md={4}>
											<TextField
												disabled={this.props.viewMood}
												label={this.props.t(
													"stepNameAR"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												required={true}
												name="stepNameAR"
												autoComplete="off"
												showError={({
													meta: {
														submitFailed,
														invalid,
													},
												}) => {
													return (
														submitFailed && invalid
													);
												}}
											/>
										</Col>
										<Col xs={12} md={4}>
											<TextField
												disabled={this.props.viewMood}
												label={this.props.t(
													"stepNameEN"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												required={true}
												name="stepNameEN"
												autoComplete="off"
												showError={({
													meta: {
														submitFailed,
														invalid,
													},
												}) => {
													return (
														submitFailed && invalid
													);
												}}
											/>
										</Col>
										<Col xs={12} md={6}>
											<TextField
												disabled={this.props.viewMood}
												label={this.props.t(
													"stepDescAR"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
												required={true}
												name="stepDescAR"
												autoComplete="off"
												showError={({
													meta: {
														submitFailed,
														invalid,
													},
												}) => {
													return (
														submitFailed && invalid
													);
												}}
											/>
										</Col>
										<Col xs={12} md={6}>
											<TextField
												disabled={this.props.viewMood}
												label={this.props.t(
													"stepDescEN"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
												required={true}
												name="stepDescEN"
												autoComplete="off"
												showError={({
													meta: {
														submitFailed,
														invalid,
													},
												}) => {
													return (
														submitFailed && invalid
													);
												}}
											/>
										</Col>
										<Col xs={12} md={6}>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t("stepType")}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
												required={true}
												name="stepType"
												data={this.state.stepTypes}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"stepType"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
											></Select>
										</Col>

										<OnChange name="stepType">
											{(value, previous) => {
												if (value && value !== "") {
													this.getStepScreens(value);
												} else {
													this.setState({
														stepScreens: [],
													});
												}
											}}
										</OnChange>

										<Col xs={12} md={6}>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"stepScreen"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
												required={true}
												name="stepScreen"
												data={this.state.stepScreens}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"stepScreen"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
											></Select>
										</Col>

										<OnChange name="stepScreen">
											{(value, previous) => {
												if (value && value !== "") {
													let screen =
														this.state.stepScreens.find(
															(s) =>
																s.value ===
																value
														);

													if (
														screen?.Service_Step_Can_Reopen
													) {
														this.setState({
															showReExcuteDD: true,
														});
														this.getReExcuteTypes();
													} else {
														this.setState({
															showReExcuteDD: false,
														});
														delete values.reExcuteType;
													}
												} else {
													this.setState({
														showReExcuteDD: false,
													});
													delete values.reExcuteType;
												}
											}}
										</OnChange>

										{(values.notification === 1 ||
											values.notification === true) && (
											<Col xs={12} md={4}>
												<Select
													disabled={
														this.props.viewMood
													}
													label={this.props.t(
														"serviceNotification"
													)}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													}`}
													name="serviceNotification"
													data={
														this.state
															.serviceNotifications
													}
													showError={() => {
														let fieldStatus =
															form.getFieldState(
																"serviceNotification"
															);
														if (fieldStatus) {
															return (
																fieldStatus.submitFailed &&
																fieldStatus.invalid
															);
														}
													}}
												></Select>
											</Col>
										)}
									</Row>
								</Col>
								<Col md={5}>
									<Row>
										<Col
											xs={12}
											md={this.props.equalGrid ? 12 : 6}
										>
											<TextField
												disabled={this.props.viewMood}
												label={this.props.t(
													"stepAcceptTime"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
												required={true}
												name="stepAcceptTime"
												showError={({
													meta: {
														submitFailed,
														invalid,
													},
												}) => {
													return (
														submitFailed && invalid
													);
												}}
											/>
										</Col>

										<Col
											xs={12}
											md={this.props.equalGrid ? 12 : 6}
										>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"stepAcceptTimeType"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
												required={true}
												name="stepAcceptTimeType"
												data={this.state.timeTypes}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"stepAcceptTimeType"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
											></Select>
										</Col>

										{this.state.showReExcuteDD && (
											<Col
												xs={12}
												md={
													this.props.equalGrid
														? 12
														: 6
												}
												className={
													!this.props.equalGrid
														? "order-5"
														: ""
												}
											>
												<Select
													disabled={
														this.props.viewMood
													}
													label={this.props.t(
														"stepReExcuteType"
													)}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													}`}
													name="reExcuteType"
													data={
														this.state.reExcuteTypes
													}
													showError={() => {
														let fieldStatus =
															form.getFieldState(
																"reExcuteType"
															);

														if (fieldStatus) {
															return (
																fieldStatus.submitFailed &&
																fieldStatus.invalid
															);
														}
													}}
												></Select>
											</Col>
										)}

										<Col
											xs={12}
											md={this.props.equalGrid ? 12 : 6}
										>
											<TextField
												disabled={this.props.viewMood}
												label={this.props.t(
													"stepExcutionTime"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
												required={true}
												name="stepTime"
												showError={({
													meta: {
														submitFailed,
														invalid,
													},
												}) => {
													return (
														submitFailed && invalid
													);
												}}
											/>
										</Col>
										<Col
											xs={12}
											md={this.props.equalGrid ? 12 : 6}
										>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"stepExcutionTimeType"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
												required={true}
												name="stepTimeType"
												data={this.state.timeTypes}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"stepTimeType"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
											></Select>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={12}>
									<Row>
										<Col
											xs={12}
											md={3}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											}`}
										>
											<Switches
												disabled={this.props.viewMood}
												color="primary"
												name="notification"
												data={{
													label: `${this.props.t(
														"notification"
													)}`,
													value: `${this.props.t(
														"notification"
													)}`,
												}}
											/>
										</Col>

										<Col
											xs={12}
											md={2}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											}`}
										>
											<Switches
												disabled={this.props.viewMood}
												color="primary"
												name="internal"
												data={{
													label: `${this.props.t(
														"internal"
													)}`,
													value: `${this.props.t(
														"internal"
													)}`,
												}}
											/>
										</Col>
										<Col
											xs={12}
											md={2}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											}`}
										>
											<Switches
												disabled={this.props.viewMood}
												color="primary"
												name="skip"
												data={{
													label: `${this.props.t(
														"canSkip"
													)}`,
													value: `${this.props.t(
														"canSkip"
													)}`,
												}}
											/>
										</Col>
										<Col
											xs={12}
											md={3}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											}`}
										>
											<Switches
												disabled={this.props.viewMood}
												color="primary"
												name="Service_Step_Escalate_Level_1"
												data={{
													label: `${this.props.t(
														"EscalateLevel1"
													)}`,
													value: `${this.props.t(
														"EscalateLevel1"
													)}`,
												}}
											/>
										</Col>
										<Col
											xs={12}
											md={2}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											}`}
										>
											<Switches
												disabled={this.props.viewMood}
												color="primary"
												name="Service_Step_Escalate_Level_2"
												data={{
													label: `${this.props.t(
														"EscalateLevel2"
													)}`,
													value: `${this.props.t(
														"EscalateLevel2"
													)}`,
												}}
											/>
										</Col>
									</Row>
								</Col>

								<Col className="mb-4" xs={12}>
									<Row className="align-items-baseline">
										<Col className="mt-2 d-flex justify-content-end">
											{!this.props.viewMood && (
												<ButtonPrimary
													color="primary"
													variant="contained"
													disabled={
														submitting ||
														this.props.viewMood
													}
													type="submit"
												>
													{this.props.t("saveStep")}
												</ButtonPrimary>
											)}
										</Col>
									</Row>
								</Col>

								{this.state.createdStepId ||
								this.serviceIdRef ? (
									<Col md={12}>
										<div className="mt-2">
											<Divider />
										</div>

										<h5
											className={`w-40 my-4`}
											style={{
												textAlign:
													this.props.i18n.language ===
													"en"
														? "left"
														: "right",
											}}
										>
											{this.props.t(
												"servicesListOfEvents"
											)}
										</h5>

										{!this.props.viewMood && (
											<Col xs={12} className="mb-3">
												<ServiceInfoForm
													serviceid={
														this.props.serviceid
													}
													serviceStepId={
														this.state
															.createdStepId ||
														this.serviceIdRef
													}
													reloadDatagrid={() =>
														this.setState({
															reload: !this.state
																.reload,
														})
													}
												/>
											</Col>
										)}

										<DataGrid
											className={[
												this.props.i18n.language ===
												"ar"
													? "dx-grid-ar"
													: "dx-grid-en",
												"globalBox",
											].join(" ")}
											id="dataGrid"
											width={"100%"}
											dataSource={jsonDataSource}
											showBorders={false}
											showColumnLines={false}
											showRowLines={true}
											allowColumnResizing={true}
											columnResizingMode={"widget"}
											allowColumnReordering={true}
											rowAlternationEnabled={false}
											focusedRowEnabled={true}
											onExporting={this.onExporting}
											columnWidth={"auto"}
											wordWrapEnabled={true}
											rtlEnabled={
												this.props.i18n.language ===
												"ar"
													? true
													: false
											}
										>
											{/* <ColumnChooser enabled={true} allowSearch={true} search={'fff'}
                            title={this.props.t('dxColumnChooserTitle')}
                            emptyPanelText={this.props.t('dxColumnChooserPanelText')}
                        /> */}

											<SearchPanel
												visible={true}
												width={240}
												placeholder={this.props.t(
													"search"
												)}
											/>

											<FilterRow visible={true} />

											<HeaderFilter visible={true} />

											<Export
												enabled={true}
												allowExportSelectedData={true}
											/>

											<Grouping
												contextMenuEnabled={true}
												expandMode="rowClick"
											/>
											<GroupPanel
												visible={true}
												emptyPanelText={this.props.t(
													"dxGroupPanelText"
												)}
											/>

											<Selection
												mode="multiple"
												selectAllMode={"allPages"}
												showCheckBoxesMode={"always"}
											/>

											{/* <Column cssClass="outlinedColumn" fixed={true} fixedPosition={this.props.i18n.language === 'ar' ? 'right' : 'left'} alignment="center" width={'70px'} cellRender={this.renderOrderButtons}>
                            </Column> */}

											<Column
												dataField={ServiceEventNameKey}
												caption={this.props.t(
													"servicesEventExecution"
												)}
												alignment={columnAlign}
											></Column>

											<Column
												dataField={ServiceNameKey}
												caption={this.props.t(
													"servicesExecutionOrder"
												)}
												alignment={columnAlign}
											></Column>

											<Column
												cssClass="outlinedColumn nowrapColumn"
												type="buttons"
												caption={this.props.t("delete")}
												fixed={true}
												fixedPosition={
													this.props.i18n.language ===
													"ar"
														? "left"
														: "right"
												}
											>
												<Button
													render={(record) => {
														return (
															<ButtonDanger
																variant="circleIcon"
																startIcon={
																	<DeleteIcon />
																}
																onClick={() =>
																	this.handleDelete(
																		record.data
																	)
																}
															>
																{/* {this.props.t('delete')} */}
															</ButtonDanger>
														);
													}}
												/>
											</Column>
										</DataGrid>
									</Col>
								) : null}
							</form>
						);
					}}
				/>
			</>
		);
	}
	onExporting(e) {
		let sheetTitle = this.props.t("servicesListOfEvents");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
	reload,
})(translationHook(ServiceStepForm));
