import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import style from "./ReturnStep.module.scss";
import { ButtonPrimary, ButtonSecondary } from "../../../UI/Buttons";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { Select } from "mui-rff";
import { OnChange } from "react-final-form-listeners";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import FormControl from "@material-ui/core/FormControl";

class ReturnStep extends Component {
	state = {
		stepsList: [
			{
				value: 0,
				label: this.props.t("pleaseSelect"),
			},
		],
		userGroupList: [
			{
				value: 0,
				label: this.props.t("pleaseSelect"),
			},
		],
		returnStepId: 0,
		returnUserType: "1",
		userGroup: 0,
		returnBackReceiverType: "1",
		reExcute: 2,
	};

	getStepsList = async () => {
		let stepsArr = [],
			at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_RETURN_STEPS_LIST",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Return_Step_Name_AR
						: item.Return_Step_Name_EN;

				stepsArr.push({
					value: item.Return_Step_Id,
					label,
					Return_Step_ReExecute_Type_Id:
						item.Return_Step_ReExecute_Type_Id,
				});
			});

			this.setState({
				stepsList: [...this.state.stepsList, ...stepsArr],
			});
		}
	};

	getUserGroupList = async () => {
		let usersArr = [],
			at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark", 9999);

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_RETURN_STEP_USERS_LIST",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Return_Step_Id: this.state.returnStepId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			usersArr.push({
				value: 0,
				label: this.props.t("pleaseSelect"),
			});

			response.data.Data.map((user) => {
				let label =
					this.props.i18n.language === "ar"
						? user.User_Name_AR
						: user.User_Name_EN;

				usersArr.push({ value: user.User_Id, label });
			});
		}

		this.props.setLoading(false);

		return usersArr;
	};

	saveRequestTaskReturnStepNo = async (stepNo) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark", 9999);

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_RETURN_STEP_NO",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
				Return_Step_Id: stepNo,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
	};

	saveRequestTaskReturnStepUser = async (userId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark", 9999);

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_RETURN_STEP_USER",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
				Return_Step_Receiver_User_Id: userId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
	};

	saveRequestTaskReturnReceiverType = async (type) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark", 9999);

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_RETURN_STEP_RECEIVER_TYPE",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
				Return_Step_Receiver_Type_Id: type,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
	};

	saveRequestTaskBackReceiverType = async (type) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark", 9999);

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_BACK_STEP_RECEIVER_TYPE",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
				Back_Step_Receiver_Type_Id: type,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
	};

	saveRequestReExcuteIntermediateSteps = async (reExcuteType) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark", 9999);

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_REEXECUTE_INTERMEDIATE_STEPS",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
				ReExecute_Intermediate_Steps: reExcuteType ? 1 : 0,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
	};

	onReturnRequestStepSubmit = async (values, form) => {};

	componentDidMount() {
		this.getStepsList();
	}

	handleReturnStepSelect = async (e) => {
		//save return step id
		await this.saveRequestTaskReturnStepNo(e.target.value);

		if (this.state.returnUserType !== "1") {
			await this.saveRequestTaskReturnReceiverType("1");
		}

		if (this.state.returnBackReceiverType !== "1") {
			await this.saveRequestTaskBackReceiverType("1");
		}

		let screen = this.state.stepsList.find(
			(s) => s.value === e.target.value
		);

		this.setState({
			returnStepId: e.target.value,
			returnUserType: "1",
			returnBackReceiverType: "1",
			userGroup: 0,
			reExcute: screen?.Return_Step_ReExecute_Type_Id
				? screen.Return_Step_ReExecute_Type_Id
				: 2,
		});
	};

	handleUserGroupSelect = async (e) => {
		let userId = e.target.value;

		await this.saveRequestTaskReturnStepUser(userId);

		this.setState({ userGroup: userId });
	};

	handleReturnUserTypeChange = async (e) => {
		let usersList = [],
			type = e.target.value;

		if (type === "3") {
			usersList = await this.getUserGroupList();
		} else {
			this.setState({ userGroup: 0 });
		}

		await this.saveRequestTaskReturnReceiverType(type);

		this.setState({ returnUserType: type, userGroupList: usersList });
	};

	handleBackReceiverTypeChange = async (e) => {
		let type = e.target.value;

		await this.saveRequestTaskBackReceiverType(type);

		this.setState({ returnBackReceiverType: type });
	};

	render() {
		const validate = (values) => {
			const errors = {};

			return errors;
		};

		return (
			<>
				<Form
					onSubmit={this.onReturnRequestStepSubmit}
					initialValues={{
						returnStepId: this.state.returnStepId,
						userGroup: this.state.userGroup,
					}}
					validate={validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="returnStepForm"
							className="row"
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12} md={8}>
								<Row>
									<Col xs={12}>
										<Select
											label={this.props.t(
												"returnedRequestStep"
											)}
											className="mb-2"
											name="returnStepId"
											required={true}
											data={this.state.stepsList}
											value={this.state.returnStepId}
											showError={() => {
												let fieldStatus =
													form.getFieldState(
														"returnStepId"
													);

												if (fieldStatus) {
													return (
														fieldStatus.submitFailed &&
														fieldStatus.invalid
													);
												}
											}}
											onChange={
												this.handleReturnStepSelect
											}
										></Select>
									</Col>

									{this.state.returnStepId !== 0 && (
										<>
											<Col xs={12} md={8}>
												<FormControl className="mt-3">
													<label
														className={
															style.radioLabel
														}
													>
														{this.props.t(
															"returnTo"
														)}
													</label>
													<RadioGroup
														row
														name="receiverType"
														value={
															this.state
																.returnUserType
														}
														onChange={
															this
																.handleReturnUserTypeChange
														}
													>
														<FormControlLabel
															value="1"
															control={<Radio />}
															label={this.props.t(
																"sameStepUser"
															)}
														/>
														<FormControlLabel
															value="2"
															control={<Radio />}
															label={this.props.t(
																"returnToWorkGroup"
															)}
														/>
														<FormControlLabel
															value="3"
															control={<Radio />}
															label={this.props.t(
																"returnToNewUser"
															)}
														/>
													</RadioGroup>
												</FormControl>
											</Col>

											{this.state.returnUserType ===
												"3" && (
												<Col
													xs={12}
													md={6}
													className="mt-3"
												>
													<Select
														label={this.props.t(
															"userGroupUsers"
														)}
														className="mb-2"
														name="userGroup"
														required={true}
														data={
															this.state
																.userGroupList
														}
														showError={() => {
															let fieldStatus =
																form.getFieldState(
																	"userGroup"
																);

															if (fieldStatus) {
																return (
																	fieldStatus.submitFailed &&
																	fieldStatus.invalid
																);
															}
														}}
														onChange={
															this
																.handleUserGroupSelect
														}
													></Select>
												</Col>
											)}

											<Col xs={12} md={8}>
												<FormControl className="mt-3">
													<label
														className={
															style.radioLabel
														}
													>
														{this.props.t(
															"backReturnTo"
														)}
													</label>
													<RadioGroup
														row
														name="backReceiverType"
														value={
															this.state
																.returnBackReceiverType
														}
														onChange={
															this
																.handleBackReceiverTypeChange
														}
													>
														<FormControlLabel
															value="1"
															control={<Radio />}
															label={this.props.t(
																"returnToMeOnFinish"
															)}
														/>
														<FormControlLabel
															value="2"
															control={<Radio />}
															label={this.props.t(
																"returnToCurrentUserGroupOnFinish"
															)}
														/>
													</RadioGroup>
												</FormControl>
											</Col>

											<Col xs={12}>
												<input
													id="reExcute"
													type="checkbox"
													style={{
														width: "20px",
														height: "20px",
														accentColor: "#28a745",
														marginInlineEnd: "8px",
														cursor:
															this.state
																.reExcute ===
																1 ||
															this.state
																.reExcute === 3
																? "not-allowed"
																: "default",
													}}
													checked={
														this.state.reExcute ===
															3 ||
														this.state.reExcute ===
															2
													}
													disabled={
														this.state.reExcute ===
															1 ||
														this.state.reExcute ===
															3
													}
													onChange={(e) => {
														this.saveRequestReExcuteIntermediateSteps(
															e.target.checked
														);
													}}
												/>
												<label for="reExcute">
													{this.props.t(
														"reExcuteIntermediateSteps"
													)}
												</label>
											</Col>
										</>
									)}
								</Row>
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ReturnStep));
