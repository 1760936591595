import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import style from "./Login.module.scss";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import LanguageBtn from "./../UI/LanguageBtn/LanguageBtn";
import Footer from "./../UI/Footer/Footer";
import Sharjah from "./../../images/login/logos/Sharjah-Mun-Logo.svg";
import Strip from "./../../imagesTemp/login/logos/STRIP.png";
import NDAHeaderBG from "./../../images/login/background/nda-header-bg.jpg";
import NDAFooterBG from "./../../images/login/background/nda-footer-bg.jpg";
import Video from "./../../images/login/video.mp4";
import VideoStrip from "./../../imagesTemp/login/Sharjah-Research-Technology-and-Innovation-Park.mp4";
import VideoCover from "react-video-cover";
import LoginForm from "./LoginForm";
import moment from "moment";
import { Button } from "@material-ui/core";
import { userConfirmNDA } from "../../actions";

const NDA = ({ currentUser, userConfirmNDA }) => {
	const i18n = useTranslation("common"),
		activeTheme = document.body.classList;

	const [ndaChecked, setNdaChecked] = useState(false);

	const theme = createMuiTheme({
		typography: {
			fontFamily: ["Cairo", "sans-serif"].join(","),
		},
		palette: {
			primary: {
				main: "#000033",
			},
		},
	});

	// useEffect(() => {
	//     console.log(window.location.href);
	// }, [window.location.href])

	let mystyle;
	if (i18n.i18n.language === "ar") {
		mystyle = {
			// container: {
			// 	direction: "rtl",
			// 	textAlign: "right",
			// },
			whiteContainer: {
				paddingLeft: 0,
			},
			videoWrapParent: {
				borderTopLeftRadius: "20px",
				borderBottomLeftRadius: "20px",
			},
			videoWrap: {
				borderTopLeftRadius: "20px",
				borderBottomLeftRadius: "20px",
				height: "140%",
				transform: "translateY(-15%)",
			},
			alignRight: { direction: "rtl", textAlign: "right" },
		};
	} else {
		mystyle = {
			// container: {
			// 	textAlign: "left",
			// 	direction: "ltr",
			// },
			whiteContainer: {
				paddingRight: 0,
			},
			videoWrapParent: {
				borderTopRightRadius: "20px",
				borderBottomRightRadius: "20px",
			},
			videoWrap: {
				borderTopRightRadius: "20px",
				borderBottomRightRadius: "20px",
				height: "140%",
				transform: "translateY(-15%)",
			},
		};
	}

	const videoOptions = {
		src: process.env.REACT_APP_IMG_SRC == 2 ? VideoStrip : Video,
		autoPlay: true,
		loop: true,
		muted: true,
	};

	if (!currentUser) {
		return null;
	}

	return (
		<ThemeProvider theme={theme}>
			<Container className="my-5" style={mystyle.container}>
				<Row className={style.contentContainer}>
					<Col
						className={style.whiteContainer}
						lg={10}
						md={12}
						style={mystyle.whiteContainer}
					>
						<Row className="mx-0">
							<Col xs={12} className="py-3 text-center">
								<img
									className={style.ndaHeader}
									src={NDAHeaderBG}
								/>

								<h2 className={style.ngaTitle}>
									{i18n.t("stripServiceAgreement")}
								</h2>
							</Col>

							<Col xs={12} sm={6} className="py-3">
								<div className={style.ndaInfo}>
									<div>
										<p>Name: {currentUser.User_Name_EN}</p>
										<p>
											Job Title:{" "}
											{currentUser.Department_Job_Name_EN}
										</p>
										<p>
											Date:{" "}
											{moment(currentUser.issued).format(
												"DD/MM/YYYY"
											)}
										</p>
									</div>
								</div>
								<p>
									I{" "}
									<span className={style.ndaBoldHighlight}>
										({currentUser.User_Name_EN})
									</span>{" "}
									, of ( Sharjah Research Technology and
									Innovation Park Authority ) to strictly
									maintain the confidentiality of all paper
									and electronic information and secrets
									(official or unofficial) that reach to my
									work, have access to it, or may be entrusted
									with access to it as a result of my work in
									the Department and for any reason related to
									all its programs, database, secrets of its
									customers, addresses, accounts and any
									information of their own or related to
									management policy, future plans, operational
									information and related secrets that can be
									used to influence their reputation or
									activities directly or indirectly, and not
									to disclose any of these information or
									secrets to others in any way (direct -
									indirect). Therefore, I hereby acknowledge
									and agree to the following:
								</p>

								<ol>
									<li>
										To bear all legal responsibilities
										(disciplinary, and penalty) arising from
										disclosing any secret or disclosing any
										information that is not authorized to be
										disclosed.
									</li>

									<li>
										I commit not to have direct or indirect
										access to information and documents
										related to the department in which I
										work, except if the nature of my work
										requires that.
									</li>

									<li>
										I am committed to maintaining the
										paper\digital data or information, and I
										pledge not to enable others to access
										such data or information.
									</li>

									<li>
										I abide by the fact that this pledge is
										valid for the duration of my work and
										shall continue after the end of my term
										of service.
									</li>

									<li>
										I am fully aware that violating the
										undertaking to maintain confidentiality
										of information and exchange of data and
										information may result in legal
										penalties.
									</li>

									<li>
										This undertaking is considered an
										integral part of my employment contract,
										the Sharjah Research Technology And
										Innovation Park has the right to use it
										during and after the end of my service
										if it is proven that I did not comply
										with what is stated in it.
									</li>
								</ol>
							</Col>

							<Col
								xs={12}
								sm={6}
								className="py-3"
								style={{
									direction: "rtl",
									textAlign: "right",
								}}
							>
								<div className={style.ndaInfo}>
									<div>
										<p>الإسم: {currentUser.User_Name_AR}</p>
										<p>
											المسمى الوظيفي:{" "}
											{currentUser.Department_Job_Name_AR}
										</p>
										<p>
											التاريخ:{" "}
											{moment(currentUser.issued).format(
												"DD/MM/YYYY"
											)}
										</p>
									</div>
								</div>
								<p>
									أتعهد أنا{" "}
									<span className={style.ndaBoldHighlight}>
										({currentUser.User_Name_AR})
									</span>
									، في هيئة (مجمع الشارقة للبحوث والتكنولوجيا
									والابتكار) بأن أحافظ و بكل دقة على سرية جميع
									المعلومات الورقية و الإلكترونية والأسرار
									(رسمية أو غير رسمية) التي تصل إلى عملي أو
									أطلع عليها أو قد يعهد لي بالإطلاع عليها جراء
									عملي لأي سبب من الأسباب المتعلقة بجميع
									برامجها وقاعدة بياناتها وأسرار عملائهم
									وعناوينهم وحساباتهم وأي معلومات خاصة بهم أو
									متعلقة بسياسة الإدارة أو الخطط المستقبلية أو
									المعلومات التشغيلية والأسرار المتعلقة بها
									والتي يمكن أن تستغل في التأثير على سمعتها أو
									أنشطتها بشكل مباشر أو غير مباشر وعدم إفشاء
									أي من تلك المعلومات أو الأسرار للغير بأي شكل
									من الاشكال (مباشرة ـــ غير مباشرة). ولذا
									وبناء على ذلك فإني أقر و أوافق بالآتي:
								</p>

								<ol style={{ marginTop: "35px" }}>
									<li>
										أن أتحمل كافة المسؤوليات القانونية (
										التأديبية، والجزائية) المترتيبة على
										إفشاء أي سر من الأسرار أو الإفصاح بأية
										معلومات غير مصرح الإدلاء بها.
									</li>

									<li>
										أن ألتزم بعدم الاطلاع المباشر أو غير
										المباشر على المعلومات والمستندات
										المتعلقة بالإدارة التي أعمل بها باستثناء
										اذا كانت طبيعة عملي تقتضي ذلك.
									</li>

									<li>
										أن ألتزم بالمحافظة على ما بحوزتي من
										معلومات وبيانات ورقية ورقمية كما أتعهد
										بعدم تمكين الغير من الوصول إلى هذه
										البيانات أو المعلومات.
									</li>

									<li>
										أن ألتزم بأن يكون تعهدي هذا ساري طوال
										مدة عملي ويظل مستمرًا بعد انتهاء مدة
										خدمتي.
									</li>

									<li>
										علمي التام بأن انتهاك تعهد المحافظة على
										سرية المعلومات وتبادل البيانات
										والمعلومات قد يترتب عليه عقوبات وجزاءات
										قانونية.
									</li>

									<li>
										اعتبار هذا التعهد جزء لا يتجزأ من عقد
										العمل الخاص بي، يحق لمجمع الشارقة للبحوث
										والتكنولوجيا والابتكار استخدامه أثناء
										وبعد انتهاء خدمتي إذا ما ثبت عدم التزامي
										بما ورد فيه.
									</li>
								</ol>
							</Col>

							<Col
								xs={12}
								className="d-flex align-items-center"
								style={mystyle.alignRight}
							>
								<div className={style.ndaConfirm}>
									<input
										type="checkbox"
										id="confirmNda"
										name="confirmNda"
										onChange={(e) => {
											setNdaChecked(e.target.checked);
										}}
										checked={ndaChecked}
									/>
									<label for="confirmNda">
										{i18n.t("agreeNDA")}
									</label>
								</div>

								<Button
									className={style.signInBtn}
									variant="contained"
									type="submit"
									disabled={!ndaChecked}
									onClick={() => {
										userConfirmNDA();
									}}
								>
									{i18n.t("confirm")}
								</Button>
							</Col>

							<Col xs={12} className="py-3 text-center">
								<img
									className={style.ndaHeader}
									src={NDAFooterBG}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Footer />
			</Container>
		</ThemeProvider>
	);
};

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser.Data };
};

export default connect(mapStateToProps, { userConfirmNDA })(NDA);
