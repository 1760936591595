import apiConfig from "../apiConfig";

export const getLicensesList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/LIST/Get_Licenses_List",
		{
			PageSize: 100000,
			PageIndex: 1,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};

export const getInvestorsList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/REPORTS/Get_Investors_Report",
		{},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};

export const getLicensesDetails = async (at, id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/LIST/GET_LICENSE_INFO",
		{
			License_Id: id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};

export const getLicensesRisk = async (at, id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/MANAGEMENT/Get_License_Risk_Ratin",
		{
			License_Id: id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	if (response.data.Status) {
		return response.data.Data;
	}
	return null;
};

export const getLicenseVisasList = async (at, License_Id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/MANAGEMENT/Get_License_Visas_List",
		{
			License_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const getLicenseVisaDetails = async (
	at,
	License_Id,
	License_Visa_Id,
	License_Visa_Active_Status_Id
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/MANAGEMENT/Get_License_Visa_Details",
		{
			License_Id,
			License_Visa_Id,
			License_Visa_Active_Status_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const delLicenseVisa = async (at, License_Id, License_Visa_Id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/MANAGEMENT/Del_License_Visa",
		{
			License_Id,
			License_Visa_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const cancelLicenseVisa = async (at, License_Id, License_Visa_Id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/MANAGEMENT/Cancel_License_Visa",
		{
			License_Id,
			License_Visa_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};

export const reactivateLicenseVisa = async (
	at,
	License_Id,
	License_Visa_Id
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/MANAGEMENT/Reactivate_License_Visa",
		{
			License_Id,
			License_Visa_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
