import React, { Component } from "react";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { translationHook } from "../../translationHook";
import {
	triggerNotification,
	triggerDialogForm,
	setLoading,
} from "../../../actions";
import style from "../processing/Processing.module.scss";
import { getRequestInputChangesList } from "../../../apis/requests/getRequestInputChangesList";
import { downloadRequestAttachment } from "../../../apis/requests/info/downloadRequestAttachment";
import InputChangesList from "./InputChangesList";
import FilesUpload from "../../UI/FileUpload/UploadFile";

import DialogForm from "../../UI/DialogForm";

class RequestData extends Component {
	state = {
		showDialog: false,
		dialogData: null,
	};

	renderChangesList = (...params) => {
		return (
			<div className="p-3">
				<InputChangesList
					changesList={params[0]}
					isInputAttachment={params[1]}
				/>
			</div>
		);
	};

	handleGetRequestInputChangesList = async (
		Request_Id,
		Department_Service_Detail_Id
	) => {
		let changesList;
		this.props.setLoading(true, "dark");

		await getRequestInputChangesList(
			this.props.currentUser.Data.access_token,
			{
				Request_Id,
				Department_Service_Detail_Id,
			}
		)
			.then((res) => {
				changesList = res.data.Data;
			})
			.catch((error) => {
				this.props.setLoading(false);
				this.props.triggerNotification(true, error.response);
				return;
			});

		this.props.setLoading(false);
		return changesList;
	};

	render() {
		return (
			<div className={style.stepWrap}>
				{Object.keys(this.props.data).map((collection) => {
					let group = this.props.data[collection];

					return (
						<div className="col-12">
							<Accordion
								className={`${style.accordionWrap} py-4 mb-3`}
								key={`group_${group.groupId}`}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`group_${group.groupId}`}
									id={`group_${group.groupId}`}
								>
									{group?.groupTitle.AR && (
										<label
											className="ml-2"
											style={{
												fontSize: "14px",
												color: "#333",
											}}
										>
											{this.props.i18n.language === "ar"
												? group.groupTitle.AR
												: group.groupTitle.EN}
										</label>
									)}
								</AccordionSummary>

								<AccordionDetails
									className={style.accordionDetail}
								>
									<div className="row mx-0">
										{group?.items?.map((item) => {
											return (
												<div
													key={
														item.Department_Service_Detail_No
													}
													className={`${
														item.Department_Service_Detail_Type_Id ===
														4
															? "col-12"
															: "col-3"
													}`}
												>
													{Boolean(
														item.Service_Detail_Changed
													) && (
														// eslint-disable-next-line jsx-a11y/anchor-is-valid
														<a
															role="button"
															href="#"
															className={
																this.props.i18n
																	.language ===
																"ar"
																	? style.labelActionAr
																	: style.labelActionEn
															}
															onClick={async (
																e
															) => {
																e.preventDefault();

																await this.handleGetRequestInputChangesList(
																	item.Request_Id,
																	item.Department_Service_Detail_Id
																).then(
																	(data) => {
																		if (
																			data
																		) {
																			this.setState(
																				{
																					showDialog: true,
																					dialogData:
																						{
																							renderForm:
																								this
																									.renderChangesList,
																							params: [
																								data,
																								false,
																							],
																						},
																				}
																			);
																		}
																	}
																);
															}}
														>
															{this.props.t(
																"viewChangesList"
															)}
														</a>
													)}

													<label className="ml-2">
														{this.props.i18n
															.language === "ar"
															? item.Department_Service_Detail_Title_AR
															: item.Department_Service_Detail_Title_EN}
													</label>
													<p>
														{this.props.i18n
															.language === "ar"
															? item.Service_Detail_Value_AR
															: item.Service_Detail_Value_EN}
													</p>

													{item.Info_Btn_Show === 1 &&
														this.props.requestInfoButtonCallback(
															"showDetails",
															item.Info_Btn_Id,

															this.props.i18n
																.language ===
																"ar"
																? `حساب ${item.Service_Detail_Value_AR}`
																: `${item.Service_Detail_Value_EN} Acconut`
														)}
												</div>
											);
										})}

										{group?.attachments?.length > 0 && (
											<div className="col-12">
												<h5 className="my-4">
													{this.props.t(
														"requestAttachments"
													)}
												</h5>
												<FilesUpload
													requestId={
														this.props.requestId
													}
													downloadCB={
														downloadRequestAttachment
													}
													view={true}
													attachments={
														group.attachments
													}
													chunk={true}
													labelAction={
														this
															.handleGetRequestInputChangesList
													}
													labelActionCB={(data) => {
														this.props.triggerDialogForm(
															true,
															{
																title: this.props.t(
																	"viewChangesList"
																),
																renderForm:
																	this
																		.renderChangesList,
																params: [
																	data,
																	true,
																],
															}
														);
													}}
												/>
											</div>
										)}
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
					);
				})}

				<DialogForm
					custom={true}
					customDialogForm={{
						Open: this.state.showDialog || false,
						Data: this.state.dialogData,
						onClose: () => this.setState({ showDialog: false }),
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	triggerDialogForm,
	setLoading,
})(translationHook(RequestData));
