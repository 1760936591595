import apiConfig from "../../apiConfig";

export const deleteLicenseActivity = async (at, activityId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/ACTIVITIES/Del_License_Activity",
		{
			License_Activity_Id: activityId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
