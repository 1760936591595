import React, { Component } from "react";
import { translationHook } from "../../translationHook";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
	reload,
} from "../../../actions";
import { connect } from "react-redux";
import { getCardsList } from "./../../../apis/system/getCardsList";
import { deleteUser } from "./../../../apis/system/deleteUser";
import CustomStore from "devextreme/data/custom_store";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { setCardStatus } from "./../../../apis/system/setCardStatus";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";
import AvatarImg from "../../../images/avatar.png";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import apiConfig from "../../../apis/apiConfig";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import { ButtonWarning } from "./../../UI/Buttons";

class UsersList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			userAccountId = data.User_Account_Id,
			userName =
				this.props.i18n.language === "ar"
					? data.User_Name_AR
					: data.User_Name_EN,
			dialogTitle = `${this.props.t("confirmDeleteTitle")} ${userName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteUser,
				params: [at, userAccountId],
				text: this.props.t("deleteUser"),
			},
		});
	};

	imgPreview = ({ data }) => {
		return (
			<img
				src={data.User_Image === "" ? AvatarImg : data.User_Image}
				style={{ width: "50px", height: "50px", objectFit: "cover" }}
			/>
		);
	};
	loginName = ({ data }) => {
		return (
			<div class="d-flex justify-content-between align-content-center">
				{data.Login_Name}
				{data.AD_Ref && (
					<span className="bg-danger text-white p-1 rounded mx-2">
						AD
					</span>
				)}
			</div>
		);
	};
	emailRender = ({ data }) => {
		return (
			<div class="d-flex justify-content-between align-content-center">
				{data.User_Mail}
				{data.User_Mail_Verified === 1 && (
					<span className="bg-success text-white p-1 rounded mx-2">
						{this.props.t("verified")}
					</span>
				)}
			</div>
		);
	};

	phoneRender = ({ data }) => {
		return (
			<div class="d-flex justify-content-between align-content-center">
				{data.User_Phone}
				{data.User_Phone_Verified === 1 && (
					<span className="bg-success text-white p-1 rounded mx-2">
						{this.props.t("verified")}
					</span>
				)}
			</div>
		);
	};

	setOrderUp = async (cardId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		await apiConfig.post(
			"/API/BASIC/SETTINGS/DASHBOARD_CARDS/SET_DASHBOARD_CARD_ORDER_UP",
			{
				Dashboard_Card_Id: cardId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	setOrderDown = async (cardId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		await apiConfig.post(
			"/API/BASIC/SETTINGS/DASHBOARD_CARDS/SET_DASHBOARD_CARD_ORDER_DOWN",
			{
				Dashboard_Card_Id: cardId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	customizeCellCheckbox = ({ data }) => {
		// let label = this.props.i18n.language === 'ar' ? data.Active_Status_Name_AR : data.Active_Status_Name_EN
		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Dashboard_Card_Active_Status_Id}
						name="userStatus"
						onChange={async () => {
							let at = this.props.currentUser.Data.access_token,
								cardId = data.Dashboard_Card_Id,
								status = data.Dashboard_Card_Active_Status_Id
									? 0
									: 1;

							this.props.setLoading(true, "dark");

							const response = await setCardStatus(
								at,
								cardId,
								status
							);

							this.props.triggerNotification(true, response);

							this.props.setLoading(false);
						}}
					/>
				}
				// label={label}
			/>
		);
	};

	renderOrderButtons = ({ data }) => {
		return (
			<div className="d-flex flex-column align-items-center order-btns">
				<IconButton
					onClick={() => this.setOrderUp(data.Dashboard_Card_Id)}
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<IconButton
					onClick={() => this.setOrderDown(data.Dashboard_Card_Id)}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</div>
		);
	};

	render() {
		let columnAlign = "left",
			CardNameKey = "Dashboard_Card_Name_EN",
			CardTypeNameKey = "Dashboard_Card_Type_Name_EN",
			CardDescKey = "Dashboard_Card_Description_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			CardNameKey = "Dashboard_Card_Name_AR";
			CardTypeNameKey = "Dashboard_Card_Type_Name_AR";
			CardDescKey = "Dashboard_Card_Description_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Dashboard_Card_Id",
			load: () => {
				return getCardsList(at);
			},
		});

		return (
			<>
				<div className="d-flex justify-content-between align-items-center pb-5">
					<h3>{this.props.t("cardSettings")}</h3>
				</div>

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						cssClass="outlinedColumn"
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "right" : "left"
						}
						alignment="center"
						cellRender={this.renderOrderButtons}
						width={"70px"}
					></Column>

					<Column
						dataField="Dashboard_Card_No"
						caption={this.props.t("cardRank")}
						alignment="center"
						// cellRender={this.imgPreview}
						width={120}
					></Column>

					<Column
						dataField={CardNameKey}
						caption={this.props.t("cardName")}
						alignment={columnAlign}
					></Column>
					<Column
						cssClass="outlinedColumn"
						dataField={CardTypeNameKey}
						caption={this.props.t("cardType")}
						alignment={columnAlign}
						// cellRender={this.loginName}
					></Column>
					{/* <Column 
                        dataField='User_Mail' 
                        caption={this.props.t('mail')}
                        alignment={columnAlign}
                        cellRender={this.emailRender}
                    >
                    </Column> */}
					{/* <Column
                        cssClass="outlinedColumn" 
                        dataField='User_Phone' 
                        caption={this.props.t('phone')}
                        alignment={'center'}
                        cellRender={this.phoneRender}
                        width={220}
                    >
                    </Column> */}
					<Column
						cssClass="outlinedColumn"
						caption={this.props.t("cardStatus")}
						allowFiltering={false}
						alignment="center"
						cellRender={this.customizeCellCheckbox}
						width={100}
					></Column>
					<Column
						dataField={CardDescKey}
						caption={this.props.t("cardDesc")}
						alignment={columnAlign}
						width={280}
					></Column>
					{/* <Column 
                        dataField={UserTypeNameKey} 
                        caption={this.props.t('userTypeName')}
                        alignment={columnAlign}
                        visible={false}
                    >
                    </Column> */}

					{/* <Column cssClass="outlinedColumn"
                        dataField={UserGroupNameKey} 
                        caption={this.props.t('userGroupName')}
                        alignment={'center'}
                        visible={false}
                    >
                    </Column>

                    <Column 
                        dataField={DepartmentNameKey} 
                        caption={this.props.t('departmentName')}
                        alignment={columnAlign}
                        visible={false}
                    >
                    </Column>
                    <Column 
                        dataField={DepartmentJobKey} 
                        caption={this.props.t('departmentJob')}
                        alignment={columnAlign}
                        visible={false}
                    >
                    </Column> */}

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
										variant="circleIcon"
										startIcon={<EditIcon />}
										onClick={() => {
											// this.props.triggerView(false, true)

											this.props.handleEdit(record.data);
										}}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("cardSettings");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
	reload,
})(translationHook(UsersList));
