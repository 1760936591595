import React, { useState, useEffect } from "react";

import RiskRatingStep from "./RiskRatingStep";
import RiskFactorInfo from "./RiskFactorInfo";
import Notes from "../Notes";
import { saveRequestRiskRating } from "../../../../apis/requests/processTask";

export const RiskRating = (props) => {
	const [riskValues, setRiskValues] = useState({});

	return (
		<>
			<h1 className="mb-5">
				{riskValues?.licenseName}
				<small
					style={{
						fontSize: "60%",
						color: "#00b1eb",
						marginInline: "10px",
					}}
				>
					{riskValues?.licenseNo}
				</small>
			</h1>

			<RiskRatingStep
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				task={props.task}
				setValuesCB={setRiskValues}
			/>

			{!!Object.keys(riskValues).length && (
				<RiskFactorInfo
					riskInfo={riskValues}
					requestId={props.requestId}
					requestTaskId={props.requestTaskId}
					setValuesCB={setRiskValues}
				/>
			)}

			<Notes
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				onTaskSubmit={(at, data) => {
					return saveRequestRiskRating(at, {
						...data,
						License_Risk_Rating_Result_Id: riskValues.Risk_Rate_Id,
					});
				}}
			/>
		</>
	);
};
