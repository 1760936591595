import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { Link } from "@reach/router";
import moment from "moment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import { ButtonSecondary } from "../../UI/Buttons";
import { DataGrid, Column } from "devextreme-react/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";
import FilesUpload from "../../UI/FileUpload/UploadFile";

import { getLicensesRisk } from "../../../apis/licenses/LicensesList";
import RiskRatingStep from "../../requests/processing/riskRating/RiskRatingStep";

class LicenseRisk extends Component {
	state = {
		riskRating: {},
	};

	componentDidMount() {
		let licenseId = this.props.licenseId,
			auth = this.props.currentUser.Data.access_token;

		if (licenseId) {
			(async () => {
				const licenseDetails = await getLicensesRisk(auth, licenseId);

				this.setState({ riskRating: licenseDetails });
			})();
		}
	}

	onSubmit = async (values) => {};

	handleAttachmentDownload = ({ data }) => {
		return (
			<GetAppIcon
				style={{ width: "100%", cursor: "pointer" }}
				onClick={async () => {
					let params = {
						at: this.props.currentUser.Data.access_token,
						File_Code: data.License_Attachment_File_Code,
					};
					const downloadResult = await downloadAttachment(params);
					const url = window.URL.createObjectURL(
						new Blob([downloadResult.data], {
							type: "application/pdf",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("target", "_blank");
					link.click();
				}}
			/>
		);
	};

	render() {
		return (
			<>
				{this.props.standalone && (
					<div className="d-flex justify-content-end mb-3">
						<ButtonSecondary
							variant="outlined"
							endIcon={<ExitToAppIcon />}
							component={Link}
							to="/manage-risks"
							size="large"
						>
							{this.props.t("exitToLicenselist")}
						</ButtonSecondary>
					</div>
				)}

				{this.state.riskRating ? (
					<>
						{this.state.riskRating?.Risk_Rating_Calculation
							?.length && (
							<RiskRatingStep
								riskRatingCalculations={
									this.state.riskRating
										.Risk_Rating_Calculation
								}
							/>
						)}

						{this.state.riskRating.Calculate_Risk_Rate_Id && (
							<div className="row mt-3">
								<Col xs={6}>
									{this.props.t("calculateRiskRating")}
									<div
										style={{
											backgroundColor:
												this.state.riskRating
													?.Calculate_Risk_Rate_Color,
											color: "#fff",
											padding: "8px",
											borderRadius: "4px",
											textAlign: "center",
											// maxWidth: "200px",
											width: "100%",
											marginTop: "10px",
										}}
									>
										{this.props.i18n.language === "ar"
											? this.state.riskRating
													.Calculate_Risk_Rate_Name_AR
											: this.state.riskRating
													.Calculate_Risk_Rate_Name_EN}
									</div>
								</Col>
							</div>
						)}

						{this.state.riskRating.DNFBP_Activities_Rating && (
							<Col xs={12}>
								<Form
									subscription={{ submitting: true }}
									onSubmit={() => {}}
									// validate={validate}
									render={({
										handleSubmit,
										form,
										submitting,
									}) => (
										<form
											className="row mt-5"
											onSubmit={handleSubmit}
											noValidate
										>
											<Col xs={12} md={6}>
												<TextField
													disabled
													label={this.props.t(
														"DNFBPactivities"
													)}
													className={`read-only mb-4 ${
														Boolean(
															this.state
																.riskRating
																?.DNFBP_Activities_Rating
																?.DNFBP_Activities_Status_Id
														)
															? "input-text-danger"
															: ""
													}`}
													name="dnfbpActivitiesAR"
													value={
														this.props.i18n
															.language === "ar"
															? this.state
																	.riskRating
																	?.DNFBP_Activities_Rating
																	?.DNFBP_Activities_Status_Name_AR
															: this.state
																	.riskRating
																	?.DNFBP_Activities_Rating
																	?.DNFBP_Activities_Status_Name_EN
													}
												/>
											</Col>

											<Col xs={12} md={6}>
												<TextField
													disabled
													label={this.props.t(
														"ESRactivities"
													)}
													className={`read-only mb-4 ${
														Boolean(
															this.state
																.riskRating
																?.ESR_Activities_Rating
																?.ESR_Activities_Status_Id
														)
															? "input-text-danger"
															: ""
													}`}
													name="esrActivitiesAR"
													value={
														this.props.i18n
															.language === "ar"
															? this.state
																	.riskRating
																	?.ESR_Activities_Rating
																	?.ESR_Activities_Status_Name_AR
															: this.state
																	.riskRating
																	?.ESR_Activities_Rating
																	?.ESR_Activities_Status_Name_EN
													}
												/>
											</Col>
										</form>
									)}
								/>
							</Col>
						)}

						<Row>
							{this.state.riskRating?.WorldCheck_Risk_Rating
								?.WorldCheck_Files.length ? (
								<Col xs={12} md={6}>
									<h5 className="my-4">
										{this.props.t("worldcheckAttachments")}
									</h5>
									<FilesUpload
										downloadCB={downloadAttachment}
										view={true}
										attachments={[
											{
												Files_Data:
													this.state.riskRating
														?.WorldCheck_Risk_Rating
														?.WorldCheck_Files,
											},
										]}
									/>
								</Col>
							) : null}

							{this.state.riskRating?.Interview_Risk_Rating
								?.Interview_Files.length ? (
								<Col xs={12} md={6}>
									<h5 className="my-4">
										{this.props.t("interviewAttachments")}
									</h5>
									<FilesUpload
										downloadCB={downloadAttachment}
										view={true}
										attachments={[
											{
												Files_Data:
													this.state.riskRating
														?.Interview_Risk_Rating
														?.Interview_Files,
											},
										]}
									/>
								</Col>
							) : null}

							{this.state.riskRating?.WorldCheck_Risk_Rating
								?.WorldCheck_Risk_Rate_Id && (
								<Col xs={12} className="my-3">
									<div className="row">
										<Col xs={6}>
											{this.props.t(
												"worldcheckRiskResult"
											)}
											<div
												style={{
													backgroundColor:
														this.state.riskRating
															?.WorldCheck_Risk_Rating
															?.WorldCheck_Risk_Rate_Color,
													color: "#fff",
													padding: "8px",
													borderRadius: "4px",
													textAlign: "center",
													// maxWidth: "200px",
													width: "100%",
													marginTop: "10px",
												}}
											>
												{this.props.i18n.language ===
												"ar"
													? this.state.riskRating
															?.WorldCheck_Risk_Rating
															?.WorldCheck_Risk_Rate_Name_AR
													: this.state.riskRating
															?.WorldCheck_Risk_Rating
															?.WorldCheck_Risk_Rate_Name_EN}
											</div>
										</Col>
									</div>
								</Col>
							)}

							{this.state.riskRating.System_Risk_Rate_Id && (
								<Col xs={12} className="my-3">
									<div className="row">
										<Col xs={6}>
											{this.props.t("systemRiskRating")}
											<div
												style={{
													backgroundColor:
														this.state.riskRating
															?.System_Risk_Rate_Color,
													color: "#fff",
													padding: "8px",
													borderRadius: "4px",
													textAlign: "center",
													// maxWidth: "200px",
													width: "100%",
													marginTop: "10px",
												}}
											>
												{this.props.i18n.language ===
												"ar"
													? this.state.riskRating
															.System_Risk_Rate_Name_AR
													: this.state.riskRating
															.System_Risk_Rate_Name_EN}
											</div>
										</Col>
									</div>
								</Col>
							)}

							{this.state.riskRating?.Risk_Rate_Id && (
								<Col xs={12} className="my-3">
									<div className="row">
										<Col xs={6}>
											{this.props.t(
												"globalCompanyRiskRating"
											)}
											<div
												style={{
													backgroundColor:
														this.state.riskRating
															?.Risk_Rate_Color,
													color: "#fff",
													padding: "8px",
													borderRadius: "4px",
													textAlign: "center",
													// maxWidth: "200px",
													width: "100%",
													marginTop: "10px",
												}}
											>
												{this.props.i18n.language ===
												"ar"
													? this.state.riskRating
															?.Risk_Rate_Name_AR
													: this.state.riskRating
															?.Risk_Rate_Name_EN}
											</div>
										</Col>
									</div>
								</Col>
							)}
						</Row>
					</>
				) : (
					<h3
						style={{
							minHeight: "300px",
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{this.props.t("noData")}
					</h3>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
})(translationHook(LicenseRisk));
