import apiConfig from "../../apiConfig";

export const refreshRequestManagmentInfo = async (
	at,
	requestId,
	requestTaskId
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/REFRESH_REQUESTS_MANAGEMENT_INFO",
		{
			Request_Id: requestId,
			Request_Task_Id: requestTaskId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
