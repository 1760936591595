import apiConfig from "../../apiConfig";

export const deleteActivityGroup = async (at, groupId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/ACTIVITIES/Del_License_Activity_Group",
		{
			License_Activity_Group_Id: groupId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
