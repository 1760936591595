import React, { Component } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DepartmentForm from "./DepartmentForm";

import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../actions";
import { getDepartmentsList } from "../../apis/departments/getDepartmentsList";
import { changeDepartmentStatus } from "../../apis/departments/changeDepartmentStatus";
import { triggerDialog } from "../../actions";
import { translationHook } from "../translationHook";
import {
	ButtonPrimary,
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from "../UI/Buttons";
import { Center } from "devextreme-react/map";

class DepartmentsList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		view: false,
	};

	renderEditForm = (...params) => {
		let viewMood = params[2] === "view" ? true : false,
			editMood = params[2] === "edit" ? true : false;
		return (
			<DepartmentForm
				departmentId={params[0]}
				initValues={params[1]}
				viewMood={viewMood}
				editMood={editMood}
			/>
		);
	};

	handleEdit = (data, viewMood) => {
		let DepartmentId = data.Department_Id,
			initValues = data,
			departmentTitle =
				this.props.i18n.language === "ar"
					? data.Department_Name_AR
					: data.Department_Name_EN,
			dialogTitle = `${
				viewMood === "view"
					? this.props.t("view")
					: this.props.t("edit")
			}  ${departmentTitle}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderEditForm,
			params: [DepartmentId, initValues, viewMood],
		});
	};
	customizeCellCheckbox = ({ data }) => {
		let label =
			this.props.i18n.language === "ar"
				? data.Active_Status_Name_AR
				: data.Active_Status_Name_EN;
		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Active_Status_Id}
						name={`departmentStatus_${data.Department_Id}`}
						onChange={async () => {
							let at = this.props.currentUser.Data.access_token,
								departmentId = data.Department_Id,
								status = data.Active_Status_Id ? 0 : 1;

							this.props.setLoading(true, "dark");

							const response = await changeDepartmentStatus(
								at,
								departmentId,
								status
							);

							this.props.triggerNotification(true, response);

							this.props.setLoading(false);
						}}
					/>
				}
				label={label}
			/>
		);
	};
	render() {
		let columnAlign = "left",
			DepNameKey = "Department_Name_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			DepNameKey = "Department_Name_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Department_Id",
			load: () => {
				return getDepartmentsList(at);
			},
		});

		return (
			<>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h3>{this.props.t("departments")}</h3>
				</div>

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						dataField={DepNameKey}
						caption={this.props.t("DepartmentName")}
						alignment={columnAlign}
					></Column>
					<Column
						dataField={"Department_Code"}
						caption={this.props.t("DepartmentCode")}
						alignment={"center"}
					></Column>

					<Column
						dataField={"Active_Status_Id"}
						caption={this.props.t("serviceStatus")}
						cellRender={this.customizeCellCheckbox}
					></Column>
					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
										variant="circleIcon"
										startIcon={<EditIcon />}
										onClick={() => {
											// this.setState({
											// 	view: false
											// })
											this.handleEdit(
												record.data,
												"edit"
											);
										}}
									></ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
										variant="circleIcon"
										onClick={() => {
											// this.setState({
											// 	view: true
											// })
											this.handleEdit(
												record.data,
												"view"
											);
										}}
										startIcon={<VisibilityIcon />}
									></ButtonInfo>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("manageDepartments");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(DepartmentsList));
