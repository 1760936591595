import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import DateBox from "devextreme-react/date-box";
import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

import { getAllSuspensionsList } from "../../apis/violations";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../actions";
import { translationHook } from "../translationHook";
import { ButtonInfo, ButtonWarning } from "../UI/Buttons";

import SuspensionForm from "./SuspensionForm";

class AllSuspensionsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDateStart: moment().date(-7).format("yyyy-MM-DD"),
			selectedDateEnd: moment().format("yyyy-MM-DD"),
		};
		this.onExporting = this.onExporting.bind(this);
	}

	renderForm = (...params) => {
		let viewMood = params[2] === "view" ? true : false,
			editMood = params[2] === "edit" ? true : false;

		return (
			<div className="p-5">
				<SuspensionForm
					violationId={params[0]}
					initValues={params[1]}
					editMood={editMood}
					viewMood={viewMood}
					label={params[3]}
				/>
			</div>
		);
	};

	handleEdit = (data, viewMood) => {
		let label = "";

		if (this.props.i18n.language === "ar") {
			label = data.Client_Name_AR ? data.Client_Name_AR : "";
			label = data.Dealing_Type_Name_AR
				? label + " - " + data.Dealing_Type_Name_AR
				: label;
		} else {
			label = data.Client_Name_EN ? data.Client_Name_EN : "";
			label = data.Dealing_Type_Name_EN
				? label + " - " + data.Dealing_Type_Name_EN
				: label;
		}

		label = data.License_No ? label + " - " + data.License_No : label;

		let initValues = {
				Client_Profile_Id: data.Client_Profile_Id,
				Violation_Notes: data.Violation_Notes,
			},
			violationId = data.Violation_Id,
			clientName =
				this.props.i18n.language === "ar"
					? data.Client_Name_AR
					: data.Client_Name_EN,
			dialogTitle = `${
				viewMood === "view"
					? this.props.t("view")
					: this.props.t("edit")
			} ${this.props.t("suspension")} ${clientName}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderForm,
			params: [violationId, initValues, viewMood, label],
		});
	};

	render() {
		let columnAlign = "left",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "Violation_Id",
			load: () => {
				return getAllSuspensionsList(at);
			},
		});

		return (
			<>
				{/* <div className="row align-items-center mb-5">
                    <div className="col-md-3">
                        <label>{t('fromDate')}</label>
                        <DateBox
                        acceptCustomValue={false}
                        defaultValue={selectedDateStart}
                        dateSerializationFormat="yyyy-MM-dd"
                        displayFormat="yyyy-MM-dd"
                        onValueChanged={(e) => { this.setState({selectedDateStart: e.value}) }}
                        type="date"
                        />
                    </div>

                    <div className="col-md-3">
                        <label>{t('toDate')}</label>
                        <DateBox
                        acceptCustomValue={false}
                        defaultValue={selectedDateEnd}
                        dateSerializationFormat="yyyy-MM-dd"
                        displayFormat="yyyy-MM-dd"
                        onValueChanged={(e) => { this.setState({selectedDateEnd: e.value}) }}
                        type="date"
                        />
                    </div>
                </div> */}

				<div className="d-flex justify-content-between align-items-center pb-5">
					<h3>{this.props.t("suspensionsList")}</h3>
				</div>

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="allViolationsList"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						dataField="Violation_Date"
						caption={this.props.t("suspensionDate")}
						alignment={columnAlign}
						calculateCellValue={(data) =>
							moment(data.Violation_Date).format("YYYY-MM-DD")
						}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Client_Name_AR"
								: "Client_Name_EN"
						}
						caption={this.props.t("clientName")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Dealing_Type_Name_AR"
								: "Dealing_Type_Name_EN"
						}
						caption={this.props.t("clientType")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Violations_Status_Name_AR"
								: "Violations_Status_Name_EN"
						}
						caption={this.props.t("suspensionStatus")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Department_Name_AR"
								: "Department_Name_EN"
						}
						caption={this.props.t("suspensionDepartment")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Ins_User_Name_AR"
								: "Ins_User_Name_EN"
						}
						caption={this.props.t("departmentUser")}
						alignment={columnAlign}
					></Column>

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonInfo
										variant="circleIcon"
										startIcon={<VisibilityIcon />}
										onClick={() => {
											this.handleEdit(
												record.data,
												"view"
											);
										}}
									></ButtonInfo>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("ServicesCategories");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
})(translationHook(AllSuspensionsList));
