import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import apiConfig from "../../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LanguageIcon from "@material-ui/icons/Language";

import { getDepartmentServiceVersionsList } from "../../../apis/services/departments/getDepartmentServiceVersionsList";
import {
	triggerDialog,
	triggerNotification,
	setLoading,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import {
	ButtonPrimary,
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
	ButtonPurple,
} from "../../UI/Buttons";

class DepartmentServiceVersionsList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		canAdd: false,
		canImport: false,
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			versionId = data.Department_Service_Version_Id,
			versionName = data.Department_Service_Version_Name,
			serviceName =
				this.props.i18n.language === "ar"
					? data.Service_Name_AR
					: data.Service_Name_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${serviceName} ${this.props.t("version")} ${versionName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: this.deleteVersion,
				params: [at, versionId],
				text: this.props.t("deleteVersion"),
			},
		});
	};

	canAddImportVersion = async () => {
		let at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/CAN_ADD_VERSION",
			{
				Department_Service_Id:
					this.props.location.state.departmentServiceId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			let canAdd = response.data.Data.Can_Add_New === 1 ? true : false,
				canImport = response.data.Data.Can_Import === 1 ? true : false;

			this.setState({
				canAdd,
				canImport,
			});
		}
	};

	setVersionOnline = async (versionId) => {
		let at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SET_VERSION_ONLINE?=",
			{
				Department_Service_Version_Id: versionId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.triggerNotification(true, response.data);
	};

	deleteVersion = async (at, versionId) => {
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/DEL_VERSION",
			{
				Department_Service_Version_Id: versionId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.canAddImportVersion();
	};

	importVersion = async () => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/ADD_VERSION_FROM_CURRENT_VERSION",
				{
					Department_Service_Id:
						this.props.location.state.departmentServiceId,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then(() => {
				this.canAddImportVersion();
			})
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);
		this.props.triggerNotification(true, response.data);
	};

	componentDidMount() {
		this.canAddImportVersion();
	}

	render() {
		let columnAlign = "left",
			DepartmentSectionNameKey = "Department_Section_Name_AR",
			DepartmentVersionStatusKey =
				"Department_Service_Version_Status_Name_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			DepartmentSectionNameKey = "Department_Section_Name_AR";
			DepartmentVersionStatusKey =
				"Department_Service_Version_Status_Name_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Department_Service_Version_Id",
			load: () => {
				return getDepartmentServiceVersionsList(
					at,
					this.props.location.state.departmentServiceId
				);
			},
		});

		return (
			<>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h3>{`${this.props.t("manageServiceVersions")} ${
						this.props.serviceName
					}`}</h3>
					<ButtonSecondary
						variant="outlined"
						endIcon={<ExitToAppIcon />}
						component={Link}
						to="/services/foundation-services"
						size="large"
					>
						{this.props.t("exitToServices")}
					</ButtonSecondary>
				</div>

				{(this.state.canAdd || this.state.canImport) && (
					<div className="d-flex justify-content-start align-items-center mb-5 pt-5">
						{this.state.canAdd && (
							<ButtonPrimary
								component={Link}
								to="add-version"
								size="large"
								state={{
									departmentServiceId:
										this.props.location.state
											.departmentServiceId,
								}}
							>
								{this.props.t("addNewVersion")}
							</ButtonPrimary>
						)}
						{this.state.canImport && (
							<ButtonSecondary
								size="large"
								onClick={this.importVersion}
							>
								{this.props.t("importCurrentVersion")}
							</ButtonSecondary>
						)}
					</div>
				)}

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						dataField="Department_Service_Version_No"
						caption={this.props.t("versionNo")}
						alignment={"center"}
					></Column>

					<Column
						dataField="Department_Service_Version_Name"
						caption={this.props.t("versionCode")}
						alignment={"center"}
						cssClass="outlinedColumn"
					></Column>

					<Column
						dataField={DepartmentSectionNameKey}
						caption={this.props.t("serviceCat")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={DepartmentVersionStatusKey}
						caption={this.props.t("serviceStatus")}
						alignment={"center"}
					></Column>

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							visible={({ row }) => {
								return row.data.Can_Make_Version_Online === 1
									? true
									: false;
							}}
							render={(record) => {
								return (
									<ButtonPurple
										variant="circleIcon"
										startIcon={<LanguageIcon />}
										onClick={() => {
											this.setVersionOnline(
												record.data
													.Department_Service_Version_Id
											);
										}}
									>
										{/* {this.props.t('goOnline')} */}
									</ButtonPurple>
								);
							}}
						/>

						<Button
							visible={({ row }) => {
								return row.data.Can_Edit_Version === 1
									? true
									: false;
							}}
							render={(record) => {
								return (
									<ButtonWarning
										variant="circleIcon"
										startIcon={<EditIcon />}
										component={Link}
										state={{
											departmentServiceId:
												this.props.location.state
													.departmentServiceId,
										}}
										to={`/services/foundation-services/${encodeURIComponent(
											this.props.serviceName
										)}/versions/edit-version/${
											record.data
												.Department_Service_Version_Id
										}`}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							visible={({ row }) => {
								return row.data.Can_Review_Version === 1
									? true
									: false;
							}}
							render={(record) => {
								return (
									<ButtonInfo
										variant="circleIcon"
										startIcon={<VisibilityIcon />}
										component={Link}
										state={{
											departmentServiceId:
												this.props.location.state
													.departmentServiceId,
										}}
										to={`/services/foundation-services/${encodeURIComponent(
											this.props.serviceName
										)}/versions/edit-version/${
											record.data
												.Department_Service_Version_Id
										}/#view`}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>

						<Button
							visible={({ row }) => {
								return row.data.Can_Delete_Version === 1
									? true
									: false;
							}}
							render={(record) => {
								return (
									<ButtonDanger
										variant="circleIcon"
										startIcon={<DeleteIcon />}
										onClick={() =>
											this.handleDelete(record.data)
										}
									>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("manageServiceVersions");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerNotification,
	setLoading,
})(translationHook(DepartmentServiceVersionsList));
