import React, { useState } from 'react'
import style from './Tasks.module.scss'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { useTranslation } from 'react-i18next'

const Tasks = () => {

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const { t, i18n } = useTranslation('common')

    let tranStyle
    if (i18n.language === 'en') {
        tranStyle = {
            textAlign: 'row'
        }
    } else {
        tranStyle = {
            flexDirection: 'row-reverse'
        }
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }  

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <button
                className={style.dropdownBtn}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
            >
                <div>
                    <span className={[style.tasksSvg, 'svg-lg'].join(' ')}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <title>Stockholm-icons / General / Clipboard</title>
                            <desc>Created with Sketch.</desc>
                            <defs></defs>
                            <g id="Stockholm-icons-/-General-/-Clipboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" id="Combined-Shape" fill="#000000"></path>
                                <rect id="Rectangle-152" fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"></rect>
                                <rect id="Rectangle-152-Copy" fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"></rect>
                            </g>
                        </svg>
                    </span>
                </div>
            </button>
        
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                onClose={handleClose}
                className={[style.dropdown, 'mt-2'].join(' ')}
            >
                <MenuItem className={[style.menuItem, style.userTasks].join(' ')} style={tranStyle}>Your Tasks</MenuItem>
                <MenuItem className={[style.menuItem, style.newTask].join(' ')} style={tranStyle} onClick={handleClose}>Tasks 1</MenuItem>
                <MenuItem className={style.newTask} onClick={handleClose} style={tranStyle}>Tasks 2</MenuItem>
                <MenuItem className={style.newTask} onClick={handleClose} style={tranStyle}>Tasks 3</MenuItem>
                <MenuItem className={style.tasksPageBtn} onClick={handleClose} style={tranStyle}>See More</MenuItem>
            </Menu>
        </div>
    )
}

export default Tasks
