import React, { useState, useEffect } from "react";

import ApproveLicenseName from "./ApproveLicenseName";
import Notes from "../Notes";
import {
	escalateRequestStep,
	saveRequestLicenseNameSelection,
	sendRequestLicenseNameSelection,
	sendRequestLicenseNameCheckSpilling,
} from "../../../../apis/requests/processTask";

export const ApproveLicenseNameStep = (props) => {
	const [licenseNameSelectionValues, setLicenseNameSelectionValues] =
			useState({}),
		[checkNameSpillingUser, setNameCheckSpillingUser] = useState(null),
		[filteredValues, setFilteredValues] = useState({});

	// // const filterInvestorValue = (data) => {
	// // 	let modifiedList = [],
	// // 		copyData = data;

	// // 	copyData?.Investors_Names_List?.forEach((item, i) => {
	// // 		delete item.Old_Lic_Name_AR;
	// // 		delete item.Old_Lic_Name_EN;

	// // 		modifiedList.push(item);
	// // 	});

	// // 	return { Investors_Names_List: modifiedList };
	// // };

	useEffect(() => {
		console.log(licenseNameSelectionValues);
	}, [licenseNameSelectionValues]);

	return (
		<>
			<ApproveLicenseName
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				task={props.task}
				setValuesCB={setLicenseNameSelectionValues}
				setUserCB={setNameCheckSpillingUser}
			/>

			<Notes
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				task={props.task}
				onTaskSubmit={(at, data) => {
					return saveRequestLicenseNameSelection(at, {
						...data,
						...licenseNameSelectionValues,
					});
				}}
				onSendNameToClient={(at, data) => {
					return sendRequestLicenseNameSelection(at, {
						...data,
						...licenseNameSelectionValues,
					});
				}}
				onNameCheckSpilling={(at, data) => {
					return sendRequestLicenseNameCheckSpilling(at, {
						...data,
						...licenseNameSelectionValues,

						Selected_User_Id: checkNameSpillingUser,
					});
				}}
				checkNameSpillingUser={checkNameSpillingUser}
				required={false}
			/>
		</>
	);
};
