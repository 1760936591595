import apiConfig from "../../apiConfig";

export const getLicenseActivitiesList = async (at, data = {}) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/ACTIVITIES/Get_Licenses_Activities_List",
		{
			...data,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};
